import React from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import moment from "moment";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const styles = theme => ({
  layout: {
    width: "80%",
    marginLeft: "auto",
    marginRight: "auto"
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme
      .spacing(3)}px`
  }
});

function AdminStats(props) {
  const { classes, claims } = props;
  let allClaims = claims;

  allClaims.sort(function(a, b) {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    return moment(b.date).isBefore(moment(a.date)) ? 1 : -1;
  });

  let groupsLc = {};
  let totalQuotedByQuoter = {};
  let totalQuotedDateByQuoter = {};
  let groupsLcRetail = {};
  let groupsLcB2B = {};
  let quoters = new Set();
  let statuses = new Set();

  allClaims.forEach(function(val) {
    let date = moment(val.date).format("MM/DD");
    if (Object.keys(groupsLc).indexOf(date) === -1) {
      groupsLc[date] = { count: 1, quotes: [], quotedBys: val.quotedBy ? [{ quotedBy: val.quotedBy }] : [] };
      if (val.approved) {
        groupsLc[date].quotes.push({ quotedBy: val.quotedBy, quote: val.quote, quoteRepairer: val.quoteRepairer });
      }
      groupsLcB2B[date] = 0;
      groupsLcRetail[date] = 0;
    } else {
      if (val.quotedBy) {
        groupsLc[date].quotedBys.push({ quotedBy: val.quotedBy });
        if (val.approved) {
          groupsLc[date].quotes.push({ quotedBy: val.quotedBy, quote: val.quote, quoteRepairer: val.quoteRepairer });
        }
      }
      groupsLc[date] = { ...groupsLc[date], count: groupsLc[date].count + 1 };
    }
    if (val.quotedBy) {
      quoters.add(val.quotedBy);
      statuses.add(val.status);
    }
    if (val.retail) {
      if (Object.keys(groupsLcRetail).indexOf(date) !== -1) {
        groupsLcRetail[date] += 1;
      } else {
        groupsLcRetail[date] = 1;
      }
    } else {
      if (Object.keys(groupsLcB2B).indexOf(date) !== -1) {
        groupsLcB2B[date] += 1;
      } else {
        groupsLcB2B[date] = 1;
      }
    }
  });

  allClaims.forEach(function(claim) {
    if (claim.quotedBy && totalQuotedByQuoter[claim.quotedBy]) {
      totalQuotedByQuoter[claim.quotedBy].push(claim.status);
    } else if (claim.quotedBy) {
      totalQuotedByQuoter[claim.quotedBy] = [claim.status];
    }
  });

  function createDataset(label, data, borderColor) {
    return {
      label,
      fill: false,
      lineTension: 0.1,
      borderColor,
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointRadius: 1,
      pointHoverRadius: 5,
      pointHitRadius: 10,
      data
    };
  }


  const dataLine = {
    labels: Object.keys(groupsLc),
    datasets: [
      createDataset("Claims", Object.values(groupsLc).map(function(val) {
        return val.count;
      }), "#5e5f5f"),
      createDataset("Retail", Object.values(groupsLcRetail).map(function(val) {
        return val;
      }), "#5aa844"),
      createDataset("B2b", Object.values(groupsLcB2B).map(function(val) {
        return val;
      }), "#2250bb")
    ]
  };

  function randomColor() {
    return "#" + Math.floor(Math.random() * 16777215).toString(16);
  }

  const timeWindow = moment().subtract(7, "days");
  const lastSaturday = moment().isoWeekday() >= 6 ? moment().isoWeekday(6).startOf("day") : moment().isoWeekday(-1).startOf("day");
  const previousSaturday = lastSaturday.clone().subtract(7, "days");
  const last7DaysQuotes = Object.fromEntries(Object.entries(groupsLc).filter(([key]) => moment(key, "MM/DD").isAfter(timeWindow)));
  const retailClaimsFromSaturday = claims.filter((claim) => claim.retail && moment(claim.date).isSameOrAfter(lastSaturday));
  const retailClaimsQuotedFromSaturday = claims.filter((claim) => claim.retail && moment(claim.quoteDate).isSameOrAfter(lastSaturday));
  const retailClaimsFromPreviousWeek = claims.filter((claim) => {
    const creationDate = moment(claim.date);
    return claim.retail && creationDate.isSameOrAfter(previousSaturday) && creationDate.isBefore(lastSaturday);
  });
  const retailClaimsQuotedFromPreviousWeek = claims.filter((claim) => {
    const quoteDate = moment(claim.quoteDate);
    return claim.retail && quoteDate.isSameOrAfter(previousSaturday) && quoteDate.isBefore(lastSaturday);
  });
  const quotersList = [...quoters];
  const quotersDataset = quotersList.map(function(quoter) {
    return createDataset(quoter, Object.values(last7DaysQuotes).map(function(val) {
      return val.quotedBys.filter((quotedBy) => quotedBy.quotedBy === quoter).length;
    }), randomColor());
  });

  const individualQuotesLineChart = {
    labels: Object.keys(last7DaysQuotes),
    datasets: quotersDataset
  };

  const quotersTakeRateDataset = quotersList.map(function(quoter) {
    return createDataset(quoter, Object.values(last7DaysQuotes).map(function(val) {
      const qByQuoter = val.quotes.filter((val) => val.quotedBy === quoter);
      const takeRates = qByQuoter.map((quote) => 1 - quote.quoteRepairer / quote.quote);
      return Math.round(100 * takeRates.reduce((a, b) => a + b, 0) / takeRates.length);
    }), randomColor());
  });

  const individualTakeRatesLineChart = {
    labels: Object.keys(last7DaysQuotes),
    datasets: quotersTakeRateDataset
  };

  retailClaimsQuotedFromSaturday.forEach(function(claim) {
    if (claim.quotedBy && totalQuotedDateByQuoter[claim.quotedBy]) {
      totalQuotedDateByQuoter[claim.quotedBy].push(moment(claim.quoteDate).format("dddd"));
    } else if (claim.quotedBy) {
      totalQuotedDateByQuoter[claim.quotedBy] = [moment(claim.quoteDate).format("dddd")];
    }
  });
  const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const barQuoterByDayDataset = [...days].map(function(day) {
    const quoterStatusCount = quotersList.map(function(quoter) {
      const count = Object.entries(totalQuotedDateByQuoter).filter(([key]) => key === quoter).map(([key, value]) => value.filter((val) => val === day).length);
      return count && count.length > 0 ? count[0] : 0;
    });
    return { label: day, data: quoterStatusCount, backgroundColor: randomColor() };
  });
  const individualQuotesByDayBarChart = {
    labels: quotersList,
    datasets: barQuoterByDayDataset
  };

  function collectKpis(c) {
    //Number of inbounds net: number of jobs in the week that came in where a picture exists
    const numberOfInboundsNet = c.filter((claim) => claim.hasPhotos).length;
    // Number of inbounds gross: number of jobs in the week that came in
    const numberOfInboundsGross = c.length;
    const numberOfRespondedJobs = c.filter((claim) => claim.quotedBy).length;
    // Response rate: number of inbounds / number of responded jobs (where the action “awaiting-quote-authorisation” exists)
    const responseRate = numberOfRespondedJobs / numberOfInboundsNet;
    // Acceptance rate: number of accepted jobs (where the action “in-marketplace” exists) / number of responded jobs
    const acceptedJobs = c.filter((claim) => claim.approved).length;
    const acceptanceRate = acceptedJobs / numberOfRespondedJobs;
    // Booked jobs: number of jobs where booking fee was paid
    const bookedJobs = c.filter((claim) => claim.bookingPaid).length;
    // Loss rate: 1 – (number of booked jobs / number of accepted jobs)
    const lossRate = 1 - (bookedJobs / acceptedJobs);
    const takeRates = c.filter((claim) => claim.approved && claim.quoteRepairer && claim.quote && claim.quote > 0).map((claim) => 1 - claim.quoteRepairer / claim.quote);
    const takeRate = takeRates.reduce((a, b) => a + b, 0) / takeRates.length;
    return {
      numberOfInboundsNet,
      numberOfInboundsGross,
      responseRate,
      acceptanceRate,
      bookedJobs,
      lossRate,
      takeRate
    };
  }

  const previousKpis = collectKpis(retailClaimsFromPreviousWeek);
  const lastKpis = collectKpis(retailClaimsFromSaturday);

  function collectAcceptanceRate(c, quoter) {
    const numberOfRespondedJobs = c.filter((claim) => claim.quotedBy === quoter).length;
    const acceptedJobs = c.filter((claim) => claim.approved && claim.quotedBy === quoter).length;
    return numberOfRespondedJobs > 0 ? (acceptedJobs / numberOfRespondedJobs) * 100 : 0;
  }

  const quotesDict = {
    "From Saturday": retailClaimsQuotedFromSaturday,
    "Prior Week": retailClaimsQuotedFromPreviousWeek,
    "Total": claims
  };
  const acceptanceRatesDataset = Object.keys(quotesDict).map(function(label) {
    const quoterRates = quotersList.map(quoter => collectAcceptanceRate(quotesDict[label], quoter));
    return { label, data: quoterRates, backgroundColor: randomColor() };
  });

  const acceptanceRatesChart = {
    labels: quotersList,
    datasets: acceptanceRatesDataset
  };

  const bookedAt = claims.filter((claim) => claim.bookedAt ? moment(claim.bookedAt).isSameOrAfter(lastSaturday) : false).length;
  const bookedAtPrevWeek = claims.filter((claim) => claim.bookedAt ? moment(claim.bookedAt).isSameOrAfter(previousSaturday) && moment(claim.bookedAt).isBefore(lastSaturday) : false).length;
  const bookedToday = claims.filter((claim) => claim.bookedAt ? moment(claim.bookedAt).isSame(moment(), "day") : false).length;
  const bookedLastWeekToday = claims.filter((claim) => claim.bookedAt ? moment(claim.bookedAt).isSame(timeWindow, "day") : false).length;
  const bookedAtSameDayLastWeek = claims.filter((claim) => claim.bookedAt ? moment(claim.bookedAt).isSameOrAfter(previousSaturday) && moment(claim.bookedAt).isSameOrBefore(timeWindow, "day") : false).length;

  return (
    <main className={classes.layout}>
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h5">
          Claim Stats
        </Typography>
        <br />
        <Typography component="h2" variant="h5">
          Booking Paid since Saturday: {bookedAt} (previous week {bookedAtPrevWeek}, until same day last
          week {bookedAtSameDayLastWeek} )
        </Typography>
        <Typography component="h2" variant="h5">
          Booking Paid today: {bookedToday} (same day last week {bookedLastWeekToday})
        </Typography>
        <br />
        <Typography component="h2" variant="h5">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>KPI</TableCell>
                <TableCell>Since Saturday</TableCell>
                <TableCell>Prior</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  Number of inbounds net
                </TableCell>
                <TableCell>{lastKpis.numberOfInboundsNet}</TableCell>
                <TableCell>{previousKpis.numberOfInboundsNet}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Number of inbounds gross
                </TableCell>
                <TableCell>{lastKpis.numberOfInboundsGross}</TableCell>
                <TableCell>{previousKpis.numberOfInboundsGross}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Response rate
                </TableCell>
                <TableCell>{Math.round(lastKpis.responseRate * 100)}%</TableCell>
                <TableCell>{Math.round(previousKpis.responseRate * 100)}%</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Acceptance rate
                </TableCell>
                <TableCell>{Math.round(lastKpis.acceptanceRate * 100)}%</TableCell>
                <TableCell>{Math.round(previousKpis.acceptanceRate * 100)}%</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Booked jobs
                </TableCell>
                <TableCell>{lastKpis.bookedJobs}</TableCell>
                <TableCell>{previousKpis.bookedJobs}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Loss rate
                </TableCell>
                <TableCell>{Math.round(lastKpis.lossRate * 100)}%</TableCell>
                <TableCell>{Math.round(previousKpis.lossRate * 100)}%</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Take rate
                </TableCell>
                <TableCell>{Math.round(lastKpis.takeRate * 100)}%</TableCell>
                <TableCell>{Math.round(previousKpis.takeRate * 100)}%</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Typography>
        <br />
        <Typography component="h2" variant="h5">
          Quotes by day by team member since Saturday
        </Typography>
        <Bar data={individualQuotesByDayBarChart} />
        <br />
        <Typography component="h2" variant="h5">
          Claims By Date (last 60 days)
        </Typography>
        <Line data={dataLine} />
        <br />
        <Typography component="h2" variant="h5">
          Take rates By Date (last 7 days)
        </Typography>
        <Line data={individualTakeRatesLineChart} />
        <Typography component="h2" variant="h5">
          Quotes By Date (last 7 days)
        </Typography>
        <Line data={individualQuotesLineChart} />
        <Typography component="h2" variant="h5">
          Acceptance Rates
        </Typography>
        <Bar data={acceptanceRatesChart} />
        <br />
      </Paper>
    </main>
  );
}

AdminStats.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AdminStats);
