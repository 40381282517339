import React, { Component } from "react";
import { Grid, CircularProgress } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import Card from "components/theme/Card/Card.jsx";
import CardBody from "components/theme/Card/CardBody.jsx";
import GridItem from "../theme/Grid/GridItem";
import claimStore from "../../stores/claimStore";
import quoteStore from "../../stores/quoteStore";
import { Link } from "react-router-dom";
import Button from "../theme/CustomButtons/Button";
import get from "lodash.get";
import HorizontalLabelPositionBelowStepper from "../common/Stepper";
import ClaimImageGallery from "../claimImageGallery/ClaimImageGallery";
import ClaimFiles from "../claimFiles/ClaimFiles";
import {
  BODYSHOP_CLAIM_STATUS_ORDER,
  CLAIM_STATUS,
  CLAIM_STATUS_KEYS,
  CLAIM_STATUS_ORDER, FNOL_CLAIM_STATUS_ORDER
} from "../../constants/claimStatus";
import movieIcon from "assets/img/movie.png";
import pdfIcon from "assets/img/pdf-icon.png";
import quoteIcon from "assets/img/request-quote-big.png";
import { observer } from "mobx-react";
import QuoteFile from "../quoteFile/QuoteFile";
import { confirmAlert } from "react-confirm-alert";
import ClaimActions from "./ClaimActions";
import ClaimDetails from "./ClaimDetails";

const Claim = observer(
  class Claim extends Component {
    state = {
      pending: true,
      quote: null
    };

    componentDidMount = async () => {
      await this.getClaim();
    };

    componentDidUpdate = async (prevProps) => {
      if (prevProps.match.params.claimId !== this.props.match.params.claimId) {
        this.setState({ quote: null });
        await this.getClaim();
      }
    };

    getClaim = async () => {
      this.setState({ pending: true });
      const claim = await claimStore.getClaim(this.props.match.params.claimId);
      if (claim.quoteRef) {
        const quote = await quoteStore.getClientQuote(claim.quoteRef);
        this.setState({ quote });
      }
      this.setState({ pending: false });
    };

    rejectClaim = async (id) => {
      confirmAlert({
        title: "Reject claim",
        message: "Are you sure?",
        buttons: [
          {
            label: "Yes",
            async onClick() {
              await claimStore.rejectClaim(id);
              await claimStore.getClaim(id);
            }
          },
          {
            label: "No",
            onClick: () => ""
          }
        ]
      });
    };

    archiveClaim = async (id) => {
      confirmAlert({
        title: "Archive claim",
        message: "Are you sure?",
        buttons: [
          {
            label: "Yes",
            async onClick() {
              await claimStore.archiveClaim(id);
              await claimStore.getClaim(id);
            }
          },
          {
            label: "No",
            onClick: () => ""
          }
        ]
      });
    };

    unarchiveClaim = async (id) => {
      confirmAlert({
        title: "Unarchive claim",
        message: "Are you sure?",
        buttons: [
          {
            label: "Yes",
            async onClick() {
              await claimStore.unarchiveClaim(id);
              await claimStore.getClaim(id);
            }
          },
          {
            label: "No",
            onClick: () => ""
          }
        ]
      });
    };

    requestFeedback = async (id) => {
      await claimStore.requestFeedback(id);
      await claimStore.getClaim(id);
    };

    requestBodyshopApproval = async (id) => {
      await claimStore.requestBodyshopApproval(id);
      await claimStore.getClaim(id);
    };

    returnToMarketplace = async (id) => {
      confirmAlert({
        title: "Rollback claim",
        message: "Are you sure to return to marketplace?",
        buttons: [
          {
            label: "Yes",
            async onClick() {
              try {
                await claimStore.returnToMarketplace(id);
              } catch (err) {
                const error = get(err, "response.data.message", err.message);
                alert(error);
              }
              await claimStore.getClaim(id);
            }
          },
          {
            label: "No",
            onClick: () => ""
          }
        ]
      });
    };
    rollbackToBidding = async (id) => {
      confirmAlert({
        title: "Rollback claim",
        message: "Are you sure to return to Bidding with pickup dates reset?",
        buttons: [
          {
            label: "Yes",
            async onClick() {
              try {
                await claimStore.rollbackToBidding(id);
              } catch (err) {
                const error = get(err, "response.data.message", err.message);
                alert(error);
              }
              await claimStore.getClaim(id);
            }
          },
          {
            label: "No",
            onClick: () => ""
          }
        ]
      });
    };

    rollbackAwaitingQuoteToFnol = async (id) => {
      confirmAlert({
        title: "Rollback claim to fnol",
        message: "Are you sure to return to Fnol with quote removed?",
        buttons: [
          {
            label: "Yes",
            async onClick() {
              try {
                await claimStore.rollbackAwaitingQuoteToFnol(id);
              } catch (err) {
                const error = get(err, "response.data.message", err.message);
                alert(error);
              }
              await claimStore.getClaim(id);
            }
          },
          {
            label: "No",
            onClick: () => ""
          }
        ]
      });
    };

    render() {
      const claim = claimStore.currentClaim;
      const quote = this.state.quote;
      if (this.state.pending || !claim) {
        return <CircularProgress />;
      }
      return (
        <>
          <Hidden smDown>
            <Grid container>
              <GridItem md={12}>
                {claim.status !== CLAIM_STATUS_KEYS.Archived && claim.retail && claim.serviceRequired === "bodyshop" && (
                  <HorizontalLabelPositionBelowStepper
                    steps={BODYSHOP_CLAIM_STATUS_ORDER.map(status => CLAIM_STATUS[status])}
                    activeStep={BODYSHOP_CLAIM_STATUS_ORDER.indexOf(claim.status)}
                  />)}
                {claim.status !== CLAIM_STATUS_KEYS.Archived && !claim.retail && claim.serviceRequired !== "bodyshop" && (
                  <HorizontalLabelPositionBelowStepper
                    steps={CLAIM_STATUS_ORDER.map(status => CLAIM_STATUS[status])}
                    activeStep={CLAIM_STATUS_ORDER.indexOf(claim.status)}
                  />)}
                {claim.status !== CLAIM_STATUS_KEYS.Archived && claim.retail && claim.serviceRequired !== "bodyshop" && (
                  <HorizontalLabelPositionBelowStepper
                    steps={FNOL_CLAIM_STATUS_ORDER.map(status => CLAIM_STATUS[status])}
                    activeStep={FNOL_CLAIM_STATUS_ORDER.indexOf(claim.status)}
                  />)}
                {claim.status === CLAIM_STATUS_KEYS.Archived && (
                  <HorizontalLabelPositionBelowStepper
                    steps={[CLAIM_STATUS[CLAIM_STATUS_KEYS.Archived]]}
                    activeStep={CLAIM_STATUS_ORDER.indexOf(claim.status)}
                  />
                )}
              </GridItem>
            </Grid>
          </Hidden>
          <Grid container>
            <GridItem md={4}>
              <Card>
                <CardBody>
                  <ClaimImageGallery
                    imagesIds={claim.images}
                    thumbnails={claim.thumbnails}
                  />
                  <ClaimFiles data={claim.pdfs} image={pdfIcon} />
                  <ClaimFiles data={claim.videos} image={movieIcon} filetype="video/mp4" extension="mp4" />
                  {quote && quote.document && (
                    <QuoteFile fileId={quote.document} image={quoteIcon} />)}
                  {quote && quote.quoteStripeId && (
                    <QuoteFile fileId={quote.quoteStripeId} image={quoteIcon} />)}
                  <ClaimActions
                    claim={claim}
                    quote={quote}
                    successHandler={this.getClaim}
                    pending={this.state.pending}
                    unarchiveAction={this.unarchiveClaim}
                    requestFeedbackAction={this.requestFeedback}
                    requestBodyshopApprovalAction={this.requestBodyshopApproval}
                    returnToMarketplaceAction={this.returnToMarketplace}
                    rollbackToBiddingAction={this.rollbackToBidding}
                    rollbackAwaitingQuoteToFnolAction={this.rollbackAwaitingQuoteToFnol}
                    archiveClaimAction={this.archiveClaim}
                    rejectClaimAction={this.rejectClaim}
                  />
                </CardBody>
              </Card>
            </GridItem>
            <GridItem md={8}>
              <ClaimDetails
                claim={claim}
                quote={quote}
              />
            </GridItem>
          </Grid>
          <Grid container justifyContent="flex-end">
            <GridItem>
              <Button
                to={"/dashboard/" + claim.status}
                component={Link}
                round
                color="primary"
              >
                Back
              </Button>
            </GridItem>
          </Grid>
        </>
      );
    }
  }
);

export default Claim;
