import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

const styles = theme => ({
  root: {
    width: '90%'
  }
});
function HorizontalLabelPositionBelowStepper(props) {
  const { classes, steps, activeStep } = props;

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {(steps? steps: []).map((label, index) => {
          const props = {};
          const labelProps = {};
          if (index === activeStep - 1) {
            // labelProps.icon=<Done />
          }
          return (
            <Step key={label} {...props}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
}

HorizontalLabelPositionBelowStepper.propTypes = {
  classes: PropTypes.object,
  activeStep: PropTypes.number,
  steps: PropTypes.object
};

export default withStyles(styles)(HorizontalLabelPositionBelowStepper);
