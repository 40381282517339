import { makeObservable, action, runInAction, observable } from "mobx";
import axios from "axios";

export class LabelsStore {
  labels = [];

  constructor() {
    makeObservable(this, {
      getLabel: action,
      getAllLabels: action,
      labels: observable
    });
  }

  async getLabel(id) {
    const response = await axios.get(`/api/labels/${id}`);
    return response.data;
  }

  async getAllLabels() {
    const response = await axios.get(`/api/labels`);
    runInAction(() => {
      this.labels = response.data;
    })
    return this.labels
  }

  async createLabel(label) {
    const response = await axios.post(`/api/labels`, label);
    return response.data;
  }

  async updateLabel(labelId, label) {
    const response = await axios.put(`/api/labels/${labelId}`, label);
    return response.data;
  }

}

export default new LabelsStore();
