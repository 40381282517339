export const generateConstantsList = constant =>
  Object.keys(constant).map(key => ({
    value: key,
    label: constant[key]
  }));

export const groupBy = (array, key) => {
  return array.reduce((grouped, current) => {
    const currentKey = current[key];
    const currentGroup = grouped[currentKey] || [];

    return {
      ...grouped,
      [currentKey]: [...currentGroup, current]
    };
  }, {});
};

export const isMobile = () => {
  return window.matchMedia("(min-width: 768px)").matches;
}
