import { observer } from 'mobx-react';
import React from 'react';
import GridContainer from '../theme/Grid/GridContainer';
import DashboardCard from './DashboardCard';
import claimStatusConfig from './claimStatusConfig';
import { CLAIM_STATUS_KEYS } from '../../constants/claimStatus';
import userStore from '../../stores/userStore';
import { PRIVILEGES } from '../../constants/privileges';

function DashboardCards() {
    return (
        <GridContainer>
          {claimStatusConfig
            .filter(config => config.status !== CLAIM_STATUS_KEYS.Archived)
            .filter(config => config.status !== CLAIM_STATUS_KEYS.Completed)
            .map(config => (
              <DashboardCard key={config.status} {...config} />
            ))}
          {(userStore.isAllowed(PRIVILEGES.ArchiveAll)) && claimStatusConfig
            .filter(config => config.status === CLAIM_STATUS_KEYS.Archived)
            .map(config => (
              <DashboardCard key={config.status} {...config} />
            ))}
        </GridContainer>
    );
}

export default observer(DashboardCards);
