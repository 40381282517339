import React from "react";
import { Paper } from "@material-ui/core";
import Grid from "@material-ui/core/Grid/Grid";

function FnolComplete() {
  return (
    <Grid container>
      <Grid item sm={10}>
        <Paper style={{ padding: 20, margin: 30 }}>
          <h3>Thank you.<br />
            Your claim was successfully uploaded and we will be in touch shortly.</h3>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default FnolComplete;
