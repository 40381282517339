import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { Avatar, Grid, Typography } from '@material-ui/core';
import AddPhotoIcon from '@material-ui/icons/AddAPhotoOutlined';
import { FieldArray } from 'react-final-form-arrays';
import Button from '@material-ui/core/Button';
import Input from './Input';
import { isRequired } from 'validation/validation';
import { MIN_IMAGE_SIZE } from 'constants/images';
import imageCompression from 'browser-image-compression';
import LinearProgress from '@material-ui/core/LinearProgress';

const style = theme => ({
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main
  },
  imageInput: {
    width: '100%',
    marginBottom: '8px'
  },
  imageContainer: {
    padding: '0 8px',
    border: `1px dashed ${theme.palette.primary.light}`,
    borderRadius: '8px'
  },
  image: {
    width: '100%',
    height: '150px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',

    '& img': {
      flex: '0 1',
      width: 'auto',
      height: 'auto',
      maxWidth: '100%',
      maxHeight: '100%'
    }
  },
  imageSizeInfo: {
    margin: '4px 0',
    fontSize: '12px'
  }
});

const compressionOptions = {
  maxSizeMB: 1
};

class Images extends Component {
  state = {
    imageUploadError: null,
    progress : {}
  };

  addImages = async (event, fields) => {
    this.clearImageErrors();

    this.convertImages(Array.from(event.target.files)).then(
      ({ images, errors }) => {
        fields.concat(images);
        this.handleImageErrors(errors);
      }
    );
  };

  convertImages = files => {
    const promises = files.map(this.compressAndResizeImage);

    return Promise.all(promises).then(loadedImages => {
      const images = loadedImages.filter(image => !image.error);
      const errors = loadedImages
        .filter(image => image.error)
        .map(image => image.error);

      return { images, errors };
    });
  };

  compressAndResizeImage = file => {
    return new Promise(resolve => {
      try {
        const [type, format] = (file.type || '').split('/');

        if (type === 'image' && ['jpg', 'png', 'jpeg'].includes(format)) {
          imageCompression(file, compressionOptions).then(compressedFile => {
            const fileReader = new FileReader();

            fileReader.onload = () => {
              const imageData = fileReader.result;

              const image = {
                data: imageData,
                contentType: file.type,
                name: file.name
              };

              const loadedImage = new Image();
              loadedImage.src = imageData;
              loadedImage.onload = () => {
                if (
                  loadedImage.width >= MIN_IMAGE_SIZE.width &&
                  loadedImage.height >= MIN_IMAGE_SIZE.height
                ) {
                  resolve(image);
                } else {
                  resolve({ error: 'Image too small' });
                }
              };
            };

            fileReader.readAsDataURL(compressedFile);
          });
        } else {
          resolve({ error: 'Wrong format' });
        }
      } catch (e) {
        resolve({ error: 'Broken image' });
      }
    });
  };

  clearImageErrors = () => this.setState({ imageUploadError: null });

  handleImageErrors = errors => {
    if (errors.length > 0) {
      const uniqueErrors = [...new Set(errors)];
      this.setState({
        imageUploadError: `Following errors occurred with some of uploaded images: ${uniqueErrors.join(
          ', '
        )}`
      });
    }
  };

  render() {
    const { imageUploadError  } = this.state;
    const { classes, title, progress } = this.props;
    return (
      <Grid item xs={12} container direction='column'>
        <Grid item container direction='column' alignItems='center'>
          <Avatar className={classes.avatar}>
            <AddPhotoIcon />
          </Avatar>
          <Typography component='h1' variant='h5'>
            {title}{' '}
          </Typography>
        </Grid>
        <Grid item>
          <FieldArray name='images'>
            {({ fields, meta }) => (
              <>
                <div>
                  <Button
                    variant='contained'
                    component='label'
                    color='secondary'
                  >
                    <input
                      style={{ display: 'none' }}
                      type='file'
                      id='imgfile'
                      accept='image/jpeg, image/png, image/jpg'
                      multiple
                      onChange={event => this.addImages(event, fields)}
                    />
                    Choose images
                  </Button>
                  <Typography
                    className={classes.imageSizeInfo}
                    component='span'
                  >
                    {`Minimum size ${MIN_IMAGE_SIZE.width} x ${
                      MIN_IMAGE_SIZE.height
                    }px`}
                  </Typography>
                </div>
                <Grid container spacing={2}>
                  {fields.map((name, index) => (
                    <Grid item xs={12} md={4} key={index}>
                      <div className={classes.imageContainer}>
                        <Input
                          name={`${name}.name`}
                          label='Name '
                          required
                          validate={isRequired}
                        />
                        <div className={classes.image}>
                          <img
                            onClick={() => fields.remove(index)}
                            src={fields.value[index].data}
                            alt=''
                          />
                        </div>
                        <LinearProgress variant="determinate" color='secondary' value={progress[fields.value[index].name] ? progress[fields.value[index].name] : 0 } />
                      </div>
                    </Grid>
                  ))}
                </Grid>
                {meta.dirty && imageUploadError && (
                  <Grid container spacing={5} alignItems='center'>
                    <Grid item>
                      <Typography color='error'>{imageUploadError}</Typography>
                    </Grid>
                  </Grid>
                )}
              </>
            )}
          </FieldArray>
        </Grid>
      </Grid>
    );
  }
}

Images.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  progress: PropTypes.object
};

Images.defaultProps = {
  title: 'Damage Images',
  name: 'images'
};

export default withStyles(style)(Images);
