import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { Dialog, Typography } from "@material-ui/core";
import Button from "../../../theme/CustomButtons/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import claimStore from "../../../../stores/claimStore";
import withStyles from "@material-ui/core/es/styles/withStyles";
import get from "lodash.get";
import TextField from "@material-ui/core/TextField";

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  uploadButton: {
    marginBottom: "12px"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  }
});

function ModifyBodyshopQuote(props) {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [file, setFile] = React.useState(null);
  const [cost, setCost] = React.useState(props.cost);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeQuote = event => {
    setCost(event.target.value);
  };

  const addFile = async event => {
    try {
      const file = await uploadFile(event.target.files[0]);
      setFile(file);
    } catch (e) {
      setError(e.message);
    }
  };

  const uploadFile = file => {
    return new Promise(resolve => {
      const fileReader = new FileReader();

      fileReader.onload = () => {
        const fileData = fileReader.result;

        const fileToUpload = {
          data: fileData,
          contentType: file.type,
          name: file.name
        };

        resolve(fileToUpload);
      };
      fileReader.readAsDataURL(file);
    });
  };

  const modifyQuote = async () => {
    try {
      if (file) {
        const result = await claimStore.uploadFileToAws(file);
        await claimStore.requestBidAuthorisation(claimStore.currentClaim._id, cost, cost, result.path);
      } else {
        await claimStore.requestBidAuthorisation(claimStore.currentClaim._id, cost, cost);
      }
      handleClose();
      props.successHandler();
    } catch (err) {
      console.log(err);
      const error = get(err, "response.data.message", "Failed to update quote");
      setError(error);
    }
  };

  const { classes } = props;
  return (
    <>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Modify Quote
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Modify Quote</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please provide the new quote for the claim. Note: user will not be requested to authorise nor will the
            repairer be notified.
          </DialogContentText>
          <TextField
            id="cost"
            label="Quote (ex. VAT)"
            type="number"
            defaultValue={cost}
            className={classes.textField}
            InputLabelProps={{
              shrink: true
            }}
            onChange={handleChangeQuote}
          />
          <Button
            variant="contained"
            component="label"
            color="primary"
            className={props.classes.uploadButton}
          >
            <input
              style={{ display: "none" }}
              type="file"
              id="uploadfile"
              accept="application/pdf"
              onChange={addFile}
            />
            Upload Quote File
          </Button>
          {file && (
            <Typography
              color="primary"
              data-cy="file-ready"
            >
              Document received
            </Typography>
          )}
          {error && (
            <Typography
              color="error"
              align="left"
              data-cy="login-error"
            >
              {error}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}
                  color="primary">
            Cancel
          </Button>
          <Button onClick={modifyQuote}
                  color="primary"
                  name="modify-quote">
            Modify Quote
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );

}

ModifyBodyshopQuote.propTypes = {
  successHandler: PropTypes.func,
  cost: PropTypes.number
};

export default withStyles(styles)(observer(ModifyBodyshopQuote));
