import React from "react";
import { Grid, Paper, Typography } from "@material-ui/core";

function RepairDateRejected() {
  return (
    <Grid container>
      <Grid item sm={10}>
        <Paper style={{ padding: 20, margin: 30 }}>
          <Typography align="center" paragraph variant="headline">
            Thank you, we’ve made a note and a repairer will be in touch ASAP to book you in.<br />
            If this does not happen, please let us know by sending an email
            to <a href="mailto:estimates@axioma.co.uk">estimates@axioma.co.uk</a>, and we will chase the repairer for
            you.<br />
            Best, The Axioma Team
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default RepairDateRejected;
