import { action, makeObservable, observable, runInAction } from "mobx";
import axios from "axios";
import { THUMBNAILS_SIZE } from "../constants/images";
import { enrichClaim, enrichClaims } from "../utils/claims";
import Resizer from "react-image-file-resizer";
import get from "lodash.get";

window.Buffer = window.Buffer || require("buffer").Buffer;

const THUMBNAILS_WIDTH = 320;

export class ClaimStore {
  loading = false;
  currentClaim = {};
  claims = [];
  cost = 0;
  claimAdminStats = [];
  filteredClaims = null;

  constructor() {
    makeObservable(this, {
      loading: observable,
      claims: observable,
      filteredClaims: observable,
      claimAdminStats: observable,
      currentClaim: observable,
      cost: observable,
      getClaim: action,
      getClaims: action,
      getFilesData: action,
      getAdminStats: action,
      deleteFiles: action,
      startRepair: action,
      finishRepair: action,
      archiveClaim: action,
      unarchiveClaim: action,
      requestQuoteAuthorisationRetail: action,
      requestQuoteAuthorisationB2B: action,
      addClaimFiles: action,
      addFnolFiles: action,
      addBid: action,
      addComment: action,
    });
  }

  async getClaims(lastDays) {
    this.loading = true;
    const response = await axios.get("/api/claims", {
      params: {
        lastDays
      }
    });
    this.claims = await enrichClaims(response.data);
    this.loading = false;
  }

  async getFilteredClaims(status) {
    this.loading = true;
    const response = await axios.get("/api/claims", {
      params: {
        status
      }
    });
    this.claims = await enrichClaims(response.data);
    this.loading = false;
    return this.claims;
  }

  async getClaimCount(status) {
    const response = await axios.get(`/api/claims/${status}/count`);
    return response.data;
  }

  async getMedianTime(status) {
    const response = await axios.get(`/api/claims/${status}/median-time`);
    return response.data;
  }

  async getAdminStats(days) {
    const response = await axios.get(`/api/claims/stats/${days}`);
    runInAction(() => {
      this.claimAdminStats = response.data;
    });
  }

  async uploadFileToAws(fileData) {
    const awsAxios = axios.create({
      transformRequest: (data, headers) => {
        delete headers["Authorization"];
        return data;
      }
    });
    const result = await axios.get("/api/images/auth/sign-s3", {
      params: {
        format: fileData.contentType
      }
    });

    const buf = window.Buffer.from(fileData.data.replace(/^data:[\w,/]+;base64,/, ""), "base64");
    await awsAxios.put(result.data.signedRequest, buf);
    return { contentType: fileData.contentType, name: fileData.name, s3Key: result.data.s3Key, path: result.data.path };
  }

  async addClaimFile(id, imageData, component) {
    const awsAxios = axios.create({
      transformRequest: (data, headers) => {
        delete headers["Authorization"];
        return data;
      }
    });
    const result = await axios.get("/api/images/auth/sign-s3", {
      params: {
        format: imageData.contentType
      }
    });

    const buf = window.Buffer.from(imageData.data.replace(/^data:[\w,/]+;base64,/, ""), "base64");
    console.log("uploading " + imageData.name);
    await awsAxios.put(result.data.signedRequest, buf, {
      onUploadProgress: (progressEvent) => {
        if (component) {
          component.progressUpdateFn(imageData.name, (progressEvent.loaded / progressEvent.total) * 100);
        }
      }
    });
    const signature = { contentType: imageData.contentType, name: imageData.name, s3Key: result.data.s3Key };
    if (imageData.contentType.includes("image")) {
      await this.uploadThumbnail(signature, buf, awsAxios, component);
    }
    console.log("updating claim with image " + imageData.name);
    const response = await axios.post(`/api/claims/${id}/images`, signature);
    return response.data.id;
  }

  async addClaimFiles(id, imagesData) {
    const promises = imagesData.map(async imageData => this.addClaimFile(id, imageData));
    try {
      return await Promise.all(promises);
    } catch (error) {
      const errorMessage = get(error, "response.data.message", error.message);
      throw new Error("Failed to upload images: " + errorMessage);
    }
  }

  async uploadThumbnail(signature, buf, awsAxios, component) {
    const resizeFile = (buf) => new Promise(resolve => {
      Resizer.imageFileResizer(buf, THUMBNAILS_WIDTH, 240, "JPG", 45, 0,
        uri => {
          resolve(uri);
        },
        "base64"
      );
    });
    console.log("authorising thumbnail");
    const thumbnailSignature = await axios.get("/api/images/auth/sign-s3", {
      params: {
        isThumbnail: true,
        previousKey: signature.s3Key,
        width: THUMBNAILS_WIDTH,
        format: "image/jpeg"
      }
    });
    const thumbnailData = await resizeFile(new Blob([buf.buffer]));
    const bufThumbnail = window.Buffer.from(thumbnailData.replace(/^data:[\w,/]+;base64,/, ""), "base64");
    console.log("uploading thumbnail");
    await awsAxios.put(thumbnailSignature.data.signedRequest, bufThumbnail, {
      onUploadProgress: (progressEvent) => {
        if (component) {
          component.progressUpdateFn(thumbnailData.name, (progressEvent.loaded / progressEvent.total) * 100);
        }
      }
    });
  }

  async addFnolFiles(id, imagesData, secret) {
    const awsAxios = axios.create({
      transformRequest: (data, headers) => {
        delete headers["Authorization"];
        return data;
      }
    });
    const promises = imagesData.map(async imageData => {
      const result = await axios.get("/api/images/noauth/sign-s3", {
        params: {
          secret: secret,
          format: imageData.contentType
        }
      });

      const buf = window.Buffer.from(imageData.data.replace(/^data:[\w,/]+;base64,/, ""), "base64");
      await awsAxios.put(result.data.signedRequest, buf);
      const signature = { contentType: imageData.contentType, name: imageData.name, s3Key: result.data.s3Key };
      if (imageData.contentType.includes("image")) {
        const resizeFile = (buf) => new Promise(resolve => {
          Resizer.imageFileResizer(buf, THUMBNAILS_WIDTH, 240, "JPG", 45, 0,
            uri => {
              resolve(uri);
            },
            "base64"
          );
        });

        const thumbnailSignature = await axios.get("/api/images/noauth/sign-s3", {
          params: {
            isThumbnail: true,
            secret: secret,
            previousKey: signature.s3Key,
            width: THUMBNAILS_WIDTH,
            format: "image/jpeg"
          }
        });
        const thumbnailData = await resizeFile(new Blob([buf.buffer]));
        const bufThumbnail = window.Buffer.from(thumbnailData.replace(/^data:[\w,/]+;base64,/, ""), "base64");
        await awsAxios.put(thumbnailSignature.data.signedRequest, bufThumbnail);
      }
      await axios.post(`/api/claims/${id}/images-fnol`, {
        secret,
        ...signature
      });
    });
    try {
      await Promise.all(promises);
    } catch (error) {
      console.log(error);
      const errorMessage = get(error, "response.data.message", error.message);
      throw new Error("Failed to upload images: " + errorMessage);
    }
  }

  async deleteFiles(claimId, imagesIds) {
    const promises = imagesIds.map(imageId =>
      axios.delete(`/api/claims/${claimId}/images/${imageId}`)
    );

    await Promise.all(promises);
  }

  async addClaim(claimData) {
    this.loading = true;
    try {
      const response = await axios.post("/api/claims/", claimData);
      this.loading = false;
      return response.data;
    } catch (error) {
      this.loading = false;
      const errorMessage = get(error, "response.data.message", error.message);
      throw new Error("Failed to create a claim: " + errorMessage);
    }
  }

  async modifyClaim(claimId, claimData) {
    this.loading = true;
    try {
      const response = await axios.put(`/api/claims/${claimId}`, claimData);
      this.loading = false;
      return response.data;
    } catch (err) {
      this.loading = false;
      throw err;
    }
  }

  async addFnolClaim(claimData) {
    this.loading = true;
    try {
      const response = await axios.post("/api/claims/fnol", claimData);
      this.loading = false;
      return response.data;
    } catch (error) {
      this.loading = false;
      const errorMessage = get(error, "response.data.message", error.message);
      throw new Error("Failed to create a claim: " + errorMessage);
    }
  }

  async getClaim(id) {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const response = await axios("/api/claims/" + id);
      const body = await response.data;
      const claim = await enrichClaim(body);
      const thumbnails = await this.getFilesData(
        claim.images,
        THUMBNAILS_SIZE.medium
      );
      runInAction(() => {
        this.currentClaim = claim;
        this.currentClaim.thumbnails = thumbnails;
        this.loading = false;
      });
      return this.currentClaim;
    } catch (err) {
      console.error(err);
      runInAction(() => {
        this.loading = false;
      });
      throw err;
    }
  }

  async rejectClaim(id) {
    const response = await axios.post(`/api/claims/${id}/reject`);
    if (this.currentClaim && this.currentClaim._id === id && response.data) {
      runInAction(() => {
        this.currentClaim.status = response.data.status;
      });
    }
    return response;
  }

  async requestFeedback(id) {
    return axios.post(`/api/claims/${id}/request-feedback`);
  }

  async returnToMarketplace(id) {
    const response = await axios.post(`/api/claims/${id}/rollback-to-marketplace`);
    if (this.currentClaim && this.currentClaim._id === id && response.data) {
      this.currentClaim.status = response.data.status;
    }
    return response;
  }

  async rollbackToBidding(id) {
    const response = await axios.post(`/api/claims/${id}/rollback-awaiting-to-bidding`);
    if (this.currentClaim && this.currentClaim._id === id && response.data) {
      this.currentClaim.status = response.data.status;
    }
    return response;
  }

  async rollbackAwaitingQuoteToFnol(id) {
    const response = await axios.post(`/api/claims/${id}/rollback-awaiting-quote-approval-to-fnol`);
    if (this.currentClaim && this.currentClaim._id === id && response.data) {
      this.currentClaim.status = response.data.status;
    }
    return response;
  }

  async changeRepairer(id, values) {
    return await axios.put(`/api/claims/${id}/change-repairer`, values);
  }

  async getPickupDates(id) {
    try {
      this.loading = true;
      const response = await axios("/api/claims/" + id + "/pickup");
      const body = await response.data;
      this.loading = false;
      this.currentClaim = { ...this.currentClaim, retail: body.retail };
      return { data: body };
    } catch (err) {
      console.log("Error occurred while fetching Claim");
      console.error(err);
      this.loading = false;
      return { error: err.toString() };
    }
  }

  async confirmPickUp(id, pickupData) {
    this.loading = true;
    try {
      const url = `/api/claims/${id}/pickup/${this.currentClaim.retail && pickupData.chargeRequired ? "retail" : ""}`;
      const response = await axios.put(url, { pickupData});
      this.loading = false;
      return { data: response.data };
    } catch (error) {
      this.loading = false;
      return { error };
    }
  }

  async confirmBodyshopPickUp(id, date) {
    const url = `/api/claims/${id}/pickup/bodyshop`;
    const response = await axios.put(url, {date});
    this.loading = false;
    return { data: response.data };
  }

  async overrideRepairDate(id, date) {
    this.loading = true;
    try {
      const url = `/api/claims/${id}/pickup/override`;
      const response = await axios.put(url, { date });
      this.loading = false;
      return { data: response.data };
    } catch (err) {
      this.loading = false;
      return { error: err };
    }
  }

  async getQuoteUrl(quoteId) {
    return axios.get("/api/external/quotes/pdf-url", { params: { quote: quoteId } });
  }

  arrayBufferToBase64(buffer) {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  async getFilesData(claimImages, width) {
    const awsAxios = axios.create({
      transformRequest: (data, headers) => {
        delete headers["Authorization"];
        return data;
      }
    });

    try {
      const promises = claimImages.map(image =>
        axios.get(`/api/images/${image}`, { params: { width } })
      );

      const imagesData = await Promise.all(promises);

      const mappedData = imagesData.map(async image => {
        let imageBinary;
        try {
          imageBinary = await awsAxios.get(image.data.url, {
            responseType: "arraybuffer", headers: {
              "Accept": "application/json"
            }
          });
        } catch (e) {
          console.log("failed to get image", e);
          imageBinary = await axios.get("/assets/img/no_image.png", {
            responseType: "arraybuffer", headers: {
              "Accept": "application/json"
            }
          });
        }
        const convertedData = "data:" + image.data.contentType + ";base64," + this.arrayBufferToBase64(imageBinary.data);
        return {
          ...image.data,
          id: image.data._id,
          data: convertedData
        };
      });
      return Promise.all(mappedData);
    } catch (error) {
      console.error("Error occurred while fetching images", error);
      throw error;
    }
  }

  async handleStatusChange(id, endpoint) {
    const response = await axios.post(`/api/claims/${id}/${endpoint}`);
    if (this.currentClaim && this.currentClaim._id === id && response.data) {
      runInAction(() => {
        this.currentClaim.status = response.data.status;
      });
    }
    return response;
  }

  async requestPickup(id, pickUpDates, contact) {
    await this.updatePickupAvailability(id, pickUpDates, contact);
    return this.handleStatusChange(id, "request-pickup");
  }

  async startRepair(id) {
    return this.handleStatusChange(id, "start-repair");
  }

  async finishRepair(id) {
    return this.handleStatusChange(id, "finish-repair");
  }

  async archiveClaim(id) {
    return this.handleStatusChange(id, "archive");
  }

  async unarchiveClaim(id) {
    return this.handleStatusChange(id, "unarchive");
  }

  async requestQuoteAuthorisationRetail(id, costRepairer) {
    try {
      const response = await axios.post(`/api/claims/${id}/request-quote-authorisation/retail`, { costRepairer });
      if (this.currentClaim && this.currentClaim._id === id && response.data) {
        runInAction(() => {
          this.currentClaim.status = response.data.status;
        });
      }
      return response;
    } catch (e) {
      throw e;
    }
  }

  async requestQuoteAuthorisationB2B(id, cost, costRepairer, document) {
    try {
      const response = await axios.post(`/api/claims/${id}/request-quote-authorisation`, {
        cost,
        costRepairer,
        document
      });
      if (this.currentClaim && this.currentClaim._id === id && response.data) {
        runInAction(() => {
          this.currentClaim.status = response.data.status;
        });
      }
      return response;
    } catch (e) {
      throw e;
    }
  }

  async requestBidAuthorisation(id, cost, costRepairer, document) {
    const response = await axios.post(`/api/claims/${id}/request-bid-authorisation`, {
      cost,
      costRepairer,
      document
    });
    if (this.currentClaim && this.currentClaim._id === id && response.data) {
      runInAction(() => {
        this.currentClaim.status = response.data.status;
      });
    }
    return response;
  }

  async approveClaim(id) {
    return this.handleStatusChange(id, "approve-for-marketplace");
  }

  async approveBid(id) {
    return this.handleStatusChange(id, "approve-for-repair");
  }

  async confirmClaimPayment(id) {
    return this.handleStatusChange(id, "confirm-payment");
  }

  async updatePickupAvailability(id, pickupDates, contact) {
    try {
      const response = await axios.put(`/api/claims/${id}/pickup/availability`, { pickupDates: pickupDates, contact });
      return response.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async addBid(bid) {
    try {
      const config = { headers: { "Content-Type": "application/json" } };
      const response = await axios.put(
        `/api/claims/${this.currentClaim._id}/bid/`,
        { ...bid },
        config
      );
      if (response.data) {
        runInAction(() => {
          this.currentClaim.status = response.data.status;
        });
      }
    } catch (err) {
      console.log("Error occurred adding bid");
      console.error(err);
      throw err;
    }
  }

  async assignStripeIdToQuote(claimId) {
    const result = await axios.post(`/api/claims/${claimId}/add-retail-quote`);
    if (!result.data.quoteStripeId) {
      return { error: "Stripe Id could not be assigned to a quote" };
    }
    return result.data;
  }

  async addSimpleStripeQuote(claimId, priceId, cost, costRepairer) {
    const result = await axios.post(`/api/claims/${claimId}/add-simple-retail-quote`, {
      priceId,
      cost,
      costRepairer
    });
    if (!result.data.quoteStripeId) {
      return { error: "Stripe Id could not be assigned to a quote" };
    }
    return result.data;
  }

  async addComment(claimId, comment) {
    try {
      const newComment = await axios.post(`/api/claims/${claimId}/comment`, {
        comment
      });
      runInAction(() => {
        this.currentClaim.comments.push(newComment.data);
      });
    } catch (error) {
      throw error;
    }
  }

  async sendBodyshopLead(claimId) {
    try {
      const { data } = await axios.post(`/api/claims/${claimId}/send-bodyshop-lead`);
      return { data };
    } catch (error) {
      return { error: error.toString() };
    }
  }

  async requestBodyshopApproval(id) {
    return axios.post(`/api/claims/${id}/request-bodyshop-approval`);
  }

  async addLabel(claimId, label) {
    const { data } = await axios.put(`/api/claims/${claimId}/add-label/${label}`);
    return { data };
  }

  async removeLabel(claimId, label) {
    const { data } = await axios.put(`/api/claims/${claimId}/remove-label/${label}`);
    return { data };
  }

  async returnToPremia(id, comment) {
    const response = await axios.put(`/api/claims/${id}/return-to-premia`, { comment });
    if (this.currentClaim && this.currentClaim._id === id && response.data) {
      this.currentClaim.status = response.data.status;
    }
    return response;
  }

}


export default new ClaimStore();
