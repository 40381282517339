import React, { Component } from 'react';
import claimStore from '../../../stores/claimStore';
import { observer } from 'mobx-react';

import {
  CircularProgress
} from '@material-ui/core';

import Panels from './Panels';

class ActionsContainer extends Component {
  state = {
    page: 0,
    rowsPerPage: 5
  };

  async componentDidMount() {
    await claimStore.getClaims(60);
  }


  render() {
    if (!claimStore.claims) {
      return (
        <CircularProgress/>
      );
    }

    return (
      <Panels/>
    );
  }
}

ActionsContainer = observer(ActionsContainer);
export default ActionsContainer;
