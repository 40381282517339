import React, { useState } from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
import { observer } from "mobx-react";
import LabelForm from "./LabelForm";
import labelsStore from "../../stores/labelsStore";

function CreateLabel(props) {
  const labelId = props.match.params.labelId;
  const [submitError, setSubmitError] = useState(null);
  const [label, setLabel] = useState({ name: "", description: "", automated: false, deleteAllowed: false });

  React.useEffect(() => {
    const getLabel = async (labelId) => {
      const label = await labelsStore.getLabel(labelId);
      setLabel({
        name: label.name,
        description: label.description,
        automated: label.metadata.automated,
        deleteAllowed: label.metadata.deleteAllowed
      });
    };
    if (labelId) {
      getLabel(labelId).catch(console.error);
    }
  }, [labelId]);
  const save = async values => {
    try {
      const {
        name,
        description,
        automated,
        deleteAllowed
      } = values;
      const data = {
        name,
        description,
        metadata: {
          automated,
          deleteAllowed
        }
      };

      if (labelId) {
        await labelsStore.updateLabel(labelId, data);
      } else {
        await labelsStore.createLabel(data);
      }
      props.history.push("/labels");
    } catch (error) {
      const submitError = get(error, "response.data.message", error.message);
      setSubmitError(submitError);
    }
  };

  return (
    <LabelForm
      initialValues={label}
      onSubmit={save}
      submitError={submitError}
      title={labelId ? "Update Label" : "Add Label"}
      submitLabel={labelId ? "Update Label" : "Add Label"}
    />
  );
}

CreateLabel.propTypes = {
  history: PropTypes.object
};

export default observer(CreateLabel);
