import { action, makeObservable } from "mobx";
import axios from "axios";


export class ClaimStore {

  constructor() {
    makeObservable(this, {
      createPaymentIntent: action,
      updatePaymentIntentWithRepairData: action
    });
  }

  async createPaymentIntent(claimId) {
    try {
      const response = await axios.post(`/api/payments/create-payment-intent/${claimId}`);
      return response.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async updatePaymentIntentWithRepairData(paymentIntentId, repairData) {
    try {
      const response = await axios.put(`/api/payments/update-payment-intent/${paymentIntentId}`, { repairData });
      return response.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async checkPaymentIntent(paymentIntentId) {
    try {
      const response = await axios.get(`/api/payments/check-payment-intent/${paymentIntentId}`);
      return response.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }


}


export default new ClaimStore();
