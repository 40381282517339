import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/theme/Grid/GridContainer.jsx";
import GridItem from "components/theme/Grid/GridItem.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import claimStore from "../../../stores/claimStore";
import Actions from "./Actions";

const styles = {
  cardTitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center"
  },
  cardCategory: {
    margin: "0",
    color: "#999999"
  }
};

class Panels extends React.Component {
  render() {
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
              <Actions actions={claimStore.claims } />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(styles)(Panels);
