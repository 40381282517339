import React from "react";
import PropTypes from "prop-types";
import { Dialog, Typography } from "@material-ui/core";
import Button from "../theme/CustomButtons/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import claimStore from "../../stores/claimStore";
import withStyles from "@material-ui/core/es/styles/withStyles";
import get from "lodash.get";
import TextField from "@material-ui/core/TextField";
import GarageSelect from "../marketplace/claims/GarageSelect";
import userStore from "../../stores/userStore";
import Restricted from "../restricted/Restricted";
import { PRIVILEGES } from "../../constants/privileges";
import OrganizationSelect from "../organizationSelect/OrganizationSelect";
import { ORGANIZATION_TYPES_KEYS } from "../../constants/organizationTypes";
import GridContainer from "../theme/Grid/GridContainer";
import GridItem from "../theme/Grid/GridItem";
import { Form } from "react-final-form";


const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  },
  distance: {
    padding: "5px",
    margin: "10px"
  }
});

function BidBodyshop(props) {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [file, setFile] = React.useState(null);
  const [cost, setCost] = React.useState(0);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeQuote = event => {
    setCost(event.target.value);
  };

  const addFile = async event => {
    try {
      const file = await uploadFile(event.target.files[0]);
      setFile(file);
    } catch (e) {
      setError(e.message);
    }
  };

  const uploadFile = file => {
    return new Promise(resolve => {
      const fileReader = new FileReader();

      fileReader.onload = () => {
        const fileData = fileReader.result;

        const fileToUpload = {
          data: fileData,
          contentType: file.type,
          name: file.name
        };

        resolve(fileToUpload);
      };
      fileReader.readAsDataURL(file);
    });
  };

  const addQuote = async (values) => {
    try {
      if (file) {
        const result = await claimStore.uploadFileToAws(file);
        const payload = {
          damageDetails: props.damages,
          organisation: props.organisation,
          garage: values.garage
        };
        await claimStore.addBid(payload)
        await claimStore.requestBidAuthorisation(claimStore.currentClaim._id, cost, cost, result.path);
      }
      handleClose();
      props.successHandler();
    } catch (err) {
      console.log(err);
      const error = get(err, "response.data.message", "Failed to request authorisation");
      setError(error);
    }
  };

  return (
    <Form
      onSubmit={addQuote}
      render={({ handleSubmit, form, values }) => (
        <form onSubmit={handleSubmit}>
          <Button variant="contained" color="primary" onClick={handleClickOpen}>
            Add Quote
          </Button>
          <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Authorisation</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please provide a quote and a supporting quote document for authorisation by claimant.
              </DialogContentText>
              <GridContainer spacing={12}>
                <Restricted
                  privileges={[PRIVILEGES.ReadOrganisations]}
                >
                  <GridItem xs={12} sm={12} md={12}>
                    <OrganizationSelect
                      types={[
                        ORGANIZATION_TYPES_KEYS.REPAIR_NETWORK,
                        ORGANIZATION_TYPES_KEYS.REPAIR_SHOP
                      ]}
                    />
                  </GridItem>
                </Restricted>
                <GridItem xs={12} sm={12} md={12}>
                  <GarageSelect
                    organization={userStore.currentUser.organizationsIds[0]}
                    setGarage={value => form.change("garage", value)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <TextField
                    id="cost"
                    label="Quote (ex. VAT)"
                    type="number"
                    defaultValue={cost}
                    className={props.classes.textField}
                    InputLabelProps={{
                      shrink: true
                    }}
                    onChange={handleChangeQuote}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <Button
                    variant="contained"
                    component="label"
                    color="primary"
                    className={props.classes.uploadButton}
                  >
                    <input
                      style={{ display: "none" }}
                      type="file"
                      id="uploadfile"
                      accept="application/pdf"
                      onChange={addFile}
                    />
                    Upload Quote File
                  </Button>
                </GridItem>
                {file && (
                  <GridItem xs={12} sm={12} md={12}>
                    <Typography
                      color="primary"
                      data-cy="file-ready"
                    >
                      Document received
                    </Typography>
                  </GridItem>
                )}
                {error && (
                  <GridItem xs={12} sm={12} md={12}>
                    <Typography
                      color="error"
                      align="left"
                      data-cy="login-error"
                    >
                      {error}
                    </Typography>
                  </GridItem>
                )}
              </GridContainer>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleSubmit} color="primary" name="request-add-bid">
                Add Quote
              </Button>
            </DialogActions>
          </Dialog>
        </form>)} />
  );
}

BidBodyshop.propTypes = {
  history: PropTypes.object,
  pickUp: PropTypes.string,
  damage: PropTypes.object,
  organisation: PropTypes.object,
  successHandler: PropTypes.func,
};

export default withStyles(styles)(BidBodyshop);
