import React from "react";
import claimStore from "../../../stores/claimStore";
import { observer } from "mobx-react";
import Claims from "./Claims";
import { CLAIM_STATUS_KEYS } from "../../../constants/claimStatus";

function InMarketplaceClaimsContainer() {
  const [filteredClaims, setFilteredClaims] = React.useState([]);

  React.useEffect(() => {
    async function getFilteredClaims() {
      const filteredClaims = await claimStore.getFilteredClaims(CLAIM_STATUS_KEYS.InMarketplace);
      setFilteredClaims(filteredClaims);
    }

    getFilteredClaims().catch(console.error);
  }, []);


  return (
    <Claims claims={filteredClaims} />
  );
}

export default observer(InMarketplaceClaimsContainer);
