import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import GridContainer from 'components/theme/Grid/GridContainer.jsx';
import GridItem from 'components/theme/Grid/GridItem.jsx';
import Input from 'components/form/Input';
import { isPositiveNumber } from '../../../validation/validation';
import Select from '../../form/Select';
import { Field } from 'react-final-form';
import { email } from '../../../validation/validation';

const style = {
  infoText: {
    fontWeight: '300',
    margin: '10px 0 30px',
    textAlign: 'center'
  },
  driverData: {
    margin: '20px',
    justify: 'right'
  }
};

const Condition = ({ when, is, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
);

class AboutForm extends React.Component {
  serviceOptions = [
    { label: 'Repair', value: 'repair' },
    { label: 'Desktop Damage Assessment', value: 'assessment' },
    { label: 'Roadside Assistance', value: 'assistance' },
    { label: 'Bodyshop Repair', value: 'bodyshop' },
    { label: 'Other', value: 'other' }
  ];

  render() {
    const { classes } = this.props;

    return (
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={12}>
          <h4 className={classes.infoText}>
            Claim information (optional)
          </h4>
        </GridItem>

        <GridItem xs={12} sm={12} md={12} lg={10}>
          <Select
            name='serviceRequired'
            label='Service Required'
            options={this.serviceOptions}
          />
        </GridItem>

        <Condition when="serviceRequired" is="other">
          <GridItem xs={12} sm={12} md={12} lg={10}>
            <Input name="serviceDescription" label="Service Description"/>
          </GridItem>
        </Condition>
        <GridItem xs={12} sm={12} md={12} lg={10}>
          <Input name="driver.name" label="Driver Name"/>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={10}>
          <Input name="driver.phoneNumber" label="Driver Phone Number"/>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={10}>
          <Input name="driver.email" label="Driver Email" validate={email}/>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={10}>
          <Input name="policynumber" label="Policy Number"/>
        </GridItem>

        <GridItem xs={12} sm={12} md={12} lg={10}>
          <Input name="policytype" label="Policy Type"/>
        </GridItem>

        <GridItem xs={12} sm={12} md={12} lg={10}>
          <Input name="excess" label="Excess (£)" validate={isPositiveNumber} type="number"/>
        </GridItem>

        <GridItem xs={12} sm={12} md={12} lg={10}>
          <Input name="externalReferences.audatex" label="Audatex Reference"/>
        </GridItem>

        <GridItem xs={12} sm={12} md={12} lg={10}>
          <Input name="externalReferences.premiaClaimReference" label="Premia Claim Reference"/>
        </GridItem>

        <GridItem xs={12} sm={12} md={12} lg={10}>
          <Input name="externalReferences.premiaRepairGuid" label="Premia Repair ID"/>
        </GridItem>

        <GridItem xs={12} sm={12} md={12} lg={10}>
          <Input name="insurerContact" label="Insurer Contact"/>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(style)(AboutForm);
