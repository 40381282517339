import React from 'react';
import NewClaimFormContainer from '../../claims/NewClaimFormContainer';
import { history } from '../../../history';

function ModifyClaim(props) {
    return (
      <NewClaimFormContainer
        claimId={props.match.params.claimId}
        history={history}
      />
    );
}

export default ModifyClaim;
