import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { Grid, Typography } from '@material-ui/core';
import { FieldArray } from 'react-final-form-arrays';
import Button from '@material-ui/core/Button';
import Input from './Input';
import { isRequired } from 'validation/validation';

const style = theme => ({
  uploadButton: {
    marginBottom: '12px'
  },
  fileContainer: {
    padding: '0 8px',
    border: `1px dashed ${theme.palette.primary.light}`,
    borderRadius: '8px'
  },
  file: {
    width: '100%',
    height: '100px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',

    '& img': {
      flex: '0 1',
      width: 'auto',
      height: 'auto',
      maxWidth: '100%',
      maxHeight: '100%'
    }
  }
});

class UploadImages extends Component {
  state = {
    uploadError: null
  };

  addFiles = async (event, fields) => {
    this.clearErrors();

    this.uploadFiles(Array.from(event.target.files)).then(
      ({ pdfs, errors }) => {
        fields.concat(pdfs);
        this.handleErrors(errors);
      }
    );
  };

  uploadFiles = files => {
    const promises = files.map(this.uploadFile);

    return Promise.all(promises).then(loadedFiles => {
      const pdfs = loadedFiles.filter(file => !file.error);
      const errors = loadedFiles
        .filter(file => file.error)
        .map(file => file.error);

      return { pdfs, errors };
    });
  };

  uploadFile = file => {
    return new Promise(resolve => {
      try {
        if (file.type.includes(this.props.extension)) {
          const fileReader = new FileReader();

          fileReader.onload = () => {
            const fileData = fileReader.result;

            const fileToUpload = {
              data: fileData,
              contentType: file.type,
              name: file.name
            };

            resolve(fileToUpload);
          };

          fileReader.readAsDataURL(file);
        } else {
          resolve({ error: 'Wrong format' });
        }
      } catch (e) {
        resolve({ error: 'Broken file' });
      }
    });
  };

  clearErrors = () => this.setState({ uploadError: null });

  handleErrors = errors => {
    if (errors.length > 0) {
      const uniqueErrors = [...new Set(errors)];
      this.setState({
        uploadError: `Following errors occurred with some of uploaded files: ${uniqueErrors.join(
          ', '
        )}`
      });
    }
  };

  render() {
    const { uploadError } = this.state;
    const { classes, title, filetype, description, image, name } = this.props;

    return (
      <Grid item xs={12} container direction='column'>
        <Grid item container direction='column' alignItems='center'>
          <Typography component='h1' variant='h5'>
            {title}
          </Typography>
        </Grid>
        <Grid item>
          <FieldArray name={name}>
            {({ fields, meta }) => (
              <>
                <div>
                  <Button
                    variant='contained'
                    component='label'
                    color='secondary'
                    className={classes.uploadButton}
                  >
                    <input
                      style={{ display: 'none' }}
                      type='file'
                      id='uploadfile'
                      accept={filetype}
                      multiple
                      onChange={event => this.addFiles(event, fields)}
                    />
                    {description}
                  </Button>
                </div>
                <Grid container spacing={16}>
                  {fields.map((name, index) => (
                    <Grid item xs={12} md={4} key={index}>
                      <div className={classes.fileContainer}>
                        <Input
                          name={`${name}.name`}
                          label='Name '
                          required
                          validate={isRequired}
                        />
                        <div className={classes.file}>
                          <img
                            onClick={() => fields.remove(index)}
                            src={image}
                            alt=''
                          />
                        </div>
                      </div>
                    </Grid>
                  ))}
                </Grid>
                {meta.dirty && uploadError && (
                  <Grid container spacing={16} alignItems='center'>
                    <Grid item>
                      <Typography color='error'>{uploadError}</Typography>
                    </Grid>
                  </Grid>
                )}
              </>
            )}
          </FieldArray>
        </Grid>
      </Grid>
    );
  }
}

UploadImages.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  filetype: PropTypes.string,
  image: PropTypes.string,
  extension: PropTypes.string
}

UploadImages.defaultProps = {
  title: 'Damage Documents',
  name: 'pdfs',
  filetype: 'application/pdf',
  extension: 'pdf',
  description: 'Choose files (pdf)',
};

export default withStyles(style)(UploadImages);
