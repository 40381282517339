import React, { useEffect, useState } from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import paymentStore from "../../stores/paymentStore";

function RepairDateSelected() {
  const [status, setStatus] = useState(null);

  useEffect(() => {
    const paymentIntent = new URLSearchParams(window.location.search).get(
      "payment_intent"
    );

    if (!paymentIntent) {
      setStatus("succeeded");
      return;
    }

    const checkPaymentIntent = async (paymentIntent) => {
      setStatus(await paymentStore.checkPaymentIntent(paymentIntent));
    };

    checkPaymentIntent(paymentIntent).catch(console.error);
  }, []);

  return (
    <Grid container>
      <Grid item sm={10}>
        <Paper style={{ padding: 20, margin: 30 }}>
          {status === "succeeded" && (
            <>
              <Typography align="center" paragraph variant="h6">
                Thank you, your repair date preference was recorded and you've been booked in.
              </Typography>
              <Typography align="center" variant="body1">
                Your technician will be in touch the day before your scheduled repair to arrange exact timing.<br />
                We will add the details of the repairer to the confirmation email you will receive.<br />
                If the repairer is not in touch 24h prior to your scheduled repair date, please contact them
                directly.<br />
                If there are any other issues, please let us know by sending an email to <a
                href="mailto:estimates@axioma.co.uk">estimates@axioma.co.uk</a>.<br />
                Best, The Axioma Team
              </Typography>
            </>)
          }
          {(status && status !== "succeeded") && (
            <Typography align="center" paragraph variant="headline">
              Unfortunately we were not able to take your payment.<br />
              Please try again or contact us at <a href="mailto:estimates@axioma.co.uk">estimates@axioma.co.uk</a> to
              book your repair date.<br />
              Best, The Axioma Team
            </Typography>
          )
          }
        </Paper>
      </Grid>
    </Grid>
  );
}

export default RepairDateSelected;
