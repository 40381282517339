import { Grid } from "@material-ui/core";
import ClaimCard from "./ClaimCard";
import React from "react";
import garagesStore from "../../../stores/garagesStore";
import userStore from "../../../stores/userStore";
import { CLAIM_STATUS_KEYS as ClaimStatusEnum } from "../../../constants/claimStatus";

const Claims = props => {
  const [claimsWithGarages, setClaimsWithGarages] = React.useState([]);
  const [userOrganizations, setUserOrganizations] = React.useState([]);
  const [garages, setGarages] = React.useState([]);

  const sortedClaims = props.claims.sort((a, b) => {
    const earliestDateA = a.pickup?.availableDates?.slice().sort()[0];
    const earliestDateB = b.pickup?.availableDates?.slice().sort()[0];
    const dateToCompareA = a.pickup?.date ? a.pickup?.date : earliestDateA;
    const dateToCompareB = b.pickup?.date ? b.pickup?.date : earliestDateB;
    if (dateToCompareA === undefined || dateToCompareA === null) {
      return 1;
    }
    if (dateToCompareB === undefined || dateToCompareB === null) {
      return -1;
    }
    return new Date(dateToCompareA) - new Date(dateToCompareB);
  });

  React.useEffect(() => {
    const garagesForClaims = async () => {
      setClaimsWithGarages(await Promise.all(sortedClaims.map(async claim => {
        let nearestGarages = [];
        if (claim.status === ClaimStatusEnum.InMarketplace) {
          try {
            nearestGarages = await garagesStore.findNearestGarages(claim.location);
          } catch (err) {
            console.log(err);
          }
        }
        return { claim, nearestGarages };
      })));
    };
    const organizationsForUser = async () => {
      setUserOrganizations(userStore.currentUser.organizationsIds);
    };

    const garagesForUser = async () => {
      setGarages(await garagesStore.getGarages());
    };

    garagesForUser().catch(console.error);
    garagesForClaims().catch(console.error);
    organizationsForUser().catch(console.error);
  }, [sortedClaims]);

  const filteredClaims = () => {
    if (userOrganizations.length === 1 && garages.length <= 1) {
      return claimsWithGarages.filter(claimWithGarages => claimWithGarages.status !== ClaimStatusEnum.InMarketplace || (claimWithGarages.nearestGarages.length > 0 && claimWithGarages.nearestGarages[0].distance < 10));
    } else {
      return claimsWithGarages;
    }
  };


  return (
    <Grid container spacing={10}>
      {filteredClaims().length > 0 ? (
          filteredClaims().map(claimWithGarages => {
              return (
                <Grid item xs={12} sm={4} lg={3} xl={3} key={claimWithGarages.claim._id}>
                  <ClaimCard claim={claimWithGarages.claim} nearestGarages={claimWithGarages.nearestGarages} />
                </Grid>
              );
            }
          )
        ) :
        (
          <Grid item>
            <h3>No claims in this section</h3>
          </Grid>
        )
      }
    </Grid>
  );
};

export default Claims;
