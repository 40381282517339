import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader, Typography, withStyles } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const styles = theme => ({
    root: {
        width: '500px',
        maxWidth: "100%",
        margin: 'auto',
    },
    avatar: {
        flexDirection: 'column',
    }
})

const SuccessStatement = ({classes, message, render}) => (
    <Card raised className={classes.root}>
        <CardHeader
            className={classes.avatar}
            avatar={<CheckCircleOutlineIcon color="secondary" fontSize="large" />}
        />
        <CardContent>
            {render
                ? render
                :
                <Typography
                    variant="subtitle1"
                    color="textPrimary"
                    align="center"
                >
                    {message}
                </Typography>
            }
        </CardContent>
    </Card>
)

SuccessStatement.propTypes = {
    message: PropTypes.string.isRequired
}

export default withStyles(styles)(SuccessStatement);
