import { groupBy } from '../utils/helpers';

export const dataCarModel = [
  {
    Make: 'ALFA ROMEO',
    Model: 'BRERA'
  },
  {
    Make: 'ALFA ROMEO',
    Model: 'GIULIA'
  },
  {
    Make: 'ALFA ROMEO',
    Model: 'GIULIETTA'
  },
  {
    Make: 'ALFA ROMEO',
    Model: 'MITO'
  },
  {
    Make: 'ALFA ROMEO',
    Model: 'STELVIO'
  },
  {
    Make: 'ALFA ROMEO',
    Model: 'Other'
  },
  {
    Make: 'AUDI',
    Model: 'A1'
  },
  {
    Make: 'AUDI',
    Model: 'A2'
  },
  {
    Make: 'AUDI',
    Model: 'A3'
  },
  {
    Make: 'AUDI',
    Model: 'A4'
  },
  {
    Make: 'AUDI',
    Model: 'A5'
  },
  {
    Make: 'AUDI',
    Model: 'A6'
  },
  {
    Make: 'AUDI',
    Model: 'A7'
  },
  {
    Make: 'AUDI',
    Model: 'A8'
  },
  {
    Make: 'AUDI',
    Model: 'Q2'
  },
  {
    Make: 'AUDI',
    Model: 'Q3'
  },
  {
    Make: 'AUDI',
    Model: 'Q5'
  },
  {
    Make: 'AUDI',
    Model: 'Q7'
  },
  {
    Make: 'AUDI',
    Model: 'Q8'
  },
  {
    Make: 'AUDI',
    Model: 'Other'
  },
  {
    Make: 'BMW',
    Model: '1 SERIES'
  },
  {
    Make: 'BMW',
    Model: '2 SERIES'
  },
  {
    Make: 'BMW',
    Model: '3 SERIES'
  },
  {
    Make: 'BMW',
    Model: '4 SERIES'
  },
  {
    Make: 'BMW',
    Model: '5 SERIES'
  },
  {
    Make: 'BMW',
    Model: '6 SERIES'
  },
  {
    Make: 'BMW',
    Model: '7 SERIES'
  },
  {
    Make: 'BMW',
    Model: '8 SERIES'
  },
  {
    Make: 'BMW',
    Model: 'Other'
  },
  {
    Make: 'CITROEN',
    Model: 'C1'
  },
  {
    Make: 'CITROEN',
    Model: 'C2'
  },
  {
    Make: 'CITROEN',
    Model: 'C3'
  },
  {
    Make: 'CITROEN',
    Model: 'C4'
  },
  {
    Make: 'CITROEN',
    Model: 'C5'
  },
  {
    Make: 'CITROEN',
    Model: 'C6'
  },
  {
    Make: 'CITROEN',
    Model: 'C8'
  },
  {
    Make: 'CITROEN',
    Model: 'Other'
  },
  {
    Make: 'FIAT',
    Model: '500'
  },
  {
    Make: 'FIAT',
    Model: 'PANDA'
  },
  {
    Make: 'FIAT',
    Model: 'Other'
  },
  {
    Make: 'FORD',
    Model: 'FIESTA'
  },
  {
    Make: 'FORD',
    Model: 'FOCUS'
  },
  {
    Make: 'FORD',
    Model: 'GALAXY'
  },
  {
    Make: 'FORD',
    Model: 'KA'
  },
  {
    Make: 'FORD',
    Model: 'Other'
  },
  {
    Make: 'FORD',
    Model: 'MONDEO'
  },
  {
    Make: 'HONDA',
    Model: 'ACCORD'
  },
  {
    Make: 'HONDA',
    Model: 'CRV'
  },
  {
    Make: 'HONDA',
    Model: 'Other'
  },
  {
    Make: 'HYUNDAI',
    Model: 'SANTA'
  },
  {
    Make: 'HYUNDAI',
    Model: 'TERRACAN'
  },
  {
    Make: 'HYUNDAI',
    Model: 'Other'
  },
  {
    Make: 'JAGUAR',
    Model: 'E TYPE'
  },
  {
    Make: 'JAGUAR',
    Model: 'XK SERIES'
  },
  {
    Make: 'JAGUAR',
    Model: 'Other'
  },
  {
    Make: 'KIA',
    Model: 'PICANTO'
  },
  {
    Make: 'KIA',
    Model: 'Other'
  },
  {
    Make: 'LAND ROVER',
    Model: 'DEFENDER'
  },
  {
    Make: 'LAND ROVER',
    Model: 'DISCOVERY'
  },
  {
    Make: 'LAND ROVER',
    Model: 'FREELANDER'
  },
  {
    Make: 'LAND ROVER',
    Model: 'RANGE ROVER'
  },
  {
    Make: 'LAND ROVER',
    Model: 'Other'
  },
  {
    Make: 'LEXUS',
    Model: 'LS400'
  },
  {
    Make: 'LEXUS',
    Model: 'Other'
  },
  {
    Make: 'MAZDA',
    Model: 'MX-3'
  },
  {
    Make: 'MAZDA',
    Model: 'MX-5'
  },
  {
    Make: 'MAZDA',
    Model: 'MX-6'
  },
  {
    Make: 'MAZDA',
    Model: 'Other'
  },
  {
    Make: 'MERCEDES',
    Model: 'A CLASS'
  },
  {
    Make: 'MERCEDES',
    Model: 'B CLASS'
  },
  {
    Make: 'MERCEDES',
    Model: 'C CLASS'
  },
  {
    Make: 'MERCEDES',
    Model: 'CL CLASS'
  },
  {
    Make: 'MERCEDES',
    Model: 'CLA CLASS'
  },
  {
    Make: 'MERCEDES',
    Model: 'CLS CLASS'
  },
  {
    Make: 'MERCEDES',
    Model: 'E CLASS'
  },
  {
    Make: 'MERCEDES',
    Model: 'G CLASS'
  },
  {
    Make: 'MERCEDES',
    Model: 'GL CLASS'
  },
  {
    Make: 'MERCEDES',
    Model: 'GLA CLASS'
  },
  {
    Make: 'MERCEDES',
    Model: 'GLC CLASS'
  },
  {
    Make: 'MERCEDES',
    Model: 'GLE CLASS'
  },
  {
    Make: 'MERCEDES',
    Model: 'GLS CLASS'
  },
  {
    Make: 'MERCEDES',
    Model: 'ML CLASS'
  },
  {
    Make: 'MG',
    Model: 'MGA'
  },
  {
    Make: 'MG',
    Model: 'MGB'
  },
  {
    Make: 'MG',
    Model: 'MGC'
  },
  {
    Make: 'MG',
    Model: 'MGF'
  },
  {
    Make: 'MG',
    Model: 'Other'
  },
  {
    Make: 'MINI',
    Model: 'COOPER'
  },
  {
    Make: 'MINI',
    Model: 'CLUBMAN'
  },
  {
    Make: 'MINI',
    Model: 'COUNTRYMAN'
  },
  {
    Make: 'MINI',
    Model: 'Other'
  },
  {
    Make: 'MITSUBISHI',
    Model: 'SHOGUN'
  },
  {
    Make: 'MITSUBISHI',
    Model: 'Other'
  },
  {
    Make: 'NISSAN',
    Model: 'MICRA'
  },
  {
    Make: 'NISSAN',
    Model: 'QASHQAI'
  },
  {
    Make: 'NISSAN',
    Model: 'X-TRAIL'
  },
  {
    Make: 'NISSAN',
    Model: 'Other'
  },
  {
    Make: 'PEUGEOT',
    Model: '206'
  },
  {
    Make: 'PEUGEOT',
    Model: '406'
  },
  {
    Make: 'PEUGEOT',
    Model: '407'
  },
  {
    Make: 'PEUGEOT',
    Model: 'Other'
  },
  {
    Make: 'PORSCHE',
    Model: '911'
  },
  {
    Make: 'PORSCHE',
    Model: 'Other'
  },
  {
    Make: 'RENAULT',
    Model: 'CLIO'
  },
  {
    Make: 'RENAULT',
    Model: 'MEGANE'
  },
  {
    Make: 'RENAULT',
    Model: 'TWINGO'
  },
  {
    Make: 'RENAULT',
    Model: 'Other'
  },
  {
    Make: 'SAAB',
    Model: '9-3'
  },
  {
    Make: 'SAAB',
    Model: 'Other'
  },
  {
    Make: 'SEAT',
    Model: 'IBIZA'
  },
  {
    Make: 'SEAT',
    Model: 'LEON'
  },
  {
    Make: 'SEAT',
    Model: 'Other'
  },
  {
    Make: 'SKODA',
    Model: 'FABIA'
  },
  {
    Make: 'SKODA',
    Model: 'Other'
  },
  {
    Make: 'SUZUKI',
    Model: 'JIMNY'
  },
  {
    Make: 'SUZUKI',
    Model: 'VITARA'
  },
  {
    Make: 'SUZUKI',
    Model: 'Other'
  },
  {
    Make: 'TOYOTA',
    Model: 'AYGO'
  },
  {
    Make: 'TOYOTA',
    Model: 'CAMRY'
  },
  {
    Make: 'TOYOTA',
    Model: 'COROLLA'
  },
  {
    Make: 'TOYOTA',
    Model: 'IQ'
  },
  {
    Make: 'TOYOTA',
    Model: 'LANDCRUISER'
  },
  {
    Make: 'TOYOTA',
    Model: 'PRIUS'
  },
  {
    Make: 'TOYOTA',
    Model: 'YARIS'
  },
  {
    Make: 'TOYOTA',
    Model: 'Other'
  },
  {
    Make: 'VAUXHALL',
    Model: 'ASTRA'
  },
  {
    Make: 'VAUXHALL',
    Model: 'CORSA'
  },
  {
    Make: 'VAUXHALL',
    Model: 'Other'
  },
  {
    Make: 'VOLKSWAGEN',
    Model: 'BEETLE'
  },
  {
    Make: 'VOLKSWAGEN',
    Model: 'BORA'
  },
  {
    Make: 'VOLKSWAGEN',
    Model: 'EOS'
  },
  {
    Make: 'VOLKSWAGEN',
    Model: 'GOLF'
  },
  {
    Make: 'VOLKSWAGEN',
    Model: 'PASSAT'
  },
  {
    Make: 'VOLKSWAGEN',
    Model: 'POLO'
  },
  {
    Make: 'VOLKSWAGEN',
    Model: 'SCIROCCO'
  },
  {
    Make: 'VOLKSWAGEN',
    Model: 'TIGUAN'
  },
  {
    Make: 'VOLKSWAGEN',
    Model: 'TOUAREG'
  },
  {
    Make: 'VOLKSWAGEN',
    Model: 'TOURAN'
  },
  {
    Make: 'VOLKSWAGEN',
    Model: 'UP'
  },
  {
    Make: 'VOLKSWAGEN',
    Model: 'Other'
  },
  {
    Make: 'VOLVO',
    Model: 'V40'
  },
  {
    Make: 'VOLVO',
    Model: 'V50'
  },
  {
    Make: 'VOLVO',
    Model: 'V60'
  },
  {
    Make: 'VOLVO',
    Model: 'V70'
  },
  {
    Make: 'VOLVO',
    Model: 'V90'
  },
  {
    Make: 'VOLVO',
    Model: 'XC40'
  },
  {
    Make: 'VOLVO',
    Model: 'XC60'
  },
  {
    Make: 'VOLVO',
    Model: 'XC70'
  },
  {
    Make: 'VOLVO',
    Model: 'XC90'
  },
  {
    Make: 'VOLKSWAGEN',
    Model: 'Other'
  }
];

export const groupedCarModels = groupBy(dataCarModel, 'Make');
