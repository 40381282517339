import React from "react";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "../../theme/Card/CardHeader";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import PlayArrow from "@material-ui/icons/PlayArrow";
import { Link } from "react-router-dom";
import ClaimImage from "../../claimImage/ClaimImage";
import withStyles from "@material-ui/core/styles/withStyles";
import Restricted from "../../restricted/Restricted";
import { PRIVILEGES } from "../../../constants/privileges";
import get from "lodash.get";
import { SERVICE_REQUIRED } from "../../../constants/claimStatus";
import Moment from "react-moment";
import { THUMBNAILS_SIZE } from "../../../constants/images";
import moment from "moment";
import PropTypes from "prop-types";

const claimCardStyle = {
  cardHeader: {
    height: "380px",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 16px",
    padding: 0,
    boxSizing: "border-box",
    overflow: "hidden",
    borderBottom: "1px solid #eee",
    "& img": {
      height: "auto",
      maxHeight: "100%",
      flex: "0 1",
      maxWidth: "100%",
      transform: "translateY(1px)"
    }
  },
  card: {
    backgroundColor: "#ffffff"
  },
  cardOverdue: {
    backgroundColor: "#eedad9"
  }
};

const ClaimCard = props => {
  const { claim, classes } = props;

  if (!claim) {
    return null;
  }

  const kmToMiles = (distance) => {
    const inMiles = distance * 0.621371;
    return inMiles.toFixed(2);
  };

  const winningBid = claim.bid.find(elem => elem.winning);
  const garage = winningBid && get(winningBid, "garage.name");
  const pickupDay = moment(claim.pickup?.date).startOf("day");
  const today = moment().startOf("day");
  const isOverdue = today.isAfter(pickupDay);
  const cardClass = isOverdue ? classes.cardOverdue : classes.card;
  return (
    <Card className={cardClass}>
      <CardHeader className={classes.cardHeader}>
        {claim.images.length > 0 ? (
          <ClaimImage imageId={claim.images[0]} width={THUMBNAILS_SIZE.medium} />
        ) : (
          <ClaimImage />
        )}
      </CardHeader>
      <CardContent>
        <Typography gutterBottom variant="h6" component="h6">
          {claim.vehicle.make} {claim.vehicle.model}: {claim.vehicle.registration}
        </Typography>
        <Typography component="p">
          Location: {claim.location}
        </Typography>
        {garage && (
          <Restricted privileges={[PRIVILEGES.CreateGarage]}>
            <Typography component="p">Garage: {garage}</Typography>
          </Restricted>
        )}
        {claim.serviceRequired &&
          <Typography component="p">
            Service
            Required: {claim.serviceRequired !== "other" ? SERVICE_REQUIRED[claim.serviceRequired] : claim.serviceDescription}
          </Typography>
        }
        {claim.pickup?.date && (
          <Typography component="p">
            Confirmed Pickup:{" "}
            <Moment format="DD/MM/YYYY">{claim.pickup.date}</Moment>
          </Typography>
        )}
        {!claim.pickup?.date && claim.pickup?.availableDates?.length > 0 && (
          <Typography component="p">
            Earliest Expected Pickup:{" "}
            <Moment format="DD/MM/YYYY">{claim.pickup.availableDates.slice().sort()[0]}</Moment>
          </Typography>
        )}
        {claim.pickup?.contactRequired && (
          <Typography component="p" color="error">
            Pickup date rejected
          </Typography>
        )}
        {props.nearestGarages && props.nearestGarages.length > 0 && (
          <Typography component="p">
            Distance: {kmToMiles(props.nearestGarages[0].distance)} miles
          </Typography>
        )}
      </CardContent>
      <CardActions>
        <Button
          size="small"
          color="primary"
          component={Link}
          to={"/marketplace/claims/" + claim._id}
        >
          <PlayArrow />
          Go To Claim
        </Button>
      </CardActions>
    </Card>
  );
};

ClaimCard.propTypes = {
  claim: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  nearestGarages: PropTypes.array
};

export default withStyles(claimCardStyle)(ClaimCard);
