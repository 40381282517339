import React from "react";
import PropTypes from "prop-types";
import Select from "components/form/Select";
import organizationStore from "../../stores/organizationsStore";
import userStore from "../../stores/userStore";
import { observer } from "mobx-react";
import { isRequired } from "../../validation/validation";
import { ORGANIZATION_TYPES_KEYS } from "../../constants/organizationTypes";

function OrganizationSelect(props) {
  React.useEffect(() => {
    async function getOrganizations() {
      if (userStore.hasManyOrganizations) {
        await organizationStore.getAllOrganizations();
      }
    }

    getOrganizations().catch(console.error);
  }, []);

  const organizations = organizationStore.organizations
    .filter(org => props.types.includes(org.type))
    .filter(org => !org.disabled)
    .map(org => ({
      label: org.name,
      value: org._id
    }))
    .sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase()) ? 1 : -1);

  if (
    !userStore.hasManyOrganizations ||
    organizationStore.organizations.length <= 1
  ) {
    return null;
  }

  return (
    <Select
      name="organisation"
      label="Organisation"
      options={organizations}
      required
      validate={isRequired}
    />
  );
}

OrganizationSelect.propTypes = {
  types: PropTypes.array
};

OrganizationSelect.defaultProps = {
  types: [ORGANIZATION_TYPES_KEYS.FLEET]
};

export default observer(OrganizationSelect);
