import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from 'components/theme/CustomButtons/Button.jsx';
import Card from 'components/theme/Card/Card.jsx';
import wizardStyle from 'assets/jss/material-dashboard-pro-react/components/wizardStyle.jsx';
import { Hidden, Typography } from '@material-ui/core';

class Wizard extends React.Component {
  constructor(props) {
    super(props);

    let width;

    if (this.props.steps.length === 1) {
      width = '100%';
    } else {
      if (window.innerWidth < 600) {
        if (this.props.steps.length !== 3) {
          width = '50%';
        } else {
          width = 100 / 3 + '%';
        }
      } else {
        if (this.props.steps.length === 2) {
          width = '50%';
        } else {
          width = 100 / 3 + '%';
        }
      }
    }

    this.state = {
      color: this.props.color,
      width: width,
      movingTabStyle: {
        transition: 'transform 0s'
      },
      allStates: []
    };

    this.wizardRef = React.createRef();
  }

  componentDidMount() {
    this.refreshAnimation(0);
    window.addEventListener('resize', this.updateWidth);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentStep !== this.props.currentStep) {
      this.updateWidth();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWidth);
  }

  updateWidth = () => {
    this.refreshAnimation(this.props.currentStep);
  };

  refreshAnimation = index => {
    const total = this.props.steps.length;
    const total_steps = this.props.steps.length;
    let li_width = 100 / total;
    let move_distance = this.wizardRef.current.offsetWidth / total_steps;
    let index_temp = index;
    let vertical_level = 0;

    let mobile_device = window.innerWidth < 600 && total > 3;

    if (mobile_device) {
      move_distance = this.wizardRef.current.offsetWidth / 2;
      index_temp = index % 2;
      li_width = 50;
    }

    this.setState({ width: li_width + '%' });

    let step_width = move_distance;
    move_distance = move_distance * index_temp;

    let current = index + 1;

    if (current === 1 || (mobile_device === true && index % 2 === 0)) {
      move_distance -= 8;
    } else if (
      current === total_steps ||
      (mobile_device === true && index % 2 === 1)
    ) {
      move_distance += 8;
    }

    if (mobile_device) {
      vertical_level = parseInt(index / 2, 10);
      vertical_level = vertical_level * 38;
    }
    let movingTabStyle = {
      width: step_width,
      transform: 'translate(' + move_distance + 'px, ' + vertical_level + 'px)',
      transition: 'all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1)'
    };
    this.setState({ movingTabStyle: movingTabStyle });
  };

  render() {
    const {
      form,
      classes,
      title,
      subtitle,
      color,
      steps,
      currentStep,
      onStepClick,
      previous,
      pending,
      error,
      success
    } = this.props;
    const isFirstStep = currentStep === 0;
    const isLastStep = currentStep === steps.length - 1;
    const CurrentStepComponent = steps[currentStep].stepComponent;

    return (
      <div className={classes.wizardContainer} ref={this.wizardRef}>
        <Card className={classes.card}>
          <div className={classes.wizardHeader}>
            <h3 className={classes.title}>{title}</h3>
            <h5 className={classes.subtitle}>{subtitle}</h5>
          </div>
          <Hidden mdDown>
          <div className={classes.wizardNavigation}>
            <ul className={classes.nav}>
              {steps.map((prop, key) => {
                return (
                  <li
                    className={classes.steps}
                    key={key}
                    style={{ width: this.state.width }}
                  >
                    <span
                      className={classes.stepsAnchor}
                      onClick={() => onStepClick(key, form)}
                    >
                      {prop.stepName}
                    </span>
                  </li>
                );
              })}
            </ul>
            <div
              className={classes.movingTab + ' ' + classes[color]}
              style={this.state.movingTabStyle}
            >
              {steps[currentStep].stepName}
            </div>
          </div>
          </Hidden>
          <div className={classes.content}>
            <CurrentStepComponent form={form} />
          </div>
          <div className={classes.footer}>
            {success ? (
              <Typography variant='h5'>Successfully submitted.</Typography>
            ) : null}
            {error ? (
              <Typography color='error' variant='h5'>
                {error}
              </Typography>
            ) : null}
            <div className={classes.left}>
              {!isFirstStep && (
                <Button
                  className={this.props.previousButtonClasses}
                  onClick={previous}
                >
                  Previous
                </Button>
              )}
            </div>
            <div className={classes.right}>
              {!isLastStep && (
                <Button
                  color='rose'
                  type='submit'
                  className={this.props.nextButtonClasses}
                >
                  Next
                </Button>
              )}
              {isLastStep && (
                <Button
                  color='rose'
                  className={this.finishButtonClasses}
                  type='submit'
                  disabled={pending}
                >
                  {pending ? 'Processing...' : 'Finish'}
                </Button>
              )}
            </div>
            <div className={classes.clearfix} />
          </div>
        </Card>
      </div>
    );
  }
}

Wizard.defaultProps = {
  color: 'rose'
};

Wizard.propTypes = {
  classes: PropTypes.object.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      stepName: PropTypes.string.isRequired,
      stepComponent: PropTypes.func.isRequired,
      stepId: PropTypes.string.isRequired
    })
  ).isRequired,
  currentStep: PropTypes.number.isRequired,
  onStepClick: PropTypes.func.isRequired,
  previous: PropTypes.func.isRequired,
  color: PropTypes.oneOf([
    'primary',
    'warning',
    'danger',
    'success',
    'info',
    'rose'
  ]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  pending: PropTypes.bool,
  error: PropTypes.string,
  success: PropTypes.bool
};

export default withStyles(wizardStyle)(Wizard);
