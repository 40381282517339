import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Typography
} from "@material-ui/core";
import Input from "../form/Input";
import { email, isRequired } from "../../validation/validation";
import { Form } from "react-final-form";
import { PRIVILEGES } from "../../constants/privileges";
import OrganizationSelect from "../organizationSelect/OrganizationSelect";
import Restricted from "../restricted/Restricted";
import { ORGANIZATION_TYPES_KEYS } from "../../constants/organizationTypes";
import Select from "../form/Select";

function GarageForm(props) {
  const {
    onSubmit,
    initialValues,
    submitError,
    title,
    showOrganization,
    submitLabel,
    usersSelect
  } = props;

  return (
    <Grid container justifyContent="center">
      <Grid item md={12} lg={6} xl={4}>
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              <Card>
                <CardHeader title={title} />
                <CardContent>
                  {showOrganization && (
                    <Restricted privileges={[PRIVILEGES.ReadOrganisations]}>
                      <OrganizationSelect
                        types={[ORGANIZATION_TYPES_KEYS.REPAIR_NETWORK]}
                      />
                    </Restricted>
                  )}
                  <Input
                    name="name"
                    label="Name"
                    required
                    validate={isRequired}
                  />
                  <Input
                    name="repairerName"
                    label="Repairer Name"
                  />
                  <Input
                    id="address1"
                    name="address1"
                    label="Address Line 1"
                  />
                  <Input
                    id="address2"
                    name="address2"
                    label="Address Line 2"
                  />
                  <Input id="city" name="city" label="City" />
                  <Input id="postcode" name="postcode" label="Postcode" />
                  <Input id="phone" name="phone" label="Phone" />
                  <Input
                    name="email"
                    label="Email"
                    type="email"
                    validate={email}
                  />
                  <Select
                    id='user'
                    name='user'
                    label='Assigned User'
                    options={usersSelect}
                  />
                </CardContent>
                <CardActions>
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <Button
                        color="secondary"
                        type="submit"
                        disabled={submitting}
                      >
                        {submitLabel}
                      </Button>
                    </Grid>
                  </Grid>
                </CardActions>
              </Card>
              {submitError && (
                <Typography color="error" align="center">
                  {submitError}
                </Typography>
              )}
            </form>
          )}
        />
      </Grid>
    </Grid>
  );
}

GarageForm.propTypes = {
  initialValues: PropTypes.object,
  submitError: PropTypes.string,
  title: PropTypes.string,
  submitLabel: PropTypes.string,
  onSubmit: PropTypes.func,
  showOrganization: PropTypes.bool,
  usersSelect: PropTypes.array
};

export default GarageForm;
