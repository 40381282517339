import React from "react";
import PropTypes from "prop-types";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CheckboxControl from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import checkboxStyle from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Field } from "react-final-form";

function Checkbox(props) {
  const { name, label, classes, onClick } = props;

  return (
    <Field
      name={name}
      type="checkbox"
      render={({ input }) => {
        return (
          <FormControlLabel
            label={label}
            control={
              <CheckboxControl
                {...input}
                checked={input.checked}
                value={input.name}
                onClick={onClick}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot
                }}
              />
            }
            classes={{
              label: classes.label
            }}
          />
        );
      }}
    />
  );
}

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default withStyles(checkboxStyle)(Checkbox);
