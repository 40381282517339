import React, {Component} from 'react';
import {Paper} from '@material-ui/core';
import Grid from "@material-ui/core/Grid/Grid";

class BidCreated extends Component {
  render() {
    return (
      <Grid container>
        <Grid item sm={10}>
          <Paper style={{padding: 20, margin: 30}}>
            <h1>Thank you, claim is allocated.</h1>
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

export default BidCreated;
