const OrganisationPrivileges = {
  CreateOrganisation: 'CREATE_ORGANISATION',
  ReadOrganisations: 'READ_ORGANISATIONS',
  UpdateFnolCode: 'UPDATE_FNOL_CODE',
  ReadFnolCode: 'READ_FNOL_CODE',
  ExecuteUserInvite: 'EXECUTE_USER_INVITE'
};

const LicensePlatePrivileges = {
  ReadLicensePlate: 'READ_LICENSE_PLATE'
};

const ClaimPrivileges = {
  ReadClaims: 'READ_CLAIMS',
  ReadRepairClaims: 'READ_REPAIR_CLAIMS',
  UpdateClaimStatus: 'UPDATE_CLAIM_STATUS',
  CreateClaimBid: 'CREATE_CLAIM_BID',
  CreateClaim: 'CREATE_CLAIM',
  UpdateClaim: 'UPDATE_CLAIM',
  DeleteClaim: 'DELETE_CLAIM',
  CreateClaimDamage: 'CREATE_CLAIM_DAMAGE',
  CreateClaimComment: 'CREATE_CLAIM_COMMENT',
  CreateClaimImage: 'CREATE_CLAIM_IMAGE',
  DeleteClaimImage: 'DELETE_CLAIM_IMAGE',
  ReadClaimsStats: 'READ_CLAIM_STATS',
  ConfirmClaimPayment: 'CONFIRM_CLAIM_PAYMENT',
  RequestQuoteAuthorisation: 'REQUEST_QUOTE_AUTHORISATION',
  RequestBidAuthorisation: 'REQUEST_BID_AUTHORISATION',
  RequestFeedback: 'REQUEST_FEEDBACK',
  RejectClaim: 'REJECT_CLAIM',
  ArchiveAll: 'ARCHIVE_ALL',
  RollbackClaim: 'ROLLBACK_CLAIM',
  ReadBodyshopClaims: 'READ_BODYSHOP_CLAIMS',
  ApproveBodyshopRepair: 'APPROVE_BODYSHOP_REPAIR',
};

const QuotePrivilegesEnum = {
  ReadClientQuote: 'READ_CLIENT_QUOTE',
  ReadRepairerQuote: 'READ_REPAIRER_QUOTE'
};

const GaragesPrivileges = {
  ReadGarages: 'READ_GARAGES',
  CreateGarage: 'CREATE_GARAGE',
  DeleteGarage: 'DELETE_GARAGE',
  UpdateGarage: 'UPDATE_GARAGE',
  CreateSingleGarage: 'CREATE_SINGLE_GARAGE'
};

const ImagesPrivileges = {
  ReadImage: 'READ_IMAGE'
};

const SuperUserPrivileges = {
  ExecuteSuperAdminInvite: 'EXECUTE_SUPER_ADMIN_INVITE',
  SuperAdminUpdateOrgData: 'SUPER_ADMIN_UPDATE_ORG_DATA',
  SuperAdminUpdateQuote: 'SUPER_ADMIN_UPDATE_QUOTE',
  SuperAdminOverridePickupDate: "SUPER_ADMIN_OVERRIDE_PICKUP_DATE",
  SuperAdminChangeRepairer: "SUPER_ADMIN_CHANGE_REPAIRER",
  SuperAdminRepairerLocation: "SUPER_ADMIN_REPAIRER_LOCATION",
  SuperAdminLabels: "SUPER_ADMIN_LABELS",
  SuperAdminExternalPremia: "SUPER_ADMIN_EXTERNAL_PREMIA",
};

export const PRIVILEGES = {
  ...OrganisationPrivileges,
  ...LicensePlatePrivileges,
  ...ClaimPrivileges,
  ...GaragesPrivileges,
  ...ImagesPrivileges,
  ...SuperUserPrivileges,
  ...QuotePrivilegesEnum
};
