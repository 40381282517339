import React from "react";
import ReactExport from "react-export-excel-xlsx-fix";
import PropTypes from 'prop-types';
import Button from '../../theme/CustomButtons/Button';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


class ExcelDownload extends React.Component {

  render() {
    return (
      <ExcelFile element={<Button color="primary">Download As Sheet</Button>}>
        <ExcelSheet data={this.props.dataSet} name="Claims Report">
          <ExcelColumn label="Claim ID" value="id"/>
          <ExcelColumn label="Claim" value="claim"/>
          <ExcelColumn label="Status" value="status"/>
          <ExcelColumn label="License plate" value="license"/>
          <ExcelColumn label="Policy nr" value="policynumber"/>
          <ExcelColumn label="Created At (GMT)" value="createdAt"/>
          <ExcelColumn label="Repair Allocated (GMT)" value="biddingDate"/>
          <ExcelColumn label="Estimate Issued (GMT)" value="awaitingDate"/>
          <ExcelColumn label="Estimate Approved (GMT)" value="inMarketplaceDate"/>
          <ExcelColumn label="Pickup Request Issued (GMT)" value="pickupDate"/>
          <ExcelColumn label="In-Repair Date (GMT)" value="inRepairDate"/>
          <ExcelColumn label="Repair Confirmed Date (GMT)" value="repairConfirmedDate"/>
          <ExcelColumn label="Finished Date (GMT)" value="finishedDate"/>
          <ExcelColumn label="Garage" value="garage"/>
          <ExcelColumn label="Quote" value="quote"/>
          <ExcelColumn label="Organisation" value="organisation"/>
          <ExcelColumn label="Booked in (Hours)" value="bookedIn"/>
          <ExcelColumn label="Estimate Issued(Days Mon-Fri)" value="estimateDuration"/>
          <ExcelColumn label="Time to find Repairer(Days Mon-Fri)" value="findRepairerDuration"/>
          <ExcelColumn label="Vehicle Off Road(Days Mon-Fri)" value="offRoadDuration"/>
          <ExcelColumn label="Turnaround(Days Mon-Fri)" value="turnaroundDuration"/>
        </ExcelSheet>
      </ExcelFile>
    );
  }
}

ExcelDownload.propTypes = {
  dataSet: PropTypes.array.isRequired,
};

export default ExcelDownload;
