import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { observer } from "mobx-react";
import AdminTabs from "./AdminTabs";
import claimStore from "../../stores/claimStore";

function Admin() {
  React.useEffect(() => {
    async function getStats() {
      await claimStore.getAdminStats(60);
    }

    getStats().catch(console.error);
  }, []);

  return (
    <React.Fragment>
      <CssBaseline />
      <AdminTabs />
    </React.Fragment>
  );
}

export default observer(Admin);
