import React from "react";
import LoginForm from "./LoginForm";
import { observer } from "mobx-react";
import userStore from "../../stores/userStore";
import { withRouter } from "react-router";
import { PRIVILEGES } from "../../constants/privileges";
import get from "lodash.get";

function Login(props) {
  const { cardAnimation } = React.useState("cardHidden");
  const [ error, setError ] = React.useState(null);

  const getRedirectPath = () => {
    if (userStore.hasPrivilege(PRIVILEGES.ReadClaims)) {
      return "/home";
    }

    if (userStore.hasPrivilege(PRIVILEGES.ReadBodyshopClaims)) {
      return "/bodyshopHome";
    }

    if (userStore.hasPrivilege(PRIVILEGES.ReadRepairClaims)) {
      return "/repairerHome";
    }

    return "404";
  };

  React.useEffect(() => {
    const redirectIfAuth = () => {
      if (userStore.isAuthenticated) {
        props.history.push(getRedirectPath());
      }
    }
    redirectIfAuth()
  }, [props]);

  const onSubmit = async values => {
    try {
      await userStore.loginUser(values);
      const { from } = props.location.state || {
        from: { pathname: getRedirectPath() }
      };
      props.history.replace(from);
    } catch (err) {
      const error = get(err, "response.data.error", "Invalid credentials");
      setError(error);
    }
  };

  return (
    <LoginForm
      cardAnimation={cardAnimation}
      error={error}
      onSubmit={onSubmit}
      logoutMessage={userStore.logoutMessage}
    />
  );
}

export default withRouter(observer(Login));
