import { generateConstantsList } from '../utils/helpers';

export const ORGANIZATION_TYPES_KEYS = {
  REPAIR_SHOP: 'REPAIR_SHOP',
  REPAIR_NETWORK: 'REPAIR_NETWORK',
  FLEET: 'FLEET',
  SMART_REPAIRER: 'SMART_REPAIRER'
};

export const ORGANIZATION_TYPES = {
  [ORGANIZATION_TYPES_KEYS.REPAIR_SHOP]: 'Repair Bodyshop',
  [ORGANIZATION_TYPES_KEYS.REPAIR_NETWORK]: 'Bodyshop Repair Network',
  [ORGANIZATION_TYPES_KEYS.FLEET]: 'Fleet',
  [ORGANIZATION_TYPES_KEYS.SMART_REPAIRER]: 'Smart Repairer'
};

export const ORGANIZATION_TYPES_LIST = generateConstantsList(
  ORGANIZATION_TYPES
);
