import React, { Component } from "react";
import PropTypes from "prop-types";
import { Dialog, Typography } from "@material-ui/core";
import Button from "../../theme/CustomButtons/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import claimStore from "../../../stores/claimStore";
import withStyles from "@material-ui/core/es/styles/withStyles";
import get from "lodash.get";
import OrganizationSelect from "../../organizationSelect/OrganizationSelect";
import { ORGANIZATION_TYPES_KEYS } from "../../../constants/organizationTypes";
import { Form } from "react-final-form";
import GarageSelect from "../../marketplace/claims/GarageSelect";

const styles = theme => ({
  container: {
    width: "90%",
    display: "flex",
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  }
});

class ChangeRepairer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      error: null
    };
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = (form) => {
    if (form) {
      form.reset();
    }
    this.setState({ open: false, error: null });
  };


  changeRepairer = async values => {
    try {
      await claimStore.changeRepairer(claimStore.currentClaim._id, values);
      await claimStore.getClaim(claimStore.currentClaim._id);
      this.handleClose();
      this.props.successHandler();
    } catch (err) {
      console.log(err);
      const error = get(err, "response.data.message", "Failed to change repairer");
      this.setState({ error });
    }
  };


  render() {
    const { classes } = this.props;
    return (
      <Form
        onSubmit={this.changeRepairer}
        render={({ handleSubmit, form, values }) => (
          <form onSubmit={handleSubmit}>
            <Button variant="contained" color="primary" onClick={this.handleClickOpen}>
              Change Repairer
            </Button>
            <Dialog open={this.state.open} onClose={() => this.handleClose(form)} aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title">Change Repairer</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Please provide the new repairer.
                </DialogContentText>
                <grid container>
                  <grid item>
                    <OrganizationSelect
                      types={[
                        ORGANIZATION_TYPES_KEYS.REPAIR_NETWORK,
                        ORGANIZATION_TYPES_KEYS.REPAIR_SHOP,
                        ORGANIZATION_TYPES_KEYS.SMART_REPAIRER
                      ]}
                    />
                  </grid>
                  <grid item className={classes.container}>
                    <GarageSelect
                      organization={values.organisation}
                      setGarage={value => form.change("garage", value)}
                    />
                  </grid>
                </grid>
                {this.state.error && (
                  <Typography
                    color="error"
                    align="left"
                    data-cy="login-error"
                  >
                    {this.state.error}
                  </Typography>
                )}

              </DialogContent>
              <DialogActions>
                <Button onClick={() => this.handleClose(form)} color="primary">
                  Cancel
                </Button>
                <Button
                  color="primary"
                  type="submit"
                  name="change-repairer"
                  onClick={handleSubmit}
                >Change Repairer</Button>
              </DialogActions>
            </Dialog>
          </form>)} />
    );
  }
}

ChangeRepairer.propTypes = {
  successHandler: PropTypes.func
};

export default withStyles(styles)(ChangeRepairer);
