import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { Form } from 'react-final-form';
import organizationsStore from '../../stores/organizationsStore';
import get from 'lodash.get';
import { Grid, Typography } from '@material-ui/core';
import Radio from '../form/Radio';

const OrganizationDisabledForm = ({ closeDialog, id }) => {
  const [error, setError] = useState(null);

  const updateDisabled = async values => {
    try {
      await organizationsStore.disableEnable(id, { disabled: values.disabledField === 'true' });

      closeDialog();
    } catch (err) {
      const error = get(err, 'response.data.message', err.message);
      setError(error);
    }
  };

  const isDisabled = () => {
    return organizationsStore.currentOrganization.disabled;
  };

  return (
    <Form
      onSubmit={updateDisabled}
      initialValues={{
        disabledField: `${!isDisabled()}`
      }}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <DialogTitle>Disable/Enable organization</DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item>
                <Radio
                  name="disabledField"
                  value="true"
                  id="disableTrue"
                  label="Disable"
                />
              </Grid>
              <Grid item>
                <Radio
                  name="disabledField"
                  value="false"
                  id="disableFalse"
                  label="Enable"
                />
              </Grid>
            </Grid>
            {error && (
              <Typography color="error" align="center">
                {error}
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="secondary">
              Send
            </Button>
          </DialogActions>
        </form>
      )}
    />
  );
};

OrganizationDisabledForm.propTypes = {
  id: PropTypes.string,
  closeDialog: PropTypes.func
};

export default OrganizationDisabledForm;
