import PropTypes from "prop-types";
import GridItem from "../theme/Grid/GridItem";
import DetailsCard from "../common/DetailsCard";
import { Assignment, AssignmentTurnedIn, Done, Label, LocalOffer, Map } from "@mui/icons-material";
import Card from "../theme/Card/Card";
import CardBody from "../theme/Card/CardBody";
import ClaimComments from "../claimComments/ClaimComments";
import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import Moment from "react-moment";
import { CLAIM_STATUS, CLAIM_STATUS_KEYS, SERVICE_REQUIRED } from "../../constants/claimStatus";
import get from "lodash.get";
import Restricted from "../restricted/Restricted";
import { PRIVILEGES } from "../../constants/privileges";
import garagesStore from "../../stores/garagesStore";
import userStore from "../../stores/userStore";
import labelsStore from "../../stores/labelsStore";
import claimStore from "../../stores/claimStore";
import GridContainer from "../theme/Grid/GridContainer";
import CardHeader from "../theme/Card/CardHeader";
import CardIcon from "../theme/Card/CardIcon";
import withStyles from "@material-ui/core/styles/withStyles";
import dashboardStyle from "../../assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

function ClaimDetails(props) {

  const { claim, quote, classes } = props;
  const repairerLocationRequiredStatuses = [CLAIM_STATUS_KEYS.InMarketplace, CLAIM_STATUS_KEYS.Fnol, CLAIM_STATUS_KEYS.AwaitingQuoteAuthorisation, CLAIM_STATUS_KEYS.Registered];
  const [nearestRepairers, setNearestRepairers] = React.useState([]);
  const [labels, setLabels] = React.useState([]);
  const [claimLabels, setClaimLabels] = React.useState([]);

  useEffect(() => {
    const fetchRepairers = async () => {
      if (userStore.isAllowed(PRIVILEGES.ReadGarages)) {
        setNearestRepairers(await garagesStore.findNearestGarages(claim.location));
      }
    };

    const fetchLabels = async () => {
      if (userStore.isAllowed(PRIVILEGES.SuperAdminLabels)) {
        const allLabels = await labelsStore.getAllLabels()
        setLabels(allLabels);
        claim.metadata?.labels && setClaimLabels(allLabels.filter(l => claim.metadata.labels.includes(l._id)));
      }
    };

    fetchRepairers().catch(console.error);
    fetchLabels().catch(console.error);
  }, [claim.location, claim.metadata?.labels]);

  const toServiceRequired = (claim) => {
    if (!claim.serviceRequired) return "Repair";
    if (claim.serviceRequired !== "other") {
      return SERVICE_REQUIRED[claim.serviceRequired];
    } else return claim.serviceDescription;
  };
  const composeClaimInformation = (claim) => {
    const basicData = [
      ["Registration plates", claim.vehicle.registration],
      [
        "Time of event",
        <Moment format="DD/MM/YYYY">{claim.date}</Moment>
      ],
      ["Status", CLAIM_STATUS[claim.status]],
      ["Service Required", toServiceRequired(claim)],
      ["Vehicle Location", claim.location]
    ];
    const driverName = claim.driver && claim.driver.name ? [["Driver", claim.driver.name]] : [];
    const driverPhone = claim.driver && claim.driver.phoneNumber ? [["Driver Phone Number", claim.driver.phoneNumber]] : [];
    const driverEmail = claim.driver && claim.driver.email ? [["Driver Email Address", claim.driver.email]] : [];


    return [...basicData, ...driverName, ...driverPhone, ...driverEmail];
  };

  const composeRepairDateInformation = (claim) => {
    const repairDate = claim.pickup.date;
    const basicData = [
      [
        "Repair Date",
        <Moment format="DD/MM/YYYY">{repairDate}</Moment>
      ]
    ];

    return [...basicData];
  };

  const composeRepairDates = (claim) => {
    const repairDates = claim.pickup.availableDates.slice().sort();
    const mappedDates = repairDates.map(d => <li><Moment format="DD/MM/YYYY">{d}</Moment></li>);
    const basicData = [
      [
        "Proposed Dates",
        <React.Fragment>{mappedDates}</React.Fragment>
      ]
    ];
    return [...basicData];
  };

  const kmToMiles = (distance)  => {
    const inMiles =  distance * 0.621371;
    return inMiles.toFixed(2);
  }

  const composeRepairLocations = (claim) => {
    const mappedGarages = nearestRepairers.map(garageWithDistance =>[garageWithDistance.garage.name, `${kmToMiles(garageWithDistance.distance)} miles`]);
    return [...mappedGarages];
  };

  let bidInfo = [];
  let quoteInfo = [];
  let damages = [];
  const winningBid = claim.bid.find(b => b.winning);

  if (typeof winningBid !== "undefined") {
    bidInfo = [
      ["Repair shop", get(winningBid, "garage.name")],
    ];

    damages = (winningBid.damageDetails || claim.damage || []).map(d => [
      d.part,
      d.action
    ]);
  }

  if (quote !== null && quote.cost) {
    quoteInfo = [
      ["Total cost (ex. VAT)", "£" + quote.cost]
    ];
  }

  const onLabelChange = async (event, value, reason) => {
    if (reason === "selectOption") {
      const last = value.pop();
      await claimStore.addLabel(claim._id, last.name)
      await claimStore.getClaim(claim._id);
    } else if (reason === "removeOption") {
      const removed = claimLabels.filter(x => !value.includes(x));
      if (removed.length >0) {
        await claimStore.removeLabel(claim._id, removed[0].name)
        await claimStore.getClaim(claim._id);
      }
    }
  }

  return (<Grid container>
    <GridItem md={6} name="claim-details">
      <DetailsCard
        icon={<Assignment />}
        color={"primary"}
        title={"Claim Information"}
        tableData={composeClaimInformation(claim)}
      />
    </GridItem>
    <GridItem md={6}>
      {claim.bid.length > 0 && (
        <DetailsCard
          icon={<LocalOffer />}
          color={"danger"}
          title={"Repairer"}
          tableData={[...bidInfo, ...damages]}
        />
      )}
    </GridItem>
    <GridItem md={6}>
      {quote && quote.cost && (
        <DetailsCard
          icon={<AssignmentTurnedIn />}
          color={"primary"}
          title={"Quote"}
          tableData={[...quoteInfo]}
        />
      )}
    </GridItem>
    <GridItem md={6} name="pickup">
      {claim.pickup && claim.pickup.date && (
        <DetailsCard
          icon={<Done />}
          color={"primary"}
          title={"Repair Date Confirmed"}
          tableData={composeRepairDateInformation(claim)}
        />
      )}
      {claim.pickup && claim.pickup.availableDates.length > 0 && !claim.pickup.date && (
        <DetailsCard
          icon={<Done />}
          color={"primary"}
          title={"Repair Dates"}
          tableData={composeRepairDates(claim)}
        />
      )}
    </GridItem>
    {repairerLocationRequiredStatuses.includes(claim.status) && (
      <Restricted
        privileges={[PRIVILEGES.SuperAdminRepairerLocation]}
      >
        <GridItem md={6}>
          <DetailsCard
            icon={<Map />}
            color={"primary"}
            title={"Nearest Repairers"}
            tableData={composeRepairLocations(claim)}
          />
        </GridItem>
      </Restricted>)}
      <Restricted
        privileges={[PRIVILEGES.SuperAdminLabels]}
      >
        <GridItem md={6}>
          <GridContainer>
            <GridItem xs={12}>
              <Card>
                <CardHeader color={"primary"} icon>
                  <CardIcon color={"primary"}><Label /></CardIcon>
                  <h4 className={classes.cardIconTitle}>Labels</h4>
                </CardHeader>
                <CardBody>
                  <GridContainer justifyContent='space-between'>
                    <GridItem xs={12} sm={12} md={12}>
                      <Autocomplete
                        multiple
                        id="claim-labels"
                        options={labels}
                        getOptionLabel={(option) => option.name}
                        value={claimLabels}
                        onChange={onLabelChange}
                        isOptionEqualToValue={(option, value) => option.name === value.name}
                        filterSelectedOptions
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            placeholder="Add label"
                          />
                        )}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </GridItem>
      </Restricted>
    <GridItem xs={12} md={12} lg={12}>
      <Card>
        <CardBody>
          <ClaimComments
            comments={claim.comments}
            claimId={claim._id}
          />
        </CardBody>
      </Card>
    </GridItem>
  </Grid>);
}


ClaimDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  claim: PropTypes.object,
  quote: PropTypes.object
};

export default withStyles(dashboardStyle)(ClaimDetails);