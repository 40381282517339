import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Input from '../form/Input';
import { email, isRequired, pipe } from '../../validation/validation';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { Form } from 'react-final-form';
import Select from '../form/Select';
import { USER_ROLES_LIST } from '../../constants/userRoles';
import organizationsStore from '../../stores/organizationsStore';
import get from 'lodash.get';
import { Typography } from '@material-ui/core';

const OrganizationInviteForm = ({ closeDialog, id }) => {
  const [error, setError] = useState(null);

  const sendInvitation = async values => {
    try {
      await organizationsStore.inviteUserToOrganization(id, values);

      closeDialog();
    } catch (err) {
      const error = get(err, 'response.data.message', err.message);
      setError(error);
    }
  };
  return (
    <Form
      onSubmit={sendInvitation}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <DialogTitle>Invite User</DialogTitle>
          <DialogContent>
            <Input
              label='Email'
              name='email'
              required
              validate={pipe(
                isRequired,
                email
              )}
            />

            <Select
              name='role'
              label='User role'
              options={USER_ROLES_LIST}
              required
              validate={isRequired}
              data-cy='user-role'
            />

            {error && (
              <Typography color='error' align='center'>
                {error}
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog} color='primary'>
              Cancel
            </Button>
            <Button type='submit' color='secondary'>
              Send
            </Button>
          </DialogActions>
        </form>
      )}
    />
  );
};

OrganizationInviteForm.propTypes = {
  id: PropTypes.string,
  closeDialog: PropTypes.func
};

export default OrganizationInviteForm;
