import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Dialog } from '@material-ui/core';
import carDefaultIcon from 'assets/img/defaultCar.png';
import claimStore from '../../stores/claimStore';
import withStyles from '@material-ui/core/styles/withStyles';
import ImagePreview from './ImagePreview';

const styles = {
  container: {
    width: '100%',
    position: 'relative'
  },
  main: {
    padding: '8px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    '& img': {
      width: '100%',
      flex: '0 1'
    }
  },
  small: {
    height: '120px',
    boxSizing: 'border-box',
    '& img': {
      height: '100%',
      width: 'auto'
    },
    padding: '8px',
    cursor: 'pointer'
  }
};

class ClaimImageGallery extends Component {
  state = {
    fullImages: {},
    previewId: null
  };

  defaultImage = { name: '', data: carDefaultIcon };

  componentDidUpdate(prevProps) {
    const { images } = this.props;

    if (images !== prevProps.images) {
      this.setState({ previewId: null, fullImages: {} });
    }
  }

  fetchFullImage = async imageId => {
    try {
      const images = await claimStore.getFilesData([imageId]);

      if (images.length) {
        this.setState(prevState => ({
          fullImages: {
            ...prevState.fullImages,
            [imageId]: images[0].data
          }
        }));
      }
    } catch (error) {
      this.closePreview();
    }
  };

  closePreview = () => this.setState({ previewId: null });

  setPreviewId = async (previewId) => {
    this.setState({ previewId });

    if (!this.state.fullImages[previewId]) {
      await this.fetchFullImage(previewId);
    }
  };

  render() {
    const { classes, thumbnails, imagesIds } = this.props;
    const [mainThumbnail, ...previewThumbnails] = thumbnails;
    const { previewId, fullImages } = this.state;

    return (
      <>
        <Dialog
          open={previewId !== null}
          onClose={this.closePreview}
          maxWidth={false}
        >
          <ImagePreview
            setPreviewId={this.setPreviewId}
            previewId={previewId}
            fullImages={fullImages}
            imagesIds={imagesIds}
          />
        </Dialog>
        <Grid container direction='column' className={classes.container}>
          <Grid item>
            <div
              className={classes.main}
              onClick={() =>
                mainThumbnail && this.setPreviewId(mainThumbnail._id)
              }
            >
              <img src={(mainThumbnail || this.defaultImage).data} alt='' />
            </div>
          </Grid>
          {!!previewThumbnails.length && (
            <Grid item>
              <Grid container wrap='wrap'>
                {previewThumbnails.slice(0, 8).map(thumbnail => {
                  return (
                    <Grid item key={thumbnail._id}>
                      <div
                        className={classes.small}
                        onClick={() => this.setPreviewId(thumbnail._id)}
                      >
                        <img
                          src={thumbnail.data}
                          alt={thumbnail.name}
                          title={thumbnail.name}
                        />
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          )}
        </Grid>
      </>
    );
  }
}

ClaimImageGallery.propTypes = {
  imagesIds: PropTypes.arrayOf(PropTypes.string),
  thumbnails: PropTypes.arrayOf(PropTypes.object)
};

ClaimImageGallery.defaultProps = {
  imagesIds: [],
  thumbnails: []
};

export default withStyles(styles)(ClaimImageGallery);
