import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
import garagesStore from "../../stores/garagesStore";
import userStore from "../../stores/userStore";
import ErrorWrapper from "../common/ErrorWrapper";
import LoadingWrapper from "../common/LoadingWrapper";
import { observer } from "mobx-react";
import GarageForm from "./GarageForm";

function GarageDetails(props) {
  const garageId = props.match.params.id;
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [users, setUsers] = useState([]);

  const getGarage = useCallback(async () => {
    try {
      await garagesStore.getCurrentGarageDetails(garageId);
    } catch (e) {
      setIsError(true);
    }
    setLoading(false);
  }, [garageId]);

  const getUsers = useCallback(async () => {
    try {
      const us = await userStore.getUsersData();
      setUsers(us);
    } catch (e) {
      setIsError(true);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    async function populateData() {
      await getGarage();
      await getUsers();
    }

    populateData().catch(console.error);
  }, [getGarage, getUsers]);

  const refetch = async () => {
    setLoading(true);
    setIsError(false);
    await getGarage();
  };

  const updateGarage = async values => {
    try {
      const { repairerName, name, address1, address2, city, postcode, phone, email, user } = values;
      const mappedUser = users.find(u => u._id === user);
      const data = {
        repairerName,
        name,
        address1,
        address2,
        city,
        postcode,
        phone,
        email,
        user: mappedUser ? mappedUser._id : userStore.currentUser._id
      };

      await garagesStore.updateGarage(garageId, data);
      props.history.push("/garages");
    } catch (error) {
      const submitError = get(error, "response.data.message", error.message);
      setSubmitError(submitError);
    }
  };

  const usersSelect = () => {
    return users.map(user => {
      return { label: user.email, value: user._id };
    });
  };

  const currentGarageToForm = () => {
    return {
      ...garagesStore.currentGarage,
      user: garagesStore.currentGarage?.user ? users.find(user => user._id === garagesStore.currentGarage.user._id)?._id : null
    };
  };

  const renderForm = () => {
    return (
      <GarageForm
        initialValues={{ ...currentGarageToForm() }}
        onSubmit={updateGarage}
        submitError={submitError}
        title="Garage Details"
        submitLabel="Update"
        usersSelect={usersSelect()}
      />
    );
  };

  return (
    <LoadingWrapper isLoading={loading}>
      <ErrorWrapper isError={isError} onRetry={refetch}>
        {renderForm()}
      </ErrorWrapper>
    </LoadingWrapper>
  );
}

GarageDetails.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object
};

export default observer(GarageDetails);
