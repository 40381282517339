const dataCarMake = [
  {
    "value": "ALFA ROMEO",
    "label": "ALFA ROMEO"
  },
  {
    "value": "AUDI",
    "label": "AUDI"
  },
  {
    "value": "BMW",
    "label": "BMW"
  },
  {
    "value": "CITROEN",
    "label": "CITROEN"
  },
  {
    "value": "FIAT",
    "label": "FIAT"
  },
  {
    "value": "FORD",
    "label": "FORD"
  },
  {
    "value": "HONDA",
    "label": "HONDA"
  },
  {
    "value": "HYUNDAI",
    "label": "HYUNDAI"
  },
  {
    "value": "JAGUAR",
    "label": "JAGUAR"
  },
  {
    "value": "KIA",
    "label": "KIA"
  },
  {
    "value": "LAND ROVER",
    "label": "LAND ROVER"
  },
  {
    "value": "LEXUS",
    "label": "LEXUS"
  },
  {
    "value": "MAZDA",
    "label": "MAZDA"
  },
  {
    "value": "MERCEDES",
    "label": "MERCEDES"
  },
  {
    "value": "MG",
    "label": "MG"
  },
  {
    "value": "MINI",
    "label": "MINI"
  },
  {
    "value": "MITSUBISHI",
    "label": "MITSUBISHI"
  },
  {
    "value": "NISSAN",
    "label": "NISSAN"
  },
  {
    "value": "PEUGEOT",
    "label": "PEUGEOT"
  },
  {
    "value": "PORSCHE",
    "label": "PORSCHE"
  },
  {
    "value": "RENAULT",
    "label": "RENAULT"
  },
  {
    "value": "SAAB",
    "label": "SAAB"
  },
  {
    "value": "SEAT",
    "label": "SEAT"
  },
  {
    "value": "SKODA",
    "label": "SKODA"
  },
  {
    "value": "SUZUKI",
    "label": "SUZUKI"
  },
  {
    "value": "TOYOTA",
    "label": "TOYOTA"
  },
  {
    "value": "VAUXHALL",
    "label": "VAUXHALL"
  },
  {
    "value": "VOLKSWAGEN",
    "label": "VOLKSWAGEN"
  },
  {
    "value": "VOLVO",
    "label": "VOLVO"
  }
];

export { dataCarMake };
