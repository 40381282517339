import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Typography } from '@material-ui/core';
import Button from '../../theme/CustomButtons/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import claimStore from '../../../stores/claimStore';
import withStyles from '@material-ui/core/es/styles/withStyles';
import get from 'lodash.get';
import TextField from '@material-ui/core/TextField';
import moment from "moment";

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    uploadButton: {
        marginBottom: '12px'
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
    },
});

function ModifyDate(props) {
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(null);
    const [date, setDate] = useState(moment(props.date).format('YYYY-MM-DD'));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChangeDate = event => {
        setDate(event.target.value);
    };

    const modifyDate = async () => {
        try {
            await claimStore.overrideRepairDate(claimStore.currentClaim._id, date);
            await claimStore.getClaim(claimStore.currentClaim._id);
            handleClose();
            props.successHandler();
        } catch (err) {
            console.log(err);
            const error = get(err, 'response.data.message', 'Failed to override repair date');
            setError(error);
        }
    };

    const {classes} = props;
    return (
      <>
          <Button variant="contained" color="primary" onClick={handleClickOpen}>
              Override Repair Date
          </Button>
          <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title">Modify Date</DialogTitle>
              <DialogContent>
                  <DialogContentText>
                      Please provide the new date of repair.
                  </DialogContentText>
                  <TextField
                    id="date"
                    label="Repair Date"
                    type="date"
                    defaultValue={date}
                    className={classes.textField}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={handleChangeDate}
                  />
                  {error && (
                    <Typography
                      color='error'
                      align='left'
                      data-cy='login-error'
                    >
                        {error}
                    </Typography>
                  )}
              </DialogContent>
              <DialogActions>
                  <Button onClick={handleClose} color="primary">
                      Cancel
                  </Button>
                  <Button onClick={modifyDate} color="primary" name="modify-quote">
                      Override Date
                  </Button>
              </DialogActions>
          </Dialog>
      </>
    );
}

ModifyDate.propTypes = {
    successHandler: PropTypes.func,
    date: PropTypes.object,
};

export default withStyles(styles)(ModifyDate);