import React, { Component } from 'react';
import axios from 'axios';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Typography
} from '@material-ui/core';
import { Send } from '@material-ui/icons';
import { Form } from 'react-final-form';
import Input from '../form/Input';
import { email, isRequired, pipe } from '../../validation/validation';
import GridContainer from '../theme/Grid/GridContainer';
import GridItem from '../theme/Grid/GridItem';

class InviteAdmin extends Component {
  state = {
    error: null,
    successMessage: null
  };

  handleSubmit = async values => {
    try {
      this.setState({ successMessage: null });
      await this.sendInvitation(values);
      this.setState({
        error: false,
        successMessage: 'Invitation sent successfully'
      });
    } catch (error) {
      if (error.response && error.response.data) {
        this.setState({ error: error.response.data.message });
      }
    }
  };

  sendInvitation = data => {
    return axios.post('/api/invites/super-admin', data);
  };

  render() {
    const { error, successMessage } = this.state;

    return (
      <GridContainer justifyContent='center'>
        <GridItem xs={12} lg={6} xl={4}>
          <Form
            onSubmit={this.handleSubmit}
            initialValues={{
              email: ''
            }}
            render={({ handleSubmit, submitting }) => (
              <form onSubmit={handleSubmit}>
                <Card>
                  <CardHeader title='Invite Admin' />
                  <CardContent>
                    <Input
                      name='email'
                      label='Email'
                      required
                      validate={pipe(
                        isRequired,
                        email
                      )}
                    />
                  </CardContent>
                  <CardActions>
                    <GridContainer justifyContent='flex-end'>
                      <GridItem>
                        <Button
                          color='secondary'
                          type='submit'
                          disabled={submitting}
                        >
                          <Send />
                          Invite
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </CardActions>
                </Card>
                {error && (
                  <Typography color='error' align='center'>
                    {error}
                  </Typography>
                )}
                {successMessage && (
                  <Typography color='textSecondary' align='center'>
                    {successMessage}
                  </Typography>
                )}
              </form>
            )}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

export default InviteAdmin;
