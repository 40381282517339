import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { AppBar, Tab, Tabs } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import claimStore from '../../stores/claimStore';
import AdminStats from './AdminStats';
import { observer } from 'mobx-react';

function TabContainer(props) {
  return (
    <Typography component='div' style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper
  }
});

class AdminTabs extends React.Component {
  state = {
    value: 0
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { classes } = this.props;
    const { value } = this.state;
    return (
      <div className={classes.root}>
        <AppBar position='static' color='default'>
          <Tabs
            value={value}
            onChange={this.handleChange}
            indicatorColor='primary'
            textColor='primary'
            variant='scrollable'
            scrollButtons='auto'
          >
            <Tab label='Claim Stats' />
          </Tabs>
        </AppBar>
        {value === 0 && (
          <TabContainer>
            <AdminStats claims={claimStore.claimAdminStats} />
          </TabContainer>
        )}
      </div>
    );
  }
}

AdminTabs.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(observer(AdminTabs));
