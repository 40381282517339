import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Axioma2024Logo from '../../assets/img/logo/axioma-logo.png';
import StripeLogo from '../../assets/img/logo/Stripe_Logo,_revised_2016.svg';

const styles = theme =>({
    root: {
        textAlign: "center",
        "& img": {
            width: "100px",
            margin: "10px",
        }
    }
});

const logosArr = {
    axioma: Axioma2024Logo,
    stripe: StripeLogo
}

const Logo = ({classes, logo = "axioma"}) => (
    <div className={classes.root}>
        <img
            src={logosArr[logo]}
            alt="Logo"
        />
    </div>
)

Logo.propTypes = {
    logo: PropTypes.oneOf([
        "axioma",
        "stripe"
    ])
}

export default withStyles(styles)(Logo);
