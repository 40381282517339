import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import cx from 'classnames';

const styles = {
  spinner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    minWidth: '300px',
  },
  small: {
    height: '100px',
    width: '100px',
    margin: 'auto'
  },
  fullScreen: {
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0
  }
};

const Spinner = ({ fullScreen, classes, message }) => {
  return (
      <>
        <div
            className={cx(
                classes.spinner,
                fullScreen ? classes.fullScreen : classes.small
            )}
        >
          <CircularProgress />
          {message}
        </div>
      </>
  );
};

Spinner.propTypes = {
  fullScreen: PropTypes.bool
};

export default withStyles(styles)(Spinner);
