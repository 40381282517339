import {
  DirectionsCar,
  Dvr,
  LibraryBooks,
  CheckCircleOutline,
  Cancel,
  Help
} from '@material-ui/icons';
import React from 'react';
import { CLAIM_STATUS_KEYS } from '../../constants/claimStatus';

const claimStatusConfig = [
  {
    name: 'FNOL',
    status: CLAIM_STATUS_KEYS.Fnol,
    icon: <LibraryBooks />,
    color: 'warning'
  },
  {
    name: 'Registered Claims',
    status: CLAIM_STATUS_KEYS.Registered,
    icon: <LibraryBooks />,
    color: 'primary'
  },
  {
    name: 'Awaiting Quote Authorisation',
    status: CLAIM_STATUS_KEYS.AwaitingQuoteAuthorisation,
    icon: <Help />,
    color: 'warning'
  },
  {
    name: 'Claims in Marketplace',
    status: CLAIM_STATUS_KEYS.InMarketplace,
    icon: <LibraryBooks />,
    color: 'primary'
  },
  {
    name: 'Repairer Allocated',
    status: CLAIM_STATUS_KEYS.Bidding,
    icon: <Dvr />,
    color: 'warning'
  },
  {
    name: 'Awaiting Bid Authorisation',
    status: CLAIM_STATUS_KEYS.AwaitingBidAuthorisation,
    icon: <Dvr />,
    color: 'warning'
  },
  {
    name: 'Awaiting Repair',
    status: CLAIM_STATUS_KEYS.AwaitingPickup,
    icon: <DirectionsCar />,
    color: 'primary'
  },
  {
    name: 'Vehicles in Repair',
    status: CLAIM_STATUS_KEYS.InRepair,
    icon: <DirectionsCar />,
    color: 'success'
  },
  {
    name: 'Finished Claims',
    status: CLAIM_STATUS_KEYS.Finished,
    icon: <CheckCircleOutline />,
    color: 'warning'
  },
  {
    name: 'Archived Claims',
    status: CLAIM_STATUS_KEYS.Archived,
    icon: <Cancel />,
    color: 'warning'
  },
  {
    name: 'Paid Claims',
    status: CLAIM_STATUS_KEYS.Paid,
    icon: <CheckCircleOutline />,
    color: 'success'
  }
];

export function getStatusConfigDetails(status) {
  return claimStatusConfig.find(filter => filter.status === status) || {};
}

export default claimStatusConfig;
