import React from "react";
import PropTypes from "prop-types";
import { Dialog, Grid, Typography } from "@material-ui/core";
import Button from "../theme/CustomButtons/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import claimStore from "../../stores/claimStore";
import withStyles from "@material-ui/core/es/styles/withStyles";
import get from "lodash.get";
import TextField from "@material-ui/core/TextField";

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  uploadButton: {
    marginBottom: "12px"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  }
});

function RequestAuthorisation(props) {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [file, setFile] = React.useState(null);
  const [cost, setCost] = React.useState(0);
  const [costRepairer, setCostRepairer] = React.useState(0);
  const [takeRate, setTakeRate] = React.useState(0);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeQuote = event => {
    setCost(event.target.value);
    setTakeRate(getTakeRate(costRepairer, event.target.value));
  };

  const handleChangeQuoteRepairer = event => {
    setCostRepairer(event.target.value);
    setTakeRate(getTakeRate(event.target.value, cost));
  };

  const addFile = async event => {
    try {
      const file = await uploadFile(event.target.files[0]);
      setFile(file);
    } catch (e) {
      setError(e.message);
    }
  };

  const uploadFile = file => {
    return new Promise(resolve => {
      const fileReader = new FileReader();

      fileReader.onload = () => {
        const fileData = fileReader.result;

        const fileToUpload = {
          data: fileData,
          contentType: file.type,
          name: file.name
        };

        resolve(fileToUpload);
      };
      fileReader.readAsDataURL(file);
    });
  };

  const getTakeRate = (costRepairer, cost) => {
    return (1 - (costRepairer / cost)) * 100;
  };

  const addQuote = async () => {
    try {
      if (file) {
        const result = await claimStore.uploadFileToAws(file);
        await claimStore.requestQuoteAuthorisationB2B(claimStore.currentClaim._id, cost, costRepairer, result.path);
      } else {
        await claimStore.requestQuoteAuthorisationB2B(claimStore.currentClaim._id, cost, costRepairer);
      }
      await claimStore.getClaim(claimStore.currentClaim._id);
      handleClose();
      props.successHandler();
    } catch (err) {
      console.log(err);
      const error = get(err, "response.data.message", "Failed to request authorisation");
      setError(error);
    }
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Add Quote
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Authorisation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please provide a quote and optionally a supporting quote document for authorisation by claimant.
          </DialogContentText>
          <Grid container>
            <Grid item>
              <TextField
                id="cost"
                label="Client Quote (ex. VAT)"
                type="number"
                defaultValue={cost}
                className={props.classes.textField}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={handleChangeQuote}
              />
            </Grid>
            <Grid item>
              <TextField
                id="costRepairer"
                label="Repairer Quote (ex. VAT)"
                type="number"
                defaultValue={costRepairer}
                className={props.classes.textField}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={handleChangeQuoteRepairer}
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                component="label"
                color="primary"
                className={props.classes.uploadButton}
              >
                <input
                  style={{ display: "none" }}
                  type="file"
                  id="uploadfile"
                  accept="application/pdf"
                  onChange={addFile}
                />
                Upload Quote File
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography
                align="left"
              >
                Take Rate: {Math.round(takeRate)} %
              </Typography>
            </Grid>
            {file && (
              <Grid item>
                <Typography
                  color="primary"
                  data-cy="file-ready"
                >
                  Document received
                </Typography>
              </Grid>
            )}
            {error && (
              <Grid item>
                <Typography
                  color="error"
                  align="left"
                  data-cy="login-error"
                >
                  {error}
                </Typography>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={addQuote} color="primary" name="request-authorise">
            Add Quote
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


RequestAuthorisation.propTypes = {
  successHandler: PropTypes.func
};

export default withStyles(styles)(RequestAuthorisation);
