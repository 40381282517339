import GridItem from "../theme/Grid/GridItem";
import Card from "../theme/Card/Card";
import CardHeader from "../theme/Card/CardHeader";
import CardIcon from "../theme/Card/CardIcon";
import { Link } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import React from "react";
import { observer } from "mobx-react";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import claimStore from "../../stores/claimStore";
import PropTypes from "prop-types";
import Restricted from "../restricted/Restricted";
import { PRIVILEGES } from "../../constants/privileges";
import userStore from "../../stores/userStore";
import {
  CLAIM_STATUS_KEYS
} from "../../constants/claimStatus";

function DashboardCard(props) {
  const { icon, name, status, color, classes } = props;
  const [filteredClaimsCount, setFilteredClaimsCountCount] = React.useState(null);
  const [medianTime, setMedianTime] = React.useState(0);

  React.useEffect(() => {
    async function getFilteredClaims() {
      const filteredClaimsCount = await claimStore.getClaimCount(status);
      setFilteredClaimsCountCount(filteredClaimsCount);
    }

    async function getMedianTime() {
      if (userStore.isAllowed(PRIVILEGES.ReadClaimsStats) && [CLAIM_STATUS_KEYS.Fnol, CLAIM_STATUS_KEYS.Registered, CLAIM_STATUS_KEYS.InMarketplace, CLAIM_STATUS_KEYS.Bidding, CLAIM_STATUS_KEYS.AwaitingPickup].includes(status)) {
        setMedianTime(await claimStore.getMedianTime(status));
      }
    }

    getFilteredClaims().catch(console.error);
    getMedianTime().catch(console.error);
  }, [status]);

  if (!filteredClaimsCount) {
    return null;
  }

  const medianDays = Math.floor(medianTime / 1440);
  const medianHours = Math.floor((medianTime % 1440) / 60);
  const medianMinutes = Math.ceil(medianTime % 60);
  const displayDays = medianDays > 0 ? `${medianDays} Days ` : "";
  const displayHours = medianHours > 0 ? `${medianHours} Hrs ` : "";
  const displayMinutes = medianMinutes > 0 ? `${medianMinutes} Mins` : "";
  return (
    <GridItem xs={12} sm={4} md={4} lg={4}>
      <Card>
        <Link to={`/dashboard/${status}`}>
          <CardHeader color={color} stats icon>
            <CardIcon color={color}>{icon}</CardIcon>
            <p className={classes.cardCategory}>{name}</p>
            <h4 className={classes.cardTitle}>Count: {filteredClaimsCount}</h4>
            <Restricted privileges={[PRIVILEGES.ReadClaimsStats]}>
              {medianTime > 0 && (<h4 className={classes.cardTitle}>Average In: {
                `${displayDays}${displayHours}${displayMinutes}`}</h4>)}
            </Restricted>
          </CardHeader>
        </Link>
      </Card>
    </GridItem>
  );
}

DashboardCard.propTypes = {
  icon: PropTypes.element,
  name: PropTypes.string,
  status: PropTypes.string,
  color: PropTypes.string,
  classes: PropTypes.object
};

export default withStyles(dashboardStyle)(observer(DashboardCard));
