import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormWizard from 'components/form/Wizard/FormWizard';
import arrayMutators from 'final-form-arrays';
import AboutForm from './ClaimSteps/AboutForm.js';
import AccidentForm from './ClaimSteps/AccidentForm.js';
import VehicleForm from './ClaimSteps/VehicleForm.js';
import claimStore from '../../stores/claimStore';
import WizardDamageForm from './ClaimSteps/WizardDamageForm.js';
import { getFilesToDelete, getNewFiles } from '../../utils/files';
import LoadingWrapper from '../common/LoadingWrapper';
import { toJS } from 'mobx';

class NewClaimFormContainer extends Component {
  state = {
    pending: false,
    loading: true,
    claimSaved: false
  };

  isUpdate = !!this.props.claimId;

  steps = [
    { stepName: 'Vehicle', stepComponent: VehicleForm, stepId: 'vehicle' },
    { stepName: 'Accident', stepComponent: AccidentForm, stepId: 'accident' },
    { stepName: 'About', stepComponent: AboutForm, stepId: 'about' },
    { stepName: 'Damage', stepComponent: WizardDamageForm, stepId: 'damage' }
  ];

  async componentDidMount() {
    if (this.isUpdate) {
        await claimStore.getClaim(this.props.claimId);
        const { organisation, ...claim } = toJS(claimStore.currentClaim);
        const pdfs = await claimStore.getFilesData(claim.pdfs);
        const videos = await claimStore.getFilesData(claim.videos);
        this.setState({
          loading: false,
          initialValues: {
            ...claim,
            organisation: organisation._id,
            images: [...claim.thumbnails],
            pdfs,
            videos
          },
        });
    } else {
      this.setState({
        loading: false,
        initialValues: {
          serviceRequired: 'repair',
          excess: 0,
          damage: []
        }
      });
    }
  }

  onSubmit = async values => {
    if (!this.state.pending) {
      this.setState({ pending: true });
      const { images, pdfs, videos, ...claimData } = values;
      const { history } = this.props;

      try {
        let claimId;
        if (this.isUpdate) {
          claimId = this.props.claimId;
          if (!this.state.claimSaved) {
            await claimStore.modifyClaim(claimId, claimData);
          }
          this.setState({ claimSaved: true });
          await this.saveImages(images, claimId);
          await this.saveFiles(pdfs, this.state.initialValues.pdfs, claimId);
          await this.saveFiles(videos, this.state.initialValues.videos, claimId);
        } else {
          if (!this.state.claimSaved) {
            const createdClaim = await claimStore.addClaim(claimData);
            this.setState({ claimSaved: true, claimId: createdClaim._id });
          }
          claimId = this.state.claimId;
          if (images && images.length) {
            await this.saveImages(images, claimId);
          }
          if (pdfs && pdfs.length) {
            await this.saveFiles(pdfs, this.state.initialValues.pdfs, claimId);
          }
          if (videos && videos.length) {
            await this.saveFiles(videos, this.state.initialValues.videos, claimId);
          }
        }
        history.push(`/dashboardClaim/${claimId}`);
      } catch (error) {
        console.log('error', error);
        this.setState({ error: error.message });
      }
      this.setState({ pending: false });
    }
  };

  saveImages = async (images, claimId) => {
    const newImages = getNewFiles(images);

    await claimStore.addClaimFiles(claimId, newImages);

    if (this.isUpdate) {
      const imagesToDelete = getFilesToDelete(
        images,
        claimStore.currentClaim.thumbnails
      );

      await claimStore.deleteFiles(claimId, imagesToDelete);
    }
  };

  saveFiles = async (files, initial, claimId) => {
    const newFiles = getNewFiles(files);
    await claimStore.addClaimFiles(claimId, newFiles);

    if (this.isUpdate) {
      const filesToDelete = getFilesToDelete(
        files, initial
      );

      await claimStore.deleteFiles(claimId, filesToDelete);
    }
  };

  render() {
    const { pending, error, loading } = this.state;

    if (loading) {
      return <LoadingWrapper isLoading />;
    }

    return (
      <FormWizard
        formProps={{
          mutators: {
            ...arrayMutators
          }
        }}
        title='Enter Claim Details'
        subtitle='This information will create the claim instance.'
        initialValues={this.state.initialValues}
        steps={this.steps}
        onSubmit={this.onSubmit}
        pending={pending}
        error={error}
      />
    );
  }
}

NewClaimFormContainer.propTypes = {
  history: PropTypes.object,
  claimId: PropTypes.string
};

export default NewClaimFormContainer;
