import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import Moment from 'react-moment';
import get from 'lodash.get';
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Grid,
  Paper,
  CircularProgress
} from '@material-ui/core';
import claimStore from '../../../stores/claimStore';
import { ORGANIZATION_TYPES } from '../../../constants/organizationTypes';

class ClaimActionsContainer extends Component {
  async componentDidMount() {
    await claimStore.getClaim(this.props.match.params.number);
  }

  render() {
    if (!claimStore.currentClaim || !claimStore.currentClaim.actions) {
      return <CircularProgress />;
    }
    const {
      vehicle: { make, model, year },
      actions
    } = claimStore.currentClaim;

    const tableRows = actions.map(row => {
      const organizationName = get(row, 'organisation.name', null);
      const organizationType = get(row, 'organisation.type', null);

      return (
        <TableRow key={row._id}>
          <TableCell>{row.name}</TableCell>
          <TableCell>{organizationName}</TableCell>
          <TableCell>
            {organizationType && ORGANIZATION_TYPES[organizationType]}
          </TableCell>
          <TableCell>{get(row, 'issuer.name', null)}</TableCell>
          <TableCell>{get(row, 'issuer._id', null)}</TableCell>
          <TableCell>
            <Moment format='DD/MM/YY HH:mm:ss' withTitle>
              {row.createdAt}
            </Moment>
          </TableCell>
          <TableCell padding='checkbox' />
        </TableRow>
      );
    });

    return (
      <Fragment>
        <Grid container>
          <Grid item sm={10}>
            <Paper style={{ padding: 20, margin: 10 }}>
              <h1>Marketplace Explorer</h1>
              <h2>
                {make} {model} ({year})
              </h2>
            </Paper>
            <Paper style={{ padding: 20, margin: 10 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Action</TableCell>
                    <TableCell>Organization Name</TableCell>
                    <TableCell>Organization Type</TableCell>
                    <TableCell>Originator Name</TableCell>
                    <TableCell>Originator Id</TableCell>
                    <TableCell>Time</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{tableRows}</TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

ClaimActionsContainer = observer(ClaimActionsContainer);
export default ClaimActionsContainer;
