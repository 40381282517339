import React from 'react';
import { Grid } from '@material-ui/core';
import Button from 'components/theme/CustomButtons/Button.jsx';
import { Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import CardAvatar from '../theme/Card/CardAvatar';
import CardBody from '../theme/Card/CardBody';
import userStore from '../../stores/userStore';

const styles = {
  cardAvatar: {
    maxWidth: '90px',
    maxHeight: '90px',
    marginTop: '-45px'
  },
  margin: {
    marginTop: '50px'
  },
  mainButton: {
    padding: '1rem 3rem',
    fontWeight: 'bold',
    fontSize: '100%',
    borderRadius: '30px'
  },
};

function RepairerHomePage(props) {
  const { classes } = props;
  const { currentUser } = userStore;
  return (
    <Grid
      container
      direction='column'
      alignItems='center'
      className={classes.margin}
    >
      <Grid container direction='column' alignItems='center'>
        <CardAvatar profile className={classes.cardAvatar}>
          <img src={currentUser.avatar} alt='...' />
        </CardAvatar>
        <CardBody profile>
          <h4 className={classes.cardTitle}>
            Welcome {userStore.currentUser.name}
          </h4>
        </CardBody>
        <CardBody>
          <Button
            to='/marketplace/in-marketplace'
            component={Link}
            color='primary'
            round
            className={classes.mainButton}
          >
            Repairs marketplace
          </Button>
          <Button
            to='/marketplace/claims'
            component={Link}
            color='primary'
            round
            className={classes.mainButton}
          >
            Your Repairs
          </Button>
        </CardBody>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(RepairerHomePage);
