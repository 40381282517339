import { observable, makeObservable, action } from 'mobx';
import axios from 'axios';

export class QuoteStore {
  loading = false;

  constructor() {
    makeObservable(this, {
      loading: observable,
      getClientQuote: action,
      getRepairerQuote: action,
      getQuoteStatus: action,
      acceptQuote: action
    });
  }

  async getClientQuote(id) {
    const response = await axios.get(`/api/quotes/${id}`);
    return response.data;
  }

  async getRepairerQuote(id) {
    const response = await axios.get(`/api/quotes/${id}/repairer`);
    return response.data;
  }

  async getQuoteStatus(id) {
    const response = await axios.get(`/api/external/quotes/status/${id}`);
    return response.data;
  }

  async updateQuote(id, cost, costRepairer) {
    return await axios.put(`/api/quotes/${id}`, {
      cost,
      costRepairer
    });
  }

  async getTopProducts() {
    const response = await axios.get("/api/quotes/topProducts/14");
    return response.data;
  }

  async acceptQuote(quoteId) {
    this.loading = true;
    try {
      const result = await axios.post("/api/quotes/quote-accept/" + quoteId);
      return result.data;
    } catch (e) {
      this.loading = false;
      throw (e);
    } finally {
      this.loading = false;
    }
  }

}

export default new QuoteStore();
