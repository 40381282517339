import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import claimStore from '../../stores/claimStore';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = {
  container: {
    width: '100%',
    position: 'relative'
  },
  small: {
    height: '80px',
    boxSizing: 'border-box',
    '& img': {
      height: '100%',
      width: 'auto'
    },
    padding: '8px',
    cursor: 'pointer'
  }
};

class ClaimFiles extends Component {
  fetchFile = async fileId => {
    try {
      const data = await claimStore.getFilesData([fileId]);

      if (data.length) {
        this.downloadFile(data[0].data);
      }
    } catch (e) {
      console.log('error');
    }
  };

  downloadFile = source => {
    let data = atob(source.split(',')[1]);
    let len = data.length;
    let buffer = new ArrayBuffer(len);
    let view = new Uint8Array(buffer);
    for (let i = 0; i < len; i++) {
      view[i] = data.charCodeAt(i);
    }
    let blob = new Blob([view], { type: this.props.filetype });
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = url;
    a.download = source.name || 'download.' + this.props.extension;
    a.click();
  };

  render() {
    const { classes, data } = this.props;

    if (data.length === 0) {
      return null;
    }

    return (
      <Grid container direction='column' className={classes.container}>
        <Grid item>
          <Grid container wrap='wrap'>
            {data.slice(0, 8).map(fileId => {
              return (
                <Grid item key={fileId}>
                  <div
                    className={classes.small}
                    onClick={() => this.fetchFile(fileId)}
                  >
                    <img src={this.props.image} alt={fileId} title={fileId} />
                  </div>
                  <div />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

ClaimFiles.propTypes = {
  data: PropTypes.arrayOf(PropTypes.string),
  filetype: PropTypes.string,
  extension: PropTypes.string,
  image: PropTypes.string,
};

ClaimFiles.defaultProps = {
  data: [],
  filetype: 'application/pdf',
  extension: 'pdf',
};

export default withStyles(styles)(ClaimFiles);
