import React, { useCallback } from "react";
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import organizationsStore from '../../stores/organizationsStore';
import ErrorWrapper from '../common/ErrorWrapper';
import LoadingWrapper from '../common/LoadingWrapper';

import { ORGANIZATION_TYPES } from '../../constants/organizationTypes';
import { observer } from 'mobx-react';
import userStore from "../../stores/userStore";

const styles = {
  row: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)'
    }
  }
};

function Organizations(props) {
  const [loading, setLoading] = React.useState(true);
  const [isError, setIsError] = React.useState(false);

  const getOrganizations =  useCallback(async () => {
    try {
      await organizationsStore.getAllOrganizations();
      const { organizations } = organizationsStore;
      if (organizations.length === 1) {
        props.history.replace(`/organizations/${organizations[0]._id}`);
      }
    } catch (e) {
      setIsError(true);
    }
    setLoading(false);
  },[props.history]);

  React.useEffect( () => {
    getOrganizations().catch(console.error);
  }, [getOrganizations]);

  const refetch = async () => {
    setLoading(true);
    setIsError(false);
    await getOrganizations();
  };

  const hasManyOrganizations = userStore.hasManyOrganizations;

  return (
    <LoadingWrapper isLoading={loading}>
      <ErrorWrapper isError={isError} onRetry={refetch}>
        <Paper>
          <Table aria-label='organizations'>
            <TableHead>
              <TableRow>
                <TableCell>Organization Name</TableCell>
                <TableCell>Organization Type</TableCell>
                {hasManyOrganizations && (
                  <TableCell>Disabled</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {organizationsStore.organizations.map(organization => (
                <TableRow
                  key={organization._id}
                  className={props.classes.row}
                  onClick={() =>
                    props.history.push(
                      `/organizations/${organization._id}`
                    )
                  }
                >
                  <TableCell>{organization.name}</TableCell>
                  <TableCell>
                    {ORGANIZATION_TYPES[organization.type]}
                  </TableCell>
                  {hasManyOrganizations && (
                    <TableCell>{organization.disabled.toString()}</TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </ErrorWrapper>
    </LoadingWrapper>
  );
}

Organizations.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  })
};

export default withStyles(styles)(observer(Organizations));
