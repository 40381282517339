import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Typography
} from '@material-ui/core';
import { Send } from '@material-ui/icons';
import { Form } from 'react-final-form';
import Input from '../form/Input';
import Select from '../form/Select';
import CustomInput from 'components/theme/CustomInput/CustomInput.jsx';
import { isRequired, pipe, email } from '../../validation/validation';
import { ORGANIZATION_TYPES_LIST } from '../../constants/organizationTypes';
import { USER_ROLES } from '../../constants/userRoles';
import organizationsStore from '../../stores/organizationsStore';
import { observer } from 'mobx-react';
import userStore from '../../stores/userStore';
import get from 'lodash.get';

class NewOrganization extends Component {
  state = {
    error: null
  };

  handleSubmit = async values => {
    try {
      await organizationsStore.createOrganization(values);
      //fetch logged user data to refresh privileges
      await userStore.getUserData();
      this.props.history.push('/organizations');
    } catch (err) {
      const error = get(err, 'response.data.message', err.message);

      this.setState({ error });
    }
  };

  render() {
    const { error } = this.state;

    return (
      <Grid container justifyContent='center'>
        <Grid item md={12} lg={6} xl={4}>
          <Form
            onSubmit={this.handleSubmit}
            initialValues={{
              name: '',
              type: '',
              email: ''
            }}
            render={({ handleSubmit, submitting }) => (
              <form onSubmit={handleSubmit}>
                <Card>
                  <CardHeader title='Create Organization' />
                  <CardContent>
                    <Input
                      name='name'
                      label='Name'
                      required
                      validate={isRequired}
                    />
                    <Select
                      name='type'
                      label='Organization type'
                      options={ORGANIZATION_TYPES_LIST}
                      required
                      validate={isRequired}
                      data-cy='organisation-type'
                    />
                    <Input
                      name='email'
                      label='Invite user (email)'
                      required
                      validate={pipe(
                        isRequired,
                        email
                      )}
                    />
                    <CustomInput
                      labelText={'User Type'}
                      formControlProps={{
                        fullWidth: true
                      }}
                      disabled
                      inputProps={{ value: USER_ROLES.ADMIN, disabled: true }}
                    />
                  </CardContent>
                  <CardActions>
                    <Grid container justifyContent='flex-end'>
                      <Grid item>
                        <Button
                          color='secondary'
                          type='submit'
                          disabled={submitting}
                        >
                          <Send />
                          Create & Invite
                        </Button>
                      </Grid>
                    </Grid>
                  </CardActions>
                </Card>
                {error && (
                  <Typography color='error' align='center'>
                    {error}
                  </Typography>
                )}
              </form>
            )}
          />
        </Grid>
      </Grid>
    );
  }
}

NewOrganization.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  })
};

export default observer(NewOrganization);
