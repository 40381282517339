import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Badge,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@material-ui/core";
import {
  Apps,
  Build,
  Dashboard,
  DriveEta,
  GpsFixed,
  AddCircleOutline,
  PersonAdd,
  Label,
  Map,
  Garage as GarageIcon,
  List as ListIcon
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import claimStore from "../../stores/claimStore";
import Restricted from "../restricted/Restricted";
import { PRIVILEGES } from "../../constants/privileges";
import { CLAIM_STATUS_KEYS } from "../../constants/claimStatus";

const styles = theme => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  }
});

function LeftMenuList(props) {

  const [inMarketplaceCount, setInMarketplaceCount] = React.useState(0);
  const { classes } = props;

  React.useEffect(() => {
    async function getInMarketplaceCount() {
      const inMarketplaceCount = await claimStore.getClaimCount(CLAIM_STATUS_KEYS.InMarketplace);
      setInMarketplaceCount(inMarketplaceCount);
    }

    getInMarketplaceCount().catch(console.error);
  }, []);


  return (
    <div className={classes.root}>
      <List component="nav">
        <Restricted privileges={[PRIVILEGES.ReadRepairClaims]}>
          <ListItem button component={Link} to="/repairerHome">
            <ListItemIcon>
              <Badge
                color="secondary"
                badgeContent={inMarketplaceCount}
                style={{ padding: "0px", marginTop: "5px" }}
              >
                <DriveEta />
              </Badge>
            </ListItemIcon>
            <ListItemText primary="Repair home" />
          </ListItem>
        </Restricted>

        <Restricted privileges={[PRIVILEGES.ReadBodyshopClaims]}>
          <ListItem button component={Link} to="/bodyshopHome">
            <ListItemIcon>
                <GarageIcon />
            </ListItemIcon>
            <ListItemText primary="Bodyshop home" />
          </ListItem>
        </Restricted>

        <Restricted privileges={[PRIVILEGES.ReadClaims]}>
          <ListItem button component={Link} to="/home">
            <ListItemIcon>
              <Apps />
            </ListItemIcon>
            <ListItemText primary="Claims" />
          </ListItem>
          <ListItem button component={Link} to="/dashboard">
            <ListItemIcon>
              <Dashboard />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
          <ListItem button component={Link} to="/explorer">
            <ListItemIcon>
              <GpsFixed />
            </ListItemIcon>
            <ListItemText primary="Explorer" />
          </ListItem>
        </Restricted>

        <Restricted privileges={[PRIVILEGES.ReadGarages]}>
          <ListItem button component={Link} to="/garages">
            <ListItemIcon>
              <Build />
            </ListItemIcon>
            <ListItemText primary="Garages" />
          </ListItem>
        </Restricted>

        <Restricted privileges={[PRIVILEGES.ReadGarages]}>
          <ListItem button component={Link} to="/repairMap">
            <ListItemIcon>
              <Map />
            </ListItemIcon>
            <ListItemText primary="Map" />
          </ListItem>
        </Restricted>

        <Restricted
          privileges={[
            PRIVILEGES.ExecuteUserInvite,
            PRIVILEGES.ReadOrganisations
          ]}
        >
          <ListItem button component={Link} to="/organizations">
            <ListItemIcon>
              <ListIcon />
            </ListItemIcon>
            <ListItemText primary="Organization" />
          </ListItem>
        </Restricted>
        <Restricted privileges={[PRIVILEGES.CreateOrganisation]}>
          <ListItem button component={Link} to="/newOrganization">
            <ListItemIcon>
              <AddCircleOutline />
            </ListItemIcon>
            <ListItemText primary="Create Organization" />
          </ListItem>
        </Restricted>
        <Restricted privileges={[PRIVILEGES.ExecuteSuperAdminInvite]}>
          <ListItem button component={Link} to="/inviteAdmin">
            <ListItemIcon>
              <PersonAdd />
            </ListItemIcon>
            <ListItemText primary="Invite Admin" />
          </ListItem>
        </Restricted>
        <Restricted privileges={[PRIVILEGES.SuperAdminLabels]}>
          <ListItem button component={Link} to="/labels">
            <ListItemIcon>
              <Label />
            </ListItemIcon>
            <ListItemText primary="Labels" />
          </ListItem>
        </Restricted>
      </List>
    </div>
  );
}

export default withStyles(styles)(observer(LeftMenuList));
