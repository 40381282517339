import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import labelsStore from "../../stores/labelsStore";
import ErrorWrapper from "../common/ErrorWrapper";
import LoadingWrapper from "../common/LoadingWrapper";

import { observer } from "mobx-react";
import GridContainer from "../theme/Grid/GridContainer";
import GridItem from "../theme/Grid/GridItem";
import { Typography } from "@material-ui/core";
import Restricted from "../restricted/Restricted";
import { PRIVILEGES } from "../../constants/privileges";
import Button from "@material-ui/core/Button";

const styles = {
  header: {
    marginBottom: "8px",
    padding: "16px"
  },
  row: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.1)"
    }
  }
};

function Labels(props) {
  const [loading, setLoading] = React.useState(true);
  const [isError, setIsError] = React.useState(false);
  const { classes } = props;
  React.useEffect(() => {
    getLabels().catch(console.error);
  }, []);

  const getLabels = async () => {
    try {
      await labelsStore.getAllLabels();
    } catch (e) {
      setIsError(true);
    }
    setLoading(false);
  };

  const refetch = async () => {
    setLoading(true);
    setIsError(false);
    await getLabels();
  };

  const createLabel = () => {
    props.history.push("/createLabel");
  };

  return (
    <LoadingWrapper isLoading={loading}>
      <ErrorWrapper isError={isError} onRetry={refetch}>
        <GridContainer>
          <GridItem xs={12}>
            <Paper className={classes.header}>
              <GridContainer>
                <GridItem xs={8} sm={10}>
                  <Typography component="h1" variant="h5">
                    Labels
                  </Typography>
                </GridItem>
                <Restricted privileges={[PRIVILEGES.SuperAdminLabels]}>
                  <GridItem xs={4} sm={2}>
                    <Button onClick={createLabel} color="secondary">
                      Add Label
                    </Button>
                  </GridItem>
                </Restricted>
              </GridContainer>
            </Paper>
          </GridItem>
          <GridItem xs={12}>
            <Paper>
              <Table aria-label="labels">
                <TableHead>
                  <TableRow>
                    <TableCell>Label Name</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Automated</TableCell>
                    <TableCell>Deletable</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {labelsStore.labels.map(label => (
                    <TableRow
                      key={label._id}
                      className={props.classes.row}
                      onClick={() =>
                        props.history.push(
                          `/updateLabel/${label._id}`
                        )
                      }
                    >
                      <TableCell>{label.name}</TableCell>
                      <TableCell>
                        {label.description}
                      </TableCell>
                      <TableCell>
                        {label?.metadata?.automated}
                      </TableCell>
                      <TableCell>
                        {label?.metadata?.deleteAllowed}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </GridItem>
        </GridContainer>
      </ErrorWrapper>
    </LoadingWrapper>
  );
}

Labels.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  })
};

export default withStyles(styles)(observer(Labels));
