import React from 'react';
import { observer } from 'mobx-react';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Document, Page } from 'react-pdf';
import claimStore from '../../stores/claimStore';
import quoteStore from '../../stores/quoteStore';
import SubmitButton from '../stripe/SubmitButton';
import SuccessStatement from '../statements/successStatement';
import Spinner from '../common/Spinner';
import Logo from '../logo/logo';

const styles = theme => ({
  paymentForm: {
    marginBottom: '5em'
  },
  logo: {
    display: 'flex',
    margin: '10px',
    '& img': {
      width: '100px'
    }
  },
  pdfViewer: {
    display: 'flex',
    justifyContent: 'center',
    '& canvas': {
      maxWidth: '100%',
      height: 'auto !important'
    }
  },
  submitButton: {
    position: 'fixed',
    left: '50%',
    bottom: '50px',
    transform: 'translate(-51%, -50%)'
  }
});

function QuotePage(props) {
  const [expired, setExpired] = React.useState(false);
  const [accepted, setAccepted] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    async function getQuoteStatus() {
      const { status, expired } = await quoteStore.getQuoteStatus(props.match.params.quoteId);
      if (status === 'accepted') {
        setAccepted({ status: 'accepted' });
        setOpen(false);
      } else if (expired) {
        setExpired(true);
        setOpen(false);
      }
    }

    getQuoteStatus();
  }, [props.match.params.quoteId]);

  async function acceptQuote(quoteId) {
    try {
      const accepted = await quoteStore.acceptQuote(quoteId);
      setAccepted(accepted);
      setOpen(false);
    } catch (e) {
      console.log(JSON.stringify(e))
      setError("There was an issue processing the quote. please contact estimates@axioma.co.uk")
    }
  }

  const { classes } = props;
  return (
    <Grid container direction="column">
      <Grid container justifyContent="center">
        <Logo />
      </Grid>
      {error && (<Grid item xs={12}>
        <Card raised className={classes.root}>
          <CardContent>
            <Typography paragraph align="center" variant="subtitle1">There was an issue processing the quote. please
              contact estimates@axioma.co.uk</Typography>
          </CardContent>
        </Card>
      </Grid>)}
      {!error && !accepted?.status && !expired &&
        (
          <>
            <Grid item xs={12} className={classes.pdfViewer}>
              <Document
                file={process.env.REACT_APP_FETCH_QUOTE + props.match.params.quoteId}
                loading={<Spinner message="Loading pdf..." />}
              >
                <Page pageNumber={1} renderTextLayer={false} />
              </Document>
            </Grid>
            <Grid item xs={12} className={classes.paymentForm}>
              <SubmitButton
                processing={claimStore.loading}
                className={classes.submitButton}
                onClick={() => acceptQuote(props.match.params.quoteId)}
                handleClose={() => setAccepted(false)}
                handleOpen={() => setOpen(true)}
                disabled={expired}
                open={open}
              >
                Accept this Quote
              </SubmitButton>
            </Grid>
          </>)}
      {accepted?.status &&
        (
          <Grid item xs={12}>
            <SuccessStatement
              render={
                <>
                  <Typography paragraph align="center" variant="h5">Thank You For Accepting Our
                    Quote!</Typography>
                  <Typography paragraph align="center" variant="subtitle1">What happens next...</Typography>
                  <Typography align="center" paragraph variant="body1">
                    We are now working to source the best repairer for your job.
                    You will receive a confirmation email once a repairer has been allocated to your job.
                  </Typography>
                  <Typography align="center" paragraph variant="body1">
                    Soon after, you will receive a booking request from the Axioma platform with the
                    repairer's availability (this usually happens within a couple of hours after we've
                    allocated the repairer).
                  </Typography>
                  <Typography align="center" paragraph variant="body1">
                    Please keep in mind our repairers are very busy so sometimes booking slots are limited
                    to only one or two days.
                    If the proposed booking dates do not work for you, please notify the repairer by
                    clicking on the "None of the dates are suitable" button and a repairer will be in
                    touch via phone to arrange.
                  </Typography>
                </>
              }
            />
          </Grid>)
      }
      {expired && (<Grid item xs={12}>
        <Card raised className={classes.root}>
          <CardContent>
            <Typography paragraph align="center" variant="subtitle1">This quote has expired. Please request
              a new quote.</Typography>
          </CardContent>
        </Card>
      </Grid>)}
    </Grid>
  );
}

export default observer(withStyles(styles)(QuotePage));