import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-final-form";
import GridContainer from "components/theme/Grid/GridContainer.jsx";
import GridItem from "components/theme/Grid/GridItem.jsx";
import Button from "components/theme/CustomButtons/Button.jsx";
import { Card, CardContent, Typography, Divider, withStyles, Grid, TextField } from "@material-ui/core";
import { observer } from "mobx-react";
import claimStore from "../../stores/claimStore";
import CardFooter from "components/theme/Card/CardFooter.jsx";
import Select from "../form/Select";
import Checkbox from "../form/Checkbox";
import PaymentForm from "../stripe/PaymentForm";
import Logo from "../logo/logo";
import axios from "axios";
import { Field } from "react-final-form";
import FormHelperText from "@material-ui/core/FormHelperText";
import { dangerColor } from "../../assets/jss/material-dashboard-pro-react";
import Axioma2024Logo from "../../assets/img/logo/axioma-logo.png";

const styles = theme => ({
  root: {
    position: "absolute",
    left: 0,
    top: 0,
    color: "white",
    "& img": {
      width: "200px"
    }
  },
  gridItem: {
    margin: "15px"
  },
  divider: {
    margin: "10px"
  },
  labelRootError: {
    color: dangerColor + " !important"
  },
  logoText: {
    fontFamily: "Poppins, sans-serif",
    fontWeight: "700"
  },
  logo: {
    width: "250px"
  },
  button: {
    backgroundColor: "#1B55F5",
    borderRadius: "30px",
    fontWeight: "700",
    fontFamily: "Poppins, sans-serif"
  }
});

const postcodeRegex = /^[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}$/;
const phoneNumberRegex = /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?#(\d{4}|\d{3}))?$/;
const API_KEY = "vitYf1i7pECRyAutgvQ1KA33667";
const isPhoneNumber = value => {
  if (!value || !value.toString().match(phoneNumberRegex)) {
    return "Valid phone number is required";
  }
};

const isValidPostcode = value => {
  if (!value || !value.toUpperCase().match(postcodeRegex)) {
    return "Valid postcode is required";
  }
};


class PickUpForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      location: null,
      addresses: [],
      contactRequired: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    await this.onPostcodeChange(this.props.location);
  }

  onPostcodeChangeEvent = async (event) => {
    return this.onPostcodeChange(event.target.value);
  };

  onPostcodeChange = async (postcode) => {
    if (postcode && postcode.length > 4 && postcode.toUpperCase().match(postcodeRegex)) {
      const result = await axios.get(`https://api.getAddress.io/find/${postcode}`, {
        timeout: 20000,
        params: {
          "api-key": API_KEY,
          expand: true
        }
      });
      const addr = result.data.addresses.map((addr) => ({
        label: addr.formatted_address.join(" "),
        value: addr.formatted_address.join(" ")
      }));
      this.setState({ location: postcode, addresses: addr });
    }
  };

  async handleSubmit(values) {
    this.setState({ processing: true });
    await this.props.onSubmit(values);
    this.setState({ processing: false });
  }

  render() {
    if (!this.props.dates) return "No dates are available - please contact estimates@axioma.co.uk";

    const { classes, location, phoneNumber } = this.props;

    const isRetail = claimStore.currentClaim.retail && this.props.chargeRequired;
    const buttonText = (values) => {
      if (this.state.processing) {
        return "Processing";
      }
      if (values.contactRequired) {
        return "Request contact";
      }
      return "Submit";
    };

    return (
      <GridContainer justify="center" direction="column" alignItems="center">
        <Grid container justifyContent="center">
          <img src={Axioma2024Logo} className={classes.logo} alt="axioma" />
        </Grid>

        <Form
          onSubmit={this.handleSubmit}
          validate={
            values => {
              const errors = {};
              if (!values.contactRequired) {
                if (!values.date) {
                  errors.date = "Date is required";
                }
                errors.phoneNumber = isPhoneNumber(values.phoneNumber);
                if (!values.manualAddress) {
                  errors.location = isValidPostcode(values.location);
                }
                if (!values.address) {
                  errors.address = "Address is required";
                }
              }
              return errors;
            }
          }
          render={({ values, handleSubmit, valid }) => (
            <><GridItem xs={12} sm={6} md={6} lg={4}>
              <Card>
                <form onSubmit={handleSubmit}>

                  <CardContent>
                    <GridContainer justifyContent="center">
                      <Typography variant="h5" className={classes.logoText}>Arrange Your Repair</Typography>
                    </GridContainer>
                    {!values.contactRequired &&
                      <>
                        <Select
                          name="date"
                          label="Click here to select a repair date"
                          options={this.props.dates}
                        />
                        <Field name="phoneNumber" initialValue={phoneNumber}>
                          {({ input, meta }) => {
                            const validationError = meta.error;
                            const submitError = !meta.dirtySinceLastSubmit && meta.submitError;
                            const errorMessage = validationError || submitError;
                            return (<>
                                <TextField
                                  label="Phone Number"
                                  name="phonenumber"
                                  required
                                  fullWidth
                                  value={input.value}
                                  onChange={async (e) => {
                                    input.onChange(e);
                                  }}
                                />
                                {errorMessage !== undefined ? (
                                  <FormHelperText className={classes.labelRootError}>
                                    {errorMessage}
                                  </FormHelperText>
                                ) : null}
                              </>
                            );
                          }}
                        </Field>
                        <Checkbox
                          label="Enter address manually"
                          name="manualAddress"
                          onClick={() => {
                          }}
                          optional
                        />
                        {values.manualAddress ? (
                          <> <Field name="location" initialValue={location}>
                            {({ input, meta }) => {
                              const validationError = meta.error;
                              const submitError = !meta.dirtySinceLastSubmit && meta.submitError;
                              const errorMessage = validationError || submitError;
                              return (<>
                                  <TextField
                                    label="Postcode"
                                    name="postcode"
                                    required
                                    fullWidth
                                    value={input.value}
                                    onChange={async (e) => {
                                      input.onChange(e);
                                    }}
                                  />
                                  {errorMessage !== undefined ? (
                                    <FormHelperText className={classes.labelRootError}>
                                      {errorMessage}
                                    </FormHelperText>
                                  ) : null}
                                </>
                              );
                            }}
                          </Field>
                            <Field name="address">
                              {({ input, meta }) => {
                                const validationError = meta.error;
                                const submitError = !meta.dirtySinceLastSubmit && meta.submitError;
                                const errorMessage = validationError || submitError;
                                return (<>
                                    <TextField
                                      label="Address"
                                      name="address"
                                      required
                                      fullWidth
                                      value={input.value}
                                      onChange={async (e) => {
                                        input.onChange(e);
                                      }}
                                    />
                                    {errorMessage !== undefined ? (
                                      <FormHelperText className={classes.labelRootError}>
                                        {errorMessage}
                                      </FormHelperText>
                                    ) : null}
                                  </>
                                );
                              }}
                            </Field> </>) : (
                          <>
                            <Field name="location" initialValue={location}>
                              {({ input, meta }) => {
                                const validationError = meta.error;
                                const submitError = !meta.dirtySinceLastSubmit && meta.submitError;
                                const errorMessage = validationError || submitError;
                                return (<>
                                    <TextField
                                      label="Postcode"
                                      name="postcode"
                                      required
                                      fullWidth
                                      value={input.value}
                                      onChange={async (e) => {
                                        input.onChange(e);
                                        await this.onPostcodeChangeEvent(e);
                                      }}
                                    />
                                    {errorMessage !== undefined ? (
                                      <FormHelperText className={classes.labelRootError}>
                                        {errorMessage}
                                      </FormHelperText>
                                    ) : null}
                                  </>
                                );
                              }}
                            </Field>
                            <Select
                              name="address"
                              label="Select the full address"
                              options={this.state.addresses}
                              required
                            />  </>)
                        }
                      </>
                    }
                    <Checkbox
                      label="None of the dates are suitable, request contact to arrange repair date."
                      name="contactRequired"
                      onClick={() => this.setState({ contactRequired: !values.contactRequired })}
                      optional
                    />
                    {values.contactRequired &&

                      <Field name="contactComment">
                        {({ input, meta }) => {
                          const validationError = meta.error;
                          const submitError = !meta.dirtySinceLastSubmit && meta.submitError;
                          const errorMessage = validationError || submitError;
                          return (<>
                              <TextField
                                label="Other dates? Please let us know."
                                name="contactComment"
                                fullWidth
                                required
                                value={input.value}
                                onChange={async (e) => {
                                  input.onChange(e);
                                }}
                              />
                              {errorMessage !== undefined ? (
                                <FormHelperText className={classes.labelRootError}>
                                  {errorMessage}
                                </FormHelperText>
                              ) : null}
                            </>
                          );
                        }}
                      </Field>
                    }
                    {this.props.error && (
                      <Typography
                        color="error"
                        align="center"
                        data-cy="login-error"
                      >
                        {this.props.error}<br />
                        Please contact <a href="mailto:estimates@axioma.co.uk">estimates@axioma.co.uk</a>
                      </Typography>
                    )}

                  </CardContent>
                  {valid && ((!this.props.chargeRequired && values.date) || values.contactRequired) &&
                    <CardFooter>
                      <Button
                        id={"submit-button"}
                        color="rose"
                        size="lg"
                        block
                        type="submit"
                        className={classes.button}
                      >
                        {buttonText(values)}
                      </Button>
                    </CardFooter>
                  }
                </form>
                {(valid && this.props.chargeRequired && values.date && !values.contactRequired) &&
                  <CardFooter>
                    <GridContainer justifyContent="center">
                      <GridItem>
                        <Logo logo="stripe" />
                        <PaymentForm
                          claimId={this.props.claimId}
                          repairData={values}
                          paymentIntentId={this.props.paymentIntentId}
                        />
                      </GridItem>
                    </GridContainer>
                  </CardFooter>}
              </Card>
            </GridItem>
              {(isRetail && valid && !values.contactRequired) &&
                <GridItem xs={12} sm={6} md={6} lg={4} className={classes.gridItem}>
                  <Divider className={classes.divider} />
                  <Typography variant="subtitle1" align="center" gutterBottom>
                    Why we ask for a booking fee:
                  </Typography>
                  <Typography variant="body2" align="justify" gutterBottom>
                    Your body repair specialist will have booked out time for you and your vehicle in their working day
                    and
                    they need to make a living.
                    If you decide to cancel your booking we will use this booking fee to reimburse the repairer for the
                    time
                    they lost making themselves available for you.
                    This £38 fee will be deducted from your final bill upon completion of the works.
                  </Typography>
                </GridItem>
              }
              {(isRetail && values.contactRequired) &&
                <GridItem xs={12} sm={6} md={6} lg={4} className={classes.gridItem}>
                  <Divider className={classes.divider} />
                  <Typography variant="body2" align="justify" gutterBottom>
                    Sorry to hear that none of the dates provided work for you!<br />
                    As you can imagine, quality repairers sourced at Axioma prices are in huge demand. This is why our
                    average lead times are 14 to 15 days.<br />
                    Please note that until you’re booked in, the dates offered are subject to change (and slots fill up
                    quickly) so please only request a call-back if you wish to change your slot to a date <b>further in
                    the
                    future than those offered.</b><br />
                    If you require a date before any of the dates offered, please get in touch with <a
                    href="mailto:estimates@axioma.co.uk">estimates@axioma.co.uk</a> and we can discuss our <b>express
                    service options.</b>
                  </Typography>
                </GridItem>
              }
            </>)} />
      </GridContainer>
    );
  }
  ;
}

PickUpForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  dates: PropTypes.array,
  location: PropTypes.string,
  phoneNumber: PropTypes.string,
  error: PropTypes.string,
  chargeRequired: PropTypes.bool,
  clientSecret: PropTypes.string,
  paymentIntentId: PropTypes.string,
  claimId: PropTypes.string
};

PickUpForm = withStyles(styles)(PickUpForm);

export default observer(PickUpForm);
