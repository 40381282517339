import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import {
  Grid,
  TextField,
  Button,
  Typography,
  Divider
} from '@material-ui/core';
import { Send } from '@material-ui/icons/index';
import claimStore from '../../stores/claimStore';
import Hidden from '@material-ui/core/Hidden';

class ClaimComments extends Component {
  state = {
    newComment: '',
    pending: false
  };

  onCommentChange = event => {
    this.setState({ newComment: event.target.value });
  };

  addComment = async event => {
    event.preventDefault();

    const { newComment } = this.state;

    if (newComment.length) {
      this.setState({ pending: true });

      try {
        await claimStore.addComment(this.props.claimId, newComment);
        this.setState({ newComment: '', pending: false });
      } catch (error) {
        console.log('ERROR', error);
        this.setState({ pending: false });
      }
    }
  };

  render() {
    const { comments } = this.props;
    const { newComment, pending } = this.state;

    return (
      <Grid container direction='column' spacing={10}>
        <Grid item>
          <Typography gutterBottom variant='h6'>
            Comments
          </Typography>
        </Grid>
        <Grid item container direction='column'>
          <form onSubmit={this.addComment}>
            <Grid item>
              <TextField
                fullWidth
                multiline
                minRows='1'
                maxRows='10'
                value={this.state.newComment}
                onChange={this.onCommentChange}
                inputProps={{ maxLength: 1000 }}
                placeholder='Add comment...'
              />
            </Grid>
            <Grid item container justifyContent='flex-end'>
              <Grid item>
                <Button
                  type='submit'
                  color='secondary'
                  disabled={!newComment.length || pending}
                >
                  <Send/>
                  Add Comment
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
        <Grid item container direction='column'>
          {[...comments].reverse().map((comment, index) => (
            <>
              {index !== 0 && <Divider/>}
              <Hidden smDown>
                <Grid
                  item
                  container
                  justifyContent='space-between'
                  key={comment._id}
                  wrap='nowrap'
                  spacing={16}
                  style={{ padding: '16px 8px' }}
                >
                  <Grid item>
                    <Typography style={{whiteSpace: "pre-wrap"}} variant='body1'>{comment.comment}</Typography>
                  </Grid>
                  <Grid item>
                    <Grid container direction='column' alignItems='flex-end'>
                      <Grid item>
                        <Typography variant='caption' align='right' noWrap>
                          {comment.date && (
                            <Moment fromNow>{comment.date}</Moment>
                          )}
                        </Typography>
                      </Grid>
                      {comment.user && (
                        <Grid item>
                          <Typography variant='body2' align='right' noWrap>
                            {comment.user.name}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Hidden>
              <Hidden mdUp>
                <Grid
                  item
                  container
                  justifyContent='space-between'
                  key={comment._id}
                  wrap='wrap'
                  spacing={16}
                  style={{ padding: '16px 8px' }}
                >
                  <Grid item>
                    <Typography variant='body1'>{comment.comment}</Typography>
                  </Grid>
                  <Grid item>
                    <Grid container direction='column' alignItems='flex-end'>
                      {comment.user && (
                        <Grid item>
                          <Typography variant='body2' align='right' noWrap>
                            {comment.user.name}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Hidden>
            </>
          ))}
        </Grid>
      </Grid>
    );
  }
}

ClaimComments.defaultProps = {
  comments: []
};

ClaimComments.propTypes = {
  comments: PropTypes.arrayOf(PropTypes.object),
  claimId: PropTypes.string
};

export default ClaimComments;
