import Button from "../theme/CustomButtons/Button";
import { Link } from "react-router-dom";
import { CLAIM_STATUS_KEYS } from "../../constants/claimStatus";
import Restricted from "../restricted/Restricted";
import { PRIVILEGES } from "../../constants/privileges";
import RequestAuthorisationRetail from "../requestAuthorisation/RequestAuthorisationRetail";
import SimpleQuote from "../requestAuthorisation/SimpleQuote";
import RequestAuthorisationB2B from "../requestAuthorisation/RequestAuthorisationB2B";
import ModifyQuote from "./modifyQuote/ModifyQuote";
import ChangeRepairer from "./changeRepairer/ChangeRepairer";
import ModifyDate from "./modifyDate/ModifyDate";
import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import AuthoriseBid from "../authorise/AuthoriseBid";
import AuthoriseQuote from "../authorise/AuthoriseQuote";
import ReturnToPremia from "./returnToPremia/ReturnToPremia";

function ClaimActions(props) {
  const { claim, quote } = props;
  const { status, pickup, metadata, retail } = claim;
  const initialStatuses = [CLAIM_STATUS_KEYS.Fnol, CLAIM_STATUS_KEYS.Registered];
  return (<div>
    <Button
      color="primary"
      to={`/modifyclaim/${claim._id}`}
      component={Link}
    >
      Modify Claim
    </Button>
    {initialStatuses.includes(status) && (
      <Restricted
        privileges={[PRIVILEGES.RequestQuoteAuthorisation]}
      >
        {claim.retail
          ? <div><RequestAuthorisationRetail successHandler={props.successHandler} /><SimpleQuote
            successHandler={props.successHandler} /></div>
          : <RequestAuthorisationB2B successHandler={props.successHandler} />
        }
      </Restricted>
    )}
    {!initialStatuses.includes(status) && quote && (
      <Restricted
        privileges={[PRIVILEGES.SuperAdminUpdateQuote]}
      >
        <ModifyQuote successHandler={props.successHandler} cost={quote.cost} />
      </Restricted>
    )}
    {!initialStatuses.includes(status) && quote && (
      <Restricted
        privileges={[PRIVILEGES.SuperAdminChangeRepairer]}
      >
        <ChangeRepairer successHandler={props.successHandler} cost={quote.cost} />
      </Restricted>)}
    {!initialStatuses.includes(status) && pickup && pickup.date && (
      <Restricted
        privileges={[PRIVILEGES.SuperAdminOverridePickupDate]}
      >
        <ModifyDate successHandler={props.successHandler} date={pickup.date} />
      </Restricted>)}
    {retail && metadata && !metadata.feedbackRequested && (status === CLAIM_STATUS_KEYS.Finished || status === CLAIM_STATUS_KEYS.Paid) && (
      <Restricted
        privileges={[PRIVILEGES.RequestFeedback]}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => props.requestFeedbackAction(claim._id)}
          disabled={props.pending}
        > Request Feedback
        </Button>
      </Restricted>)}
    {retail && metadata && !metadata.bodyshopApprovalRequested && initialStatuses.includes(status) && (
      <Restricted
        privileges={[PRIVILEGES.RequestFeedback, PRIVILEGES.ReadBodyshopClaims]}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => props.requestBodyshopApprovalAction(claim._id)}
          disabled={props.pending}
        > Bodyshop Approval Request
        </Button>
      </Restricted>)}
    {(status === CLAIM_STATUS_KEYS.Bidding || status === CLAIM_STATUS_KEYS.InRepair || status === CLAIM_STATUS_KEYS.AwaitingPickup) && (
      <Restricted
        privileges={[PRIVILEGES.RollbackClaim]}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => props.returnToMarketplaceAction(claim._id)}
          disabled={props.pending}
        > Return to Marketplace
        </Button>

      </Restricted>)}
    {status === CLAIM_STATUS_KEYS.AwaitingPickup && (
      <Restricted
        privileges={[PRIVILEGES.RollbackClaim]}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => props.rollbackToBiddingAction(claim._id)}
          disabled={props.pending}
        > Return to Bidding
        </Button>

      </Restricted>
    )}
    {status === CLAIM_STATUS_KEYS.AwaitingQuoteAuthorisation && (
      <Restricted
        privileges={[PRIVILEGES.RollbackClaim]}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => props.rollbackAwaitingQuoteToFnolAction(claim._id)}
          disabled={props.pending}
        > Return to Fnol
        </Button>

      </Restricted>
    )}
    {status === CLAIM_STATUS_KEYS.Fnol && retail && (
      <Restricted
        privileges={[PRIVILEGES.RejectClaim]}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => props.rejectClaimAction(claim._id)}
          disabled={props.pending}
        > Reject
        </Button>
      </Restricted>)}
    {status !== CLAIM_STATUS_KEYS.Archived && (
      <Restricted
        privileges={[PRIVILEGES.ArchiveAll]}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => props.archiveClaimAction(claim._id)}
          disabled={props.pending}
        > Archive
        </Button>
      </Restricted>)}
    {status === CLAIM_STATUS_KEYS.Archived && (
      <Restricted
        privileges={[PRIVILEGES.ArchiveAll]}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => props.unarchiveAction(claim._id)}
          disabled={props.pending}
        > Unarchive
        </Button>
      </Restricted>)}
    {claim.status === "awaiting-quote-authorisation" && (
      <AuthoriseQuote successHandler={props.successHandler} />)}
    {claim.status === "awaiting-bid-authorisation" && (
      <AuthoriseBid successHandler={props.successHandler} />)}
    {claim?.externalReferences?.premiaRepairGuid && (
      <Restricted
        privileges={[PRIVILEGES.SuperAdminExternalPremia]}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => window.open(`${process.env.REACT_APP_PREMIA_WEB_API_URL}?GUID=${claim.externalReferences.premiaRepairGuid}`)}
        > View in Premia
        </Button>
        <ReturnToPremia successHandler={props.successHandler}/>
      </Restricted>)}
  </div>);
}

ClaimActions.propTypes = {
  successHandler: PropTypes.func,
  unarchiveAction: PropTypes.func,
  archiveClaimAction: PropTypes.func,
  requestFeedbackAction: PropTypes.func,
  requestBodyshopApprovalAction: PropTypes.func,
  returnToMarketplaceAction: PropTypes.func,
  rollbackToBiddingAction: PropTypes.func,
  rollbackAwaitingQuoteToFnolAction: PropTypes.func,
  rejectClaimAction: PropTypes.func,
  claim: PropTypes.object,
  quote: PropTypes.object,
  pending: PropTypes.bool
};

export default observer(ClaimActions);