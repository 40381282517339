import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, Typography } from '@material-ui/core';
import Button from '../theme/CustomButtons/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import claimStore from '../../stores/claimStore';
import withStyles from '@material-ui/core/es/styles/withStyles';
import get from 'lodash.get';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
});


function AuthoriseQuote (props) {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const authorise = async () => {
    try {
      await claimStore.approveClaim(claimStore.currentClaim._id);
      handleClose()
      props.successHandler()
    } catch (err) {
      const error = get(err, 'response.data.error', 'Failed to authorise');
      setError(error);
    }
  };

  return (
    <div>
      <Button variant="contained" color="danger" onClick={handleClickOpen}>
        Approve Repair
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Authorisation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please confirm that you have reviewed and are comfortable with the estimate provided.
          </DialogContentText>
          {error && (
            <Typography
              color='error'
              align='left'
              data-cy='login-error'
            >
              {error}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={authorise} color="primary" name="authorise">
            Authorise
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

AuthoriseQuote.propTypes = {
  successHandler: PropTypes.func,
};

export default withStyles(styles)(AuthoriseQuote);
