import { observable, makeObservable, action, computed } from "mobx";
import { computedFn } from "mobx-utils";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { history } from "../history";
import {
  removeAuthToken,
  setAuthToken,
  addUnauthorizedHandler,
  removeUnauthorizedHandler
} from "../utils/http";

export class UserStore {
  currentUser = null;
  notifications = [];
  logoutMessage = "";

  constructor() {
    makeObservable(this, {
      currentUser: observable,
      notifications: observable,
      logoutMessage: observable,
      registerUser: action,
      loginUser: action,
      setCurrentUser: action,
      clearCurrentUser: action,
      logoutUser: action,
      getUserData: action,
      markAllAsRead: action,
      markAsRead: action,
      isAuthenticated: computed,
      hasManyOrganizations: computed
    });
  }

  onAuthorized(token) {
    setAuthToken(token);
    addUnauthorizedHandler(() =>
      this.logoutUser("You have been logged out due to inactivity")
    );
  }

  onUnauthorized() {
    removeAuthToken();
    removeUnauthorizedHandler();
  }

  async restoreSession() {
    const jwtToken = localStorage.getItem("jwtToken");

    if (jwtToken) {
      const decoded = jwt_decode(jwtToken);
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        this.logoutUser("Authorization expired, logged out");
      } else {
        this.onAuthorized(jwtToken);
        await this.getUserData();
      }
    }
  }

  async registerUser(userData, token, history) {
    try {
      await axios.post(`/api/users/register?token=${token}`, userData);
      history.push("/login");
    } catch (err) {
      throw err;
    }
  }

  async loginUser(userData) {
    try {
      const res = await axios.post("/api/users/login", userData);
      const { token, user } = res.data;
      this.onAuthorized(token);
      this.setCurrentUser(user);
      return res;
    } catch (err) {
      throw err;
    }
  }

  async getUserData() {
    const response = await axios.get("/api/users/current");
    this.setCurrentUser(response.data);
    return response.data;
  }

  async getUsersData() {
    return (await axios.get("/api/users")).data;
  }

  async markAsRead(id) {
    await axios.post(`/api/notifications/${id}/mark-as-read`);
  }

  async markAllAsRead() {
    await axios.post("/api/notifications/mark-all-as-read");
  }

  setCurrentUser(currentUser) {
    this.currentUser = {
      ...currentUser,
      organizationsIds: currentUser.permissions
        .filter(p => !!p.organisationId)
        .map(p => p.organisationId)
    };
  }

  clearCurrentUser() {
    this.currentUser = null;
    this.notifications = [];
  }

  updatePassword(data) {
    return axios.put("/api/users/password", data);
  }

  async logoutUser(message) {
    this.logoutMessage = message;
    this.onUnauthorized();
    try {
      await axios.post("/api/users/logout");
    } catch (err) {
      console.log(err);
    }
    this.clearCurrentUser();
    history.push("/login");
  }

  get isAuthenticated() {
    return this.currentUser !== null;
  }

  isAllowed = computedFn(function(allowedPrivileges = []) {
    if (!this.currentUser) {
      return false;
    }

    if (allowedPrivileges.length === 0) {
      return true;
    }

    return this.currentUser.permissions.some(permission =>
      permission.privileges.some(privilege =>
        allowedPrivileges.includes(privilege)
      )
    );
  });

  hasPrivilege = computedFn(function(privilege) {
    if (!this.currentUser) {
      return false;
    }

    return this.currentUser.permissions.some(permission =>
      permission.privileges.includes(privilege)
    );
  });

  get hasManyOrganizations() {
    return this.currentUser && this.currentUser.organizationsIds.length > 1;
  }
}


export default new UserStore();
