import { observable, makeObservable, action, runInAction } from "mobx";
import axios from "axios";

export class OrganizationsStore {
  organizations = [];
  currentOrganization = null;

  constructor() {
    makeObservable(this, {
      organizations: observable,
      currentOrganization: observable,
      getAllOrganizations: action,
      getOrganizationDetails: action,
      inviteUserToOrganization: action,
      updateFnolCode: action,
      createOrganization: action
    });
  }

  async getAllOrganizations() {
    try {
      const response = await axios.get("/api/organisations");
      runInAction(() => {
        this.organizations = response.data;
      });
    } catch (e) {
      throw e;
    }
  }

  async getOrganizationDetails(id) {
    try {
      const response = await axios.get(`/api/organisations/${id}`);
      runInAction(() => {
        this.currentOrganization = response.data;
      });
      return response.data
    } catch (e) {
      throw e;
    }
  }

  async inviteUserToOrganization(id, data) {
    await axios.post(`/api/organisations/${id}/invite-user`, data);
  }

  async updateFnolCode(id, data) {
    await axios.put(`/api/organisations/${id}/fnol`, data);
    if (this.currentOrganization) {
      runInAction(() => {
        this.currentOrganization.fnol = data.fnol;
      });
    }
  }

  async updatePayment(id, data) {
    await axios.put(`/api/organisations/${id}/direct-payment`, data);
    if (this.currentOrganization) {
      runInAction(() => {
        this.currentOrganization.payment = data;
      });
    }
  }

  async disableEnable(id, data) {
    await axios.put(`/api/organisations/${id}/disable`, data);
    if (this.currentOrganization) {
      runInAction(() => {
        this.currentOrganization.disabled = data.disabled;
      });
    }
  }

  async createOrganization(organization) {
    await axios.post("/api/organisations", organization);
  }
}

export default new OrganizationsStore();
