import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";
import { withStyles } from "@material-ui/core/styles";
import cx from "classnames";
import userStore from "stores/userStore";
import { PRIVILEGES } from "../../constants/privileges";
import Moment from "react-moment";
import {
  NOTIFICATION_MESSAGES,
  NOTIFICATION_TYPES_KEYS
} from "../../constants/notifications";
import { CLAIM_STATUS } from "../../constants/claimStatus";
import { Queue } from "@mui/icons-material";
import { enqueueSnackbar } from "notistack";
import { io } from "socket.io-client";
import { runInAction } from "mobx";

const style = {
  root: {
    marginRight: "8px"
  },
  badge: {
    marginTop: "4px"
  },
  link: {
    padding: "0 4px",
    textTransform: "none",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  menuHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 16px 8px 16px"
  },
  emptyContainer: {
    padding: "16px 32px"
  },
  menuItem: {
    width: "500px",
    maxWidth: "100%",
    boxSizing: "border-box",
    padding: "4px 16px",
    height: "auto",
    "&:not(:last-child)": {
      borderBottom: "1px solid rgba(224, 224, 224, 1)"
    },
    "&:hover $markReadLink": {
      visibility: "visible"
    }
  },
  notificationRoot: {
    width: "100%"
  },
  notificationRootRetail: {
    width: "100%",
    backgroundColor: "#d4dee5"
  },
  notificationHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  plateNumber: {
    fontWeight: 600
  },
  contentWrapper: {
    display: "flex",
    alignItems: "flex-end"
  },
  content: {
    flex: 1,
    whiteSpace: "normal"
  },
  markReadLink: {
    marginLeft: "12px",
    visibility: "hidden"
  }
};

const websocketAddress = process.env.REACT_APP_WEBSOCKET_ADDRESS;

class Notifications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorElement: null,
    };
  }

  componentDidMount() {
      console.log(`Setting up websocket on address ${websocketAddress}`)
      this.client = this.setupNotificationSocket();
  }

  setupNotificationSocket() {
    try {
      const socket = io.connect(websocketAddress, {
        transports: ["websocket"],
        query: { token: localStorage.getItem("jwtToken") }
      });
      socket.on("connect", () => {
        console.log("Websockets: Websocket connected");
      });
      socket.on("notifications:readyToRegister", () => {
        console.log("Websockets: Registering for notifications");
        socket.emit("notifications:register", {})
      });
      socket.on("disconnect", () => {
        console.log("Websockets: Websocket disconnected");
      });
      socket.on("notifications:initialState", notifications => {
        console.log("Websockets: received initial state");
        runInAction(() => {
          userStore.notifications = notifications;
        });
      });
      socket.on("notifications:newNotification", notification => {
        console.log("Websockets: received notification");
        runInAction(() => {
          userStore.notifications.unshift(notification);
          const action = () => {
            return (
              <Button
                color="primary"
                size="small"
                onClick={() => {
                  this.handleSnackbarClick(notification);
                }}
              >
                View
              </Button>
            );
          }
          enqueueSnackbar(`${notification.registration} - ${this.getNotificationMessage(notification)}`, {
            variant: "info",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right"
            },
            autoHideDuration: 5000,
            action
          });
        });
      });
      return socket;
    } catch (error) {
      throw error;
    }
  }

  componentWillUnmount() {
    if (this.client) {
      this.client.disconnect();
    }
  }

  openNotifications = ({ currentTarget }) => {
    this.setState({
      anchorElement: currentTarget
    });
  };

  closeNotifications = () => {
    this.setState({
      anchorElement: null
    });
  };

  handleNotificationClick = async notifications => {
    const { history } = this.props;

    if (userStore.hasPrivilege(PRIVILEGES.ReadClaims)) {
      history.push(`/dashboardClaim/${notifications[0].claimId}`);
    } else if (userStore.hasPrivilege(PRIVILEGES.ReadRepairClaims) || userStore.hasPrivilege(PRIVILEGES.ReadBodyshopClaims)) {
      history.push(`/marketplace/claims/${notifications[0].claimId}`);
    }

    this.closeNotifications();
    await notifications.forEach((notification) => {
      this.markAsRead(notification._id);
    });
  };

  handleSnackbarClick = async notification => {
    if (userStore.hasPrivilege(PRIVILEGES.ReadClaims)) {
      window.open(`/dashboardClaim/${notification.claimId}`, 'blank');
    } else if (userStore.hasPrivilege(PRIVILEGES.ReadRepairClaims) || userStore.hasPrivilege(PRIVILEGES.ReadBodyshopClaims)) {
      window.open(`/marketplace/claims/${notification.claimId}`, 'blank');
    }
    this.markAsRead(notification._id);
  };

  markAsRead = async id => {
    try {
      await userStore.markAsRead(id);
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  markAllAsRead = async () => {
    try {
      await userStore.markAllAsRead();
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  handleMarkAsRead = async (event, nots) => {
    event.stopPropagation();
    await nots.forEach((notification) => {
      this.markAsRead(notification._id);
    });
  };

  getNotificationMessage = (notification) => {
    const { type, payload } = notification;
    const message = NOTIFICATION_MESSAGES[type];
    if (
      type === NOTIFICATION_TYPES_KEYS.ClaimStatusUpdated &&
      payload &&
      payload.status
    ) {
      return `${message} to ${CLAIM_STATUS[payload.status]}`;
    } else if (type === NOTIFICATION_TYPES_KEYS.ClaimCommentAdded && payload?.user && payload?.comment) {
      const commentTrunc = payload.comment.length > 200 ? payload.comment.substring(0, 200) + "..." : payload.comment
      return `${payload.user}: ${commentTrunc}`;
    }

    return message;
  };

  render() {
    const { classes, children } = this.props;
    const { anchorElement } = this.state;
    const { notifications } = userStore;

    const isEmpty = notifications.length === 0;

    const grouped = notifications.reduce(function(acc, current) {
      const key = `${current.registration};${current.type}`;
      if (acc.has(key)) {
        return acc.set(key, [...acc.get(key), current]);
      } else {
        return acc.set(key, [current]);
      }
    }, new Map());
    return (
      <span className={classes.root}>
        <Badge
          color="secondary"
          badgeContent={grouped.size}
          classes={{ badge: classes.badge }}
        >
          {children({ openNotifications: this.openNotifications })}
        </Badge>
        <Popover
          open={!!anchorElement}
          anchorEl={anchorElement}
          onClose={this.closeNotifications}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
        >
          <MenuList>
            <div className={cx(classes.menuItem, classes.menuHeader)}>
              <Button
                className={classes.link}
                color="secondary"
                size="small"
                onClick={this.markAllAsRead}
              >
                Mark all as read
              </Button>
            </div>
            {isEmpty && (
              <div className={classes.emptyContainer}>
                <Typography align="center">
                  There are no new notifications
                </Typography>
              </div>
            )}
            {Array.from(grouped.entries(), (entry => (
              <MenuItem
                className={classes.menuItem}
                key={entry[1][0]._id}
                onClick={() => this.handleNotificationClick(entry[1])}
              >
                <div
                  className={(entry[1][0].payload && entry[1][0].payload.retail) ? classes.notificationRootRetail : classes.notificationRoot}>
                  <div className={classes.notificationHeader}>
                    <Typography className={classes.plateNumber}>
                      {entry[1][0].registration}
                    </Typography>
                    <Typography variant="caption">
                      {entry[1][0].date && <Moment fromNow>{entry[1][0].date}</Moment>}
                    </Typography>
                  </div>
                  <div className={classes.contentWrapper}>
                    <Typography component="span" className={classes.content}>
                      {entry[1].length > 1 ? <Queue /> : ""}
                      {this.getNotificationMessage(entry[1][0])}
                    </Typography>
                    <Button
                      className={cx(classes.link, classes.markReadLink)}
                      color="secondary"
                      size="small"
                      onClick={event => this.handleMarkAsRead(event, entry[1])}
                    >
                      Mark as read
                    </Button>
                  </div>
                </div>
              </MenuItem>
            )))}
          </MenuList>
        </Popover>
      </span>
    );
  }
}

Notifications.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
  children: PropTypes.func
};

export default withStyles(style)(withRouter(observer(Notifications)));
