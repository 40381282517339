import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Drawer, List, Divider, IconButton } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import LeftMenuList from './LeftMenuList';

class LeftDrawer extends Component {
  render() {
    return (
      <Drawer
        variant='permanent'
        classes={{
          paper: classNames(
            this.props.classes.drawerPaper,
            !this.props.open && this.props.classes.drawerPaperClose
          )
        }}
        open={this.props.open}
      >
        <div className={this.props.classes.toolbar}>
          <IconButton onClick={this.props.onClick}>
            {this.props.theme.direction === 'rtl' ? (
              <ChevronRight />
            ) : (
              <ChevronLeft />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          <LeftMenuList />
        </List>
      </Drawer>
    );
  }
}

LeftDrawer.propTypes = {
  classes: PropTypes.any,
  open: PropTypes.bool,
  onClick: PropTypes.func,
  theme: PropTypes.any
};

export default LeftDrawer;
