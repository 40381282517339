import React, { Component } from "react";
import PropTypes from "prop-types";
import { Dialog, Grid, Typography } from "@material-ui/core";
import Button from "../theme/CustomButtons/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import claimStore from "../../stores/claimStore";
import quoteStore from "../../stores/quoteStore";
import withStyles from "@material-ui/core/es/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import get from "lodash.get";
import Spinner from "../common/Spinner";

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  uploadButton: {
    marginBottom: "12px"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  },
  dialogActions: {
    justifyContent: "center"
  }
});

class RequestAuthorisationRetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      quote: {},
      fetchInterval: null,
      error: null,
      file: null,
      costRepairer: 0,
      takeRate: 0
    };
  }

  handleClickOpen = async () => {
    this.setState({ open: true, error: null });
    const { _id, payment: { clientStripeId }, quoteRef } = claimStore.currentClaim;
    let quote = {};
    if (typeof quoteRef !== "undefined") {
      quote = await quoteStore.getClientQuote(quoteRef);
    }
    if (!quote?.quoteStripeId) {
      try {
        const result = await claimStore.assignStripeIdToQuote(_id, clientStripeId);
        await claimStore.getClaim(_id);
        if (result.quoteStripeId) {
          window.open(process.env.REACT_APP_STRIPE_QUOTE_LINK + `${result.quoteStripeId}/edit`, "blank");
          this.setState({
            fetchInterval: setInterval(() => this.fetchQuote(result._id), 5000)
          });
        } else {
          this.setState({ error: result.error });
        }
      } catch (e) {
        this.setState({ error: e.response ? JSON.stringify(e.response.data) : JSON.stringify(e) });
      }
    } else {
      if (quote.status === "draft") {
        window.open(process.env.REACT_APP_STRIPE_QUOTE_LINK + `${quote.quoteStripeId}/edit`, "blank");
      }
      this.setState({
        fetchInterval: setInterval(() => this.fetchQuote(quoteRef), 5000)
      });
    }

  };

  fetchQuote = (quoteId) => {
    quoteStore.getClientQuote(quoteId).then(quote => {
      if (quote && quote.status === "finalized") {
        clearInterval(this.state.fetchInterval);
        this.setState({ quote });
        this.setState({ takeRate: this.getTakeRate(this.state.costRepairer, quote.cost) });
      }
    });
  };

  handleClose = () => {
    this.setState({ open: false });
    clearInterval(this.state.fetchInterval);
  };

  handleChangeQuoteRepairer = event => {
    this.setState({ costRepairer: event.target.value });
    this.setState({ takeRate: this.getTakeRate(event.target.value, this.state.quote.cost) });
  };

  addQuote = async () => {
    try {
      await claimStore.requestQuoteAuthorisationRetail(claimStore.currentClaim._id, this.state.costRepairer);
      await claimStore.getClaim(claimStore.currentClaim._id);
      this.handleClose();
      this.props.successHandler();
    } catch (err) {
      const error = get(err, "response.data.message", "Failed to request authorisation");
      this.setState({ error });
    }
  };

  getTakeRate(costRepairer, cost) {
    return (1 - (costRepairer / cost)) * 100;
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Button variant="contained" color="primary" onClick={this.handleClickOpen}>
          Add Quote
        </Button>
        <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Authorisation</DialogTitle>
          <DialogContent>
            {this.state.quote.status !== "finalized"
              ?
              <>
                {this.state.error ?
                  <Typography
                    color="error"
                    align="left"
                    data-cy="login-error"
                  >
                    {this.state.error}
                  </Typography>
                  : <Spinner message="Awaiting quote finalisation in Stripe" />
                }
              </>
              :
              <>
                <DialogContentText>
                  <Typography color="primary" gutterBottom>
                    Stripe quote attached
                  </Typography>
                  Please set the quote for the repairer
                </DialogContentText>
                <Grid container>
                  <Grid item>
                    <TextField
                      id="costDriver"
                      label="Client Quote"
                      type="text"
                      value={"£" + this.state.quote.cost}
                      className={classes.textField}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      id="costRepairer"
                      label="Repairer Quote (ex. VAT)"
                      type="number"
                      defaultValue={this.state.costRepairer}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true
                      }}
                      onChange={this.handleChangeQuoteRepairer}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      align="left"
                    >
                      Take Rate: {Math.round(this.state.takeRate)} %
                    </Typography>
                  </Grid>
                  {this.state.error && (
                    <Grid item>
                      <Typography
                        color="error"
                        align="left"
                        data-cy="login-error"
                      >
                        {this.state.error}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </>
            }
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            {this.state.quote.status === "finalized" &&
              <Button onClick={this.addQuote} color="primary" name="request-authorise">
                Add Quote
              </Button>
            }
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

RequestAuthorisationRetail.propTypes = {
  successHandler: PropTypes.func
};

export default withStyles(styles)(RequestAuthorisationRetail);
