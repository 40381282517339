import React from "react";

import GridContainer from "components/theme/Grid/GridContainer.jsx";
import Input from "../form/Input";
import { isRequired } from "../../validation/validation";
import { Grid } from "@material-ui/core";

function FNOLStep() {
  return (
    <div>
      <GridContainer justifyContent="center" spacing={16}>
        <Grid item xs={12} container>
          <Input name="secret" label="Code" required validate={isRequired} />
          <Input
            name="registration"
            label="Registration Plates"
            required
            validate={isRequired}
          />
          <Input
            name="phone"
            label="Phone Number"
            required
            validate={isRequired}
          />
          <Input
            name="location"
            label="Vehicle Location"
            required
            validate={isRequired}
          />
          <Input name="comment" label="Additional Information" />
        </Grid>
      </GridContainer>
    </div>
  );
}

export default FNOLStep;
