import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@material-ui/core';
import SupportForm from './SupportForm';

class Support extends Component {
  state = {
    isDialogOpen: false
  };

  toggleDialog = () =>
    this.setState(state => ({ isDialogOpen: !state.isDialogOpen }));

  render() {
    return (
      <>
        {this.props.children({ openSupport: this.toggleDialog })}
        <Dialog
          open={this.state.isDialogOpen}
          onClose={this.toggleDialog}
          maxWidth='md'
          fullWidth
        >
          <SupportForm closeDialog={this.toggleDialog} />
        </Dialog>
      </>
    );
  }
}

Support.propTypes = {
  children: PropTypes.func
};

export default Support;
