import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Input from '../form/Input';
import { isRequired } from '../../validation/validation';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { Form } from 'react-final-form';
import organizationsStore from '../../stores/organizationsStore';
import get from 'lodash.get';
import { Typography } from '@material-ui/core';

const OrganizationFnolCodeForm = ({ closeDialog, id }) => {
  const [error, setError] = useState(null);

  const updateFnol = async values => {
    try {
      await organizationsStore.updateFnolCode(id, values);

      closeDialog();
    } catch (err) {
      const error = get(err, 'response.data.message', err.message);
      setError(error);
    }
  };
  return (
    <Form
      onSubmit={updateFnol}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <DialogTitle>Update FNOL Code</DialogTitle>
          <DialogContent>
            <Input
              name='fnol'
              label='FNOL Code'
              type='input'
              required
              validate={isRequired}
            />

            {error && (
              <Typography color='error' align='center'>
                {error}
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog} color='primary'>
              Cancel
            </Button>
            <Button type='submit' color='secondary'>
              Send
            </Button>
          </DialogActions>
        </form>
      )}
    />
  );
};

OrganizationFnolCodeForm.propTypes = {
  id: PropTypes.string,
  closeDialog: PropTypes.func
};

export default OrganizationFnolCodeForm;
