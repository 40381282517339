import React, { Component } from 'react';
import { observer } from 'mobx-react';
import userStore from '../../stores/userStore';

class UserProfile extends Component {
  render() {
    return (
      <>
        <p>Id: {userStore.currentUser._id}</p>
        <p>Name: {userStore.currentUser.name}</p>
        <p>Email: {userStore.currentUser.email}</p>
        <p>Avatar: {userStore.currentUser.avatar}</p>
      </>
    );
  }
}

UserProfile = observer(UserProfile);
export default UserProfile;
