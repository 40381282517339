import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { Field } from 'react-final-form';
import { Button, Grid, Paper } from '@material-ui/core';
import Select from 'react-select';
import carimagefront from '../common/DamageExplain.png';
import parts from '../../data/parts.json';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeftOutlined';
import ArrowRightIcon from '@material-ui/icons/ArrowRightOutlined';
import Hidden from '@material-ui/core/Hidden';

const styles = theme => ({
  layout: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    [theme.breakpoints.up(450 + theme.spacing.unit * 3 * 2)]: {
      width: 1000,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit}px ${theme.spacing.unit}px ${theme
      .spacing.unit}px`
  },
  modal: {
    overflow: 'auto'
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing.unit * 3
  },

  selectList: {
    width: '100%'
  },
  partsListLeft: {
    width: '100%',
    height: '460px',
    overflow: 'auto',
    border: '1px solid #000',
    padding: theme.spacing.unit / 3,
    boxSizing: 'border-box'
  },
  partsList: {
    width: '100%',
    [theme.breakpoints.up("md")]: {
      height: '500px',
    },
    [theme.breakpoints.down("md")]: {
      height: '200px',
    },
    overflow: 'auto',
    border: '1px solid #000',
    padding: theme.spacing.unit / 3,
    boxSizing: 'border-box'
  },
  part: {
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    '&.selected': {
      color: '#FFF',
      background: theme.palette.secondary.main
    }
  },
  error: {
    color: 'red'
  }
});


class DamageList extends Component {

  state = {
    partToAdd: null,
    partToRemove: null,
    editedPartIndex: null
  };

  options = tDamage => parts.filter(
    part => !tDamage.some(damage => damage.part === part.name))
    .map(part => {
      return { label: part.name, value: part.value };
    });

  selectPartToAdd = partToAdd => {
    this.setState({ partToAdd });
  };

  handleSelectChange = (selectedOption, input) => {
    if (selectedOption) {
      const newPart = {
        index: selectedOption.value,
        part: selectedOption.label,
        size: 'coin',
        type: 'scratch',
        location: 'center',
        action: 'repair'
      };

      this.setState({
        editedPartIndex: input.value.length
      });
      input.onChange([...input.value, newPart])
    }
  };

  addToList = (input) => {
    const { partToAdd } = this.state;

    if (partToAdd) {
      const newPart = {
        index: partToAdd.value,
        part: partToAdd.name,
        size: 'coin',
        type: 'scratch',
        location: 'center',
        action: 'repair'
      };

      this.setState({
        editedPartIndex: input.value.length,
        partToAdd: null
      });
      input.onChange([...input.value, newPart])
    }
  };

  editDamage = (index, input) => {
    this.setState({
      editedPartIndex: index,
      partToRemove: input.value[index]
    });
  };

  removeFromList = (input) => {
    const { partToRemove } = this.state;

    if (partToRemove) {
      const updatedTotalDamage = input.value.filter(damage => {
        return damage.part !== partToRemove.part;
      });

      this.setState({partToRemove: null });
      input.onChange(updatedTotalDamage)
    }
  };

  render() {
    const {
      name,
      classes,
      ...others
    } = this.props;

    return (
      <Field name={name}
        {...others}
             render={({ input, meta }) => {
               return (
               <Grid container className={classes.demo} spacing={Number(24)}>
                 <Grid key={0} item xs={12} sm={12} md={12} lg={3}>
                   <Paper className={classes.paper}>
                     <Select className={classes.selectList}
                             onChange={(selected) => this.handleSelectChange(selected, input)}
                             options={this.options(input.value)}
                             placeholder='Type part name'
                     />
                     <Hidden mdDown>
                     <div className={classes.partsListLeft}>
                       {parts
                         .filter(
                           part =>
                             !input.value.some(damage => damage.part === part.name)
                         )
                         .sort(function(p1, p2) {
                           return p1.name.localeCompare(p2.name);
                         })
                         .map(part => {
                           return (
                             <div
                               onClick={() => this.selectPartToAdd(part)}
                               key={part.name}
                               className={`${classes.part} ${
                                 this.state.partToAdd && this.state.partToAdd.name === part.name
                                   ? 'selected'
                                   : ''
                               }`}
                             >
                               {part.name}
                             </div>
                           );
                         })}
                     </div>
                     </Hidden>
                   </Paper>
                 </Grid>
                 <Grid key={1} item xs={12} sm={12} md={12} lg={1}>
                   <Hidden mdDown>
                   <Grid key={0} item>
                     <Paper className={classes.paper}>
                       <Button onClick={() => this.addToList(input)}>
                         <ArrowRightIcon/>
                       </Button>
                     </Paper>
                   </Grid>
                   </Hidden>
                   <Grid key={1} item>
                     <Paper className={classes.paper}>
                       <Button onClick={() => this.removeFromList(input)}>
                         <ArrowLeftIcon/>
                       </Button>
                     </Paper>
                   </Grid>
                 </Grid>
                 <Grid key={2} item xs={12} sm={12} md={12} lg={3}>
                   <Paper className={classes.paper}>
                     <div className={classes.partsList}>
                       {input.value.map((part, index) => {
                         return (
                           <div
                             onClick={() => this.editDamage(index, input)}
                             key={part.part}
                             className={`${classes.part} ${
                               this.state.partToRemove && this.state.partToRemove.part === part.part
                                 ? 'selected'
                                 : ''
                             }`}
                           >
                             {part.part}
                           </div>
                         );
                       })}
                     </div>
                   </Paper>
                 </Grid>
                 <Grid key={3} item xs={12} sm={12} md={12} lg={5}>
                   <Grid key={0} item/>
                   <Grid key={1} item>
                     <Paper className={classes.paper}>
                       <img
                         src={carimagefront}
                         style={{ width: '100%' }}
                         alt='car'
                       />
                     </Paper>
                     <Paper>
                       L/F = Left / Front<br/>
                       R/F = Right / Front<br/>
                       L/R = Left / Rear<br/>
                       R/R = Right / Rear<br/>
                     </Paper>
                   </Grid>
                 </Grid>
               </Grid>)
             }}
      />
    );
  }
}

DamageList.propTypes = {
  name: PropTypes.string.isRequired
};

export default withStyles(styles)(DamageList);
