import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import axios from 'axios';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { Form } from 'react-final-form';
import Input from '../form/Input';
import { isRequired, email, pipe } from '../../validation/validation';
import { historyStack } from '../../history';
import platform from 'platform';

class SupportForm extends Component {
  handleSubmit = async values => {
    const data = {
      ...values,
      url: historyStack,
      browserData: `${platform.name} ${platform.version}`
    };

    try {
      await this.sendFeedback(data);
      this.props.closeDialog();
    } catch (error) {
      console.log('ERROR', error);
    }
  };

  sendFeedback = data => axios.post('/api/feedback', data);

  render() {
    const { closeDialog } = this.props;
    return (
      <Form
        onSubmit={this.handleSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle>Help & Feedback</DialogTitle>
            <DialogContent>
              <Input
                label='Email'
                name='email'
                required
                validate={pipe(
                  isRequired,
                  email
                )}
              />

              <Input
                label='Message'
                name='feedback'
                required
                multiline
                rows='5'
                validate={isRequired}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog} color='primary'>
                Cancel
              </Button>
              <Button type='submit' color='secondary'>
                Send
              </Button>
            </DialogActions>
          </form>
        )}
      />
    );
  }
}

SupportForm.propTypes = {
  closeDialog: PropTypes.func,
  history: PropTypes.object
};

export default withRouter(SupportForm);
