import React from 'react';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  footer: {
    backgroundColor: '#5E5F5F',
    padding: theme.spacing.unit * 2
  }
});

const Footer = props => {
  const { classes } = props;

  return (
    <footer className={classes.footer}>
      <Typography
        variant='subtitle1'
        align='center'
        gutterBottom
        color={'secondary'}
      >
        Copyright &copy; {new Date().getFullYear()} Axioma
      </Typography>
    </footer>
  );
};

export default withStyles(styles)(Footer);
