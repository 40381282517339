import React from "react";
import ClaimTabs from "./ClaimTabs";
import { observer } from "mobx-react";

function ClaimsContainer() {
  return (
    <ClaimTabs />
  );
}

export default observer(ClaimsContainer);
