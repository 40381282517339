import React from 'react';
import PropTypes from 'prop-types';
import { CardHeader } from '@material-ui/core';
import { withStyles } from '@material-ui/core';

const style = ({ palette }) => ({
  root: {
    backgroundColor: palette.primary.extraLight
  },
  title: {
    textAlign: 'center',
    color: 'white'
  }
});

const FormHeader = ({ classes, title }) => {
  return <CardHeader classes={classes} title={title} />;
};

FormHeader.propTypes = {
  title: PropTypes.string
};

export default withStyles(style)(FormHeader);
