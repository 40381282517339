import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import paymentStore from "../../stores/paymentStore";
import PropTypes from "prop-types";
import Button from "components/theme/CustomButtons/Button.jsx";
import { withStyles } from "@material-ui/core";

const styles = theme => ({
  button: {
    backgroundColor: "#1B55F5",
    borderRadius: "30px",
    fontWeight: "700",
    fontFamily: "Poppins, sans-serif",
  }
});

function PaymentForm(props) {
  const { classes } = props;
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    try {

      await paymentStore.updatePaymentIntentWithRepairData(props.paymentIntentId, {
        ...props.repairData,
        claimId: props.claimId
      });
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${process.env.REACT_APP_PUBLIC_URL}/repairDateSelected`
        }
      });

      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage("There was an error processing your transaction.");
      }

    } catch (error) {
      setMessage(error);
    }


    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs"
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <Button disabled={isLoading || !stripe || !elements} id="submit" color="rose"
              size="lg"
              block
              type="submit"
      className={classes.button}>
        {isLoading ? <div id="spinner"></div> : "Pay £38 Booking Fee"}
      </Button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}

PaymentForm.propTypes = {
  paymentIntentId: PropTypes.string,
  repairData: PropTypes.object,
  claimId: PropTypes.string
};

export default withStyles(styles)(PaymentForm)