import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import SelectControl from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { Field } from "react-final-form";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";

const styles = {
  ...customSelectStyle,
  label: {
    textTransform: "none",
    fontSize: "14px"
  }
};

function Select(props) {
  const {
    name,
    label,
    required,
    validate,
    classes,
    options,
    disabled,
    onChange: customOnChange,
    ...others
  } = props;

  return (
    <Field
      name={name}
      validate={validate}
      {...others}
      render={({ input, meta }) => {
        const error = meta.touched && !!meta.error;
        const { onChange: formOnChange } = input;

        const onChange = event => {
          const { value } = event.target;

          customOnChange && customOnChange(value);
          formOnChange(value);
        };

        const getLabel = value => {
          const item = options.find(o => o.value === value);

          return item ? item.label : value;
        };

        return (
          <FormControl
            fullWidth
            className={classes.selectFormControl}
            error={error}
          >
            <InputLabel
              className={`${classes.selectLabel}  ${classes.label}`}
            >
              {label}
              {required && <small> (required)</small>}
            </InputLabel>
            <SelectControl
              displayEmpty={false}
              MenuProps={{
                className: classes.selectMenu
              }}
              classes={{
                select: classes.select
              }}
              disabled={disabled}
              value={input.value}
              {...input}
              onChange={onChange}
              renderValue={getLabel}
              {...others}
            >
              {options.map(item => {
                return (
                  <MenuItem
                    key={item.value}
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected
                    }}
                    value={item.value}
                  >
                    {item.label}
                  </MenuItem>
                );
              })}
            </SelectControl>
            {error && <FormHelperText>{meta.error}</FormHelperText>}
          </FormControl>
        );
      }}
    />
  );
}

Select.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  validate: PropTypes.func,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  )
};

export default withStyles(styles)(Select);
