import { observable, makeObservable, action } from "mobx";
import axios from "axios";

export class GaragesStore {
  garages = [];
  currentGarage = null;

  constructor() {
    makeObservable(this, {
      garages: observable,
      currentGarage: observable,
      getGarages: action,
      getCurrentGarageDetails: action,
      getGarageStats: action,
      updateGarage: action,
      createGarage: action
    });
  }

  async getGaragesWithStats(includeDisabledOrgs) {
    const includeDisabledOrgsParam = includeDisabledOrgs ? { params: { includeDisabled: includeDisabledOrgs } } : { params: { includeDisabled: false } };
    const response = await axios.get("/api/garages", includeDisabledOrgsParam);
    this.garages = await Promise.all(response.data.map(async g => {
      const stats = await this.getGarageStats(g._id);
      return {
        ...g,
        ...stats
      };
    }));
    return this.garages;
  }

  async getGarages() {
    const response = await axios.get("/api/garages", { params: { includeDisabled: false } });
    return response.data;
  }

  async getCurrentGarageDetails(id) {
    const response = await axios.get(`/api/garages/${id}`);
    this.currentGarage = response.data;
  }

  async getGarageStats(id) {
    const response = await axios.get(`/api/garages/${id}/jobs`);
    return response.data;
  }

  async updateGarage(id, data) {
    await axios.put(`/api/garages/${id}`, data);
  }

  async createGarage(data) {
    await axios.post("/api/garages", data);
  }

  async findNearestGarages(postcode) {
    const response = await axios.get(`/api/garages/nearest/${postcode}`);
    return response.data;
  }
}

export default new GaragesStore();
