//Validation functions for react-final-form;

const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const pipe = (...functions) => (value, values) =>
  functions.reduce(
    (currentValue, currentFunction) =>
      !currentValue ? currentFunction(value, values) : currentValue,
    ''
  );

export const isRequired = value => {
  if (value === '' || value === undefined || value === null) {
    return 'This field is required';
  }
};

export const isNumber = value => {
  if (value !== undefined && Number.isNaN(parseInt(value))) {
    return 'This field must be a number';
  }
};

export const minLength = min => value => {
  if (value.length < min) {
    return `Should contain at least ${min} characters `;
  }
};

export const maxLength = max => value => {
  if (value.length > max) {
    return `Should contain maximum ${max} characters `;
  }
};

export const isPositive = value => {
  if (parseInt(value) < 0) {
    return 'This field must be a positive number';
  }
};

export const isPositiveNumber = pipe(
  isNumber,
  isPositive
);

export const email = value => {
  if (value && !emailPattern.test(value)) {
    return 'Please  provide proper email address';
  }
};
