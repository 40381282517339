import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, Typography } from '@material-ui/core';
import Button from '../../theme/CustomButtons/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import claimStore from '../../../stores/claimStore';
import quoteStore from '../../../stores/quoteStore';
import withStyles from '@material-ui/core/es/styles/withStyles';
import get from 'lodash.get';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    uploadButton: {
        marginBottom: '12px'
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
    },
});

function ModifyQuote(props) {
    const [open, setOpen] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [cost, setCost] = React.useState(props.cost);
    const [costRepairer, setCostRepairer] = React.useState(0);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChangeQuote = event => {
        setCost(event.target.value);
    };

    const handleChangeQuoteRepairer = event => {
        setCostRepairer(event.target.value);
    };

    const modifyQuote = async () => {
        try {
            await quoteStore.updateQuote(claimStore.currentClaim.quoteRef, cost, costRepairer);
            await claimStore.getClaim(claimStore.currentClaim._id);
            handleClose();
            props.successHandler();
        } catch (err) {
            console.log(err);
            const error = get(err, 'response.data.message', 'Failed to update quote');
            setError(error);
        }
    };

    const { classes } = props;
    return (
        <>
            <Button variant="contained" color="primary" onClick={handleClickOpen}>
                Modify Quote
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Modify Quote</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please provide the new quote for the claim. Note: user will not be requested to authorise nor will the repairer be notified.
                    </DialogContentText>
                    <TextField
                        id="cost"
                        label="Client Quote (ex. VAT)"
                        type="number"
                        defaultValue={cost}
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={handleChangeQuote}
                    />
                    <TextField
                        id="costRepairer"
                        label="Repairer Quote (ex. VAT)"
                        type="number"
                        defaultValue={costRepairer}
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={handleChangeQuoteRepairer}
                    />
                    {error && (
                        <Typography
                            color='error'
                            align='left'
                            data-cy='login-error'
                        >
                            {error}
                        </Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}
                        color="primary">
                        Cancel
                    </Button>
                    <Button onClick={modifyQuote}
                        color="primary"
                        name="modify-quote">
                        Modify Quote
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );

}

ModifyQuote.propTypes = {
    successHandler: PropTypes.func,
    cost: PropTypes.number,
};

export default withStyles(styles)(ModifyQuote);
