import React from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = {
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%'
  }
};

const ErrorWrapper = ({
  onRetry,
  isError,
  errorMessage = 'Error occurred',
  children,
  classes
}) => {
  if (isError) {
    return (
      <div className={classes.wrapper}>
        <Typography>{errorMessage}</Typography>
        <Button onClick={onRetry} color='secondary'>
          Retry
        </Button>
      </div>
    );
  }
  return children;
};

ErrorWrapper.propTypes = {
  onRetry: PropTypes.func,
  isError: PropTypes.bool,
  errorMessage: PropTypes.string,
  children: PropTypes.node
};

export default withStyles(styles)(ErrorWrapper);
