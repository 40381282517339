import React, { Component } from 'react';
import { observer } from 'mobx-react';
import userStore from '../../stores/userStore';
import { Form } from 'react-final-form';
import get from 'lodash.get';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography
} from '@material-ui/core';
import Input from '../form/Input';
import { isRequired, pipe } from '../../validation/validation';
import CssBaseline from '@material-ui/core/CssBaseline';
import GridItem from '../theme/Grid/GridItem';
import GridContainer from '../theme/Grid/GridContainer';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  layout: {
    width: '100px',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  }
});

const isLongEnough = value => {
  if (value !== undefined && value.length < 8) {
    return 'Minimum of 8 characters required';
  }
};

class UserUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
      success: false
    };
  }

  validate(values) {
    if (values.newPassword !== values.newPasswordConfirm) {
      this.setState({ error: 'Passwords do not match' });
    } else if (values.newPassword.length < 8) {
      this.setState({ error: 'Minimum of 8 characters required' });
    } else return true;
  }

  handleSubmit = async values => {
    try {
      if (this.validate(values)) {
        await userStore.updatePassword(values);
        this.setState({ success: true });
      }
    } catch (err) {
      const error = get(err, 'response.data.error', err.message);

      this.setState({ error });
    }
  };

  render() {
    const { error, success } = this.state;
    return (
      <React.Fragment>
        <CssBaseline />
        <GridContainer justifyContent='center'>
          <GridItem xs={12} sm={6} md={4} lg={3}>
            <Form
              onSubmit={this.handleSubmit}
              render={({ handleSubmit }) => (
                <GridContainer justifyContent='center'>
                  <form onSubmit={handleSubmit} id='passwordForm'>
                    <Card>
                      <CardHeader title='Update Password' />
                      <CardContent>
                        <Input
                          name='oldPassword'
                          label='Current Password'
                          type='password'
                          required
                          validate={isRequired}
                        />
                        <Input
                          name='newPassword'
                          label='New Password'
                          type='password'
                          required
                          validate={pipe(
                            isRequired,
                            isLongEnough
                          )}
                        />
                        <Input
                          name='newPasswordConfirm'
                          label='New Password (confirm)'
                          type='password'
                          required
                          validate={isRequired}
                        />
                      </CardContent>
                      <CardActions spacing={8}>
                        <Button color='secondary' type='submit'>
                          Update
                        </Button>
                        {error ? (
                          <Typography color='error'>{error}</Typography>
                        ) : null}
                        {success ? (
                          <Typography color='secondary'>
                            Password updated
                          </Typography>
                        ) : null}
                      </CardActions>
                    </Card>
                  </form>
                </GridContainer>
              )}
            />
          </GridItem>
        </GridContainer>
      </React.Fragment>
    );
  }
}

UserUpdate = observer(UserUpdate);
export default withStyles(styles)(UserUpdate);
