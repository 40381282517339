import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/core/styles';

const style = {
  label: {
    marginLeft: '2px'
  },
  iconButton: {
    padding: '8px'
  }
};

const NavbarItem = ({ onClick, label, icon, classes, ...others }) => {
  return (
    <>
      <Hidden smDown>
        <Button onClick={onClick} {...others} size='small' color='secondary'>
          {icon}
          <span className={classes.label}>{label}</span>
        </Button>
      </Hidden>
      <Hidden mdUp>
        <IconButton
          onClick={onClick}
          {...others}
          size='small'
          color='secondary'
          className={classes.iconButton}
        >
          {icon}
        </IconButton>
      </Hidden>
    </>
  );
};

NavbarItem.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
  icon: PropTypes.node
};

export default withStyles(style)(NavbarItem);
