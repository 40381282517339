import React, { Component } from "react";
import PropTypes from "prop-types";
import { Dialog, Typography, Grid } from "@material-ui/core";
import Button from "../theme/CustomButtons/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import claimStore from "../../stores/claimStore";
import quoteStore from "../../stores/quoteStore";
import withStyles from "@material-ui/core/es/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import get from "lodash.get";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

const styles = theme => ({
  ...customSelectStyle,
  label: {
    textTransform: "none",
    fontSize: "14px"
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  uploadButton: {
    marginBottom: "12px"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  },
  dialogActions: {
    justifyContent: "center"
  }
});


class SimpleQuote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      quote: {},
      error: null,
      file: null,
      cost: 0,
      costRepairer: 0,
      costsRepairer: [],
      topProducts: [],
      prices: [],
      product: null,
      price: null,
      inProgress: false,
      takeRate: 0
    };
  }

  handleClickOpen = async () => {
    this.setState({ open: true, error: null });
    const topProducts = await quoteStore.getTopProducts();
    this.setState({ topProducts: topProducts });
    if (topProducts.length > 0) {
      this._changeProduct(topProducts[0]);
    }
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleChangeProduct = (event, product) => {
    console.log("handle change product");
    if (product) {
      this._changeProduct(product);
    }
  };

  _changeProduct = (product) => {
    console.log("product update " + JSON.stringify(product));
    this.setState({ product });
    this.setState({ prices: product.prices });
    this.setState({ price: product.prices.length ? product.prices[0] : null });
    const cost = product.prices.length ? product.prices[0].amount / 100 : null;
    this.setState({ cost });
    this.setState({ costsRepairer: product.costsRepairer });
    const costRepairer = product.costsRepairer.length ? product.costsRepairer[0] : 0;
    this.setState({ costRepairer });
    this.setState({ takeRate: this.getTakeRate(costRepairer, cost) });
  };

  handleChangePrice = (event, price) => {
    if (price) {
      this.setState({ price });
      this.setState({ cost: price.amount / 100 });
      this.setState({ takeRate: this.getTakeRate(this.state.costRepairer, price.amount / 100) });
    }
  };

  handleChangeRepairerCost = (event, costRepairer) => {
    if (costRepairer) {
      this.setState({ costRepairer });
      this.setState({ takeRate: this.getTakeRate(costRepairer, this.state.cost) });
    }
  };

  handleChangeQuoteRepairer = event => {
    this.setState({ costRepairer: event.target.value });
    this.setState({ takeRate: this.getTakeRate(event.target.value, this.state.cost) });
  };


  getTakeRate(costRepairer, cost) {
    return (1 - (costRepairer / cost)) * 100;
  }

  addQuote = async () => {
    this.setState({ inProgress: true });
    //validate the inputs?
    try {
      await claimStore.addSimpleStripeQuote(claimStore.currentClaim._id, this.state.price.price, this.state.cost, this.state.costRepairer);
      await claimStore.requestQuoteAuthorisationRetail(claimStore.currentClaim._id, this.state.costRepairer);
      await claimStore.getClaim(claimStore.currentClaim._id);
      this.handleClose();
      this.props.successHandler();
    } catch (err) {
      const error = get(err, "response.data.message", "Failed to request authorisation");
      this.setState({ error, inProgress: false });
    }
  };


  render() {
    const { classes } = this.props;
    const { product, prices, price, topProducts, costsRepairer, costRepairer, inProgress, takeRate, open } = this.state;
    return (
      <div>
        <Button variant="contained" color="primary" onClick={this.handleClickOpen}>
          Add Simple Quote
        </Button>
        <Dialog open={open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Simple Quote</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please select product and price
            </DialogContentText>
            <Grid container spacing={2} direction="column">
              <Grid item>
                <Typography
                  align="left"
                >
                  Product:
                </Typography>
                <ToggleButtonGroup
                  id="products"
                  color="primary"
                  value={product ? product : ""}
                  exclusive
                  onChange={this.handleChangeProduct}
                >
                  {topProducts.map(item => {
                    return (
                      <ToggleButton value={item}>{item.description}</ToggleButton>
                    );
                  })}
                </ToggleButtonGroup>
              </Grid>
              <Grid item>
                <Typography
                  align="left"
                >
                  Client Quote:
                </Typography>
                <ToggleButtonGroup
                  id="prices"
                  color="primary"
                  value={price ? price : ""}
                  exclusive
                  onChange={this.handleChangePrice}
                >
                  {prices.map(item => {
                    return (
                      <ToggleButton value={item}>{item.amount / 100}</ToggleButton>
                    );
                  })}
                </ToggleButtonGroup>
              </Grid>
              <Grid item>
                <Typography
                  align="left"
                >
                  Repairer Quote:
                </Typography>
                <ToggleButtonGroup
                  id="repairerCosts"
                  color="primary"
                  value={costRepairer}
                  exclusive
                  onChange={this.handleChangeRepairerCost}
                >
                  {costsRepairer.map(item => {
                    return (
                      <ToggleButton value={item}>{item}</ToggleButton>
                    );
                  })}
                </ToggleButtonGroup>
                <TextField
                  id="costRepairer"
                  label="Repairer Quote (ex. VAT)"
                  type="number"
                  defaultValue={costRepairer}
                  value={costRepairer}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={this.handleChangeQuoteRepairer}
                />
              </Grid>
              <Grid item>
                <Typography
                  align="left"
                >
                  Take Rate: {Math.round(takeRate)} %
                </Typography>
              </Grid>
              {this.state.error && (
                <Grid item>
                  <Typography
                    color="error"
                    align="left"
                    data-cy="login-error"
                  >
                    {this.state.error}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button onClick={this.handleClose} color="primary" disabled={inProgress}>
              Cancel
            </Button>
            <Button onClick={this.addQuote} color="primary" name="request-simple-authorise" disabled={inProgress}>
              Add Quote
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
      ;
  }
}

SimpleQuote.propTypes = {
  successHandler: PropTypes.func
};

export default withStyles(styles)(SimpleQuote);
