import React, { Component } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import ApplicationBar from './ApplicationBar';
import LeftDrawer from './LeftDrawer';
import {isMobile} from "../../utils/helpers";

const drawerWidth = 240;
const styles = theme => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex'
  },
  appBar: {
    color: 'secondary',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36
  },
  hide: {
    display: 'none'
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: isMobile() ? theme.spacing.unit * 3 : 0,
    height: '100vh',
    overflow: 'auto'
  }
});

class Main extends Component {
  state = {
    open: false
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes, theme, claimStore, userStore, location: { pathname } } = this.props;
    const pickupPathPattern = /^\/pickup\/(\d)+(\w)+$/g;
    const quotePagePattern = /^\/quote-accept\/qt_(\d)+(\w)+$/g;
    const repairDatePages = /repairDate/g;
    const showAppBar = !(quotePagePattern.test(pathname) || pickupPathPattern.test(pathname) || repairDatePages.test(pathname));

    return (
      <div className={classes.root}>
        {showAppBar &&
          <ApplicationBar
              classes={classes}
              open={this.state.open}
              onClick={this.handleDrawerOpen}
              isAuthenticated={userStore.isAuthenticated}
          />
        }
        {userStore.isAuthenticated ? (
          <LeftDrawer
            classes={classes}
            open={this.state.open}
            onClick={this.handleDrawerClose}
            theme={theme}
            claimStore={claimStore}
            userStore={userStore}
          />
        ) : (
          ''
        )}

        <main className={classes.content}>
          <div className={showAppBar ? classes.appBarSpacer : ''} />
          {this.props.children}
        </main>
      </div>
    );
  }
}

Main.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  claimStore: PropTypes.object.isRequired,
  userStore: PropTypes.object.isRequired
};

Main = observer(withRouter(Main));
export default withStyles(styles, { withTheme: true })(Main);
