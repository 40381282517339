import axios from 'axios';

let unauthorizedInterceptor;

export const addUnauthorizedHandler = onUnauthorized => {
  unauthorizedInterceptor = axios.interceptors.response.use(
    response => response,
    error => {
      if (error.response.status === 401) {
        onUnauthorized();
      }
      return Promise.reject(error);
    }
  );
};

export const removeUnauthorizedHandler = () => {
  axios.interceptors.response.eject(unauthorizedInterceptor);
};

export const setAuthToken = token => {
  if (token) {
    axios.defaults.headers.common['Authorization'] = token;
    localStorage.setItem('jwtToken', token);
  }
};

export const removeAuthToken = () => {
  delete axios.defaults.headers.common['Authorization'];
  localStorage.removeItem('jwtToken');
};