import React from "react";
import PropTypes from "prop-types";
import { Dialog, Typography } from "@material-ui/core";
import Button from "../theme/CustomButtons/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import claimStore from "../../stores/claimStore";
import withStyles from "@material-ui/core/es/styles/withStyles";
import get from "lodash.get";
import "react-day-picker/dist/style.css";

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  },
  distance: {
    padding: "5px",
    margin: "10px"
  }
});

function StartRepair(props) {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [pending, setPending] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const startRepair = async () => {
    const { history } = props;
    try {
      setPending(true);
      await claimStore.startRepair(claimStore.currentClaim._id);
      await claimStore.getClaim(claimStore.currentClaim._id);
      history.push("/marketplace/claims");
    } catch (err) {
      const error = get(err, "response.data.message", "Failed to Start Repair");
      setError(error);
      setPending(false);
    }
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Start Repair
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Start Repair</DialogTitle>
        <DialogContent>
          <DialogContentText className={props.classes.distance}>
            <b>Important!</b> Please ensure that you take photos during your repair, and of the finished job.
          </DialogContentText>
          {error && (
            <Typography
              color="error"
              align="left"
              data-cy="login-error"
            >
              {error}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={startRepair} color="primary" name="start-repair-modal" disabled={pending}>
            Start Repair
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

StartRepair.propTypes = {
  history: PropTypes.object
};

export default withStyles(styles)(StartRepair);
