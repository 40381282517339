import React, { Component } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import garagesStore from "../../stores/garagesStore";
import userStore from "../../stores/userStore";
import LoadingWrapper from "../common/LoadingWrapper";
import { observer } from "mobx-react";
import Restricted from "components/restricted/Restricted";
import get from "lodash.get";
import { PRIVILEGES } from "../../constants/privileges";
import GridContainer from "../theme/Grid/GridContainer";
import GridItem from "../theme/Grid/GridItem";
import { Typography } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

const styles = {
  header: {
    marginBottom: "8px",
    padding: "16px"
  },
  row: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.1)"
    }
  }
};


class GarageList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      selectedIndex: null,
      garages: null,
      displayFilter: "week",
      orgFilter: "enabled"
    };
  }

  async componentDidMount() {
    await this.getGarages();
    this.setState({ loading: false });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.orgFilter !== this.state.orgFilter) {
      await this.refetch();
    }
  }

  getGarages = async () => {
    try {
      const includeDisabledOrgs = this.state.orgFilter === "allOrgs";
      const garages = await garagesStore.getGaragesWithStats(includeDisabledOrgs);
      this.setState({ garages: garages.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1) });
    } catch (e) {
      console.log(e)
    }
  };

  refetch = async () => {
    this.setState({ loading: true, isError: false });
    await this.getGarages();
    this.setState({ loading: false });
  };

  createGarage = () => this.props.history.push("/createGarage");
  handlePeriodFilterChange = (event, displayFilter) => {
    if (displayFilter != null) {
      this.setState({ displayFilter });
    }
  };
  handleOrganisationFilter = async (event, orgFilter) => {
    if (orgFilter != null) {
      this.setState({ orgFilter });
    }
  };

  render() {
    const { classes } = this.props;
    const { garages, displayFilter, orgFilter } = this.state;
    const hasManyOrganizations = userStore.hasManyOrganizations;
    const shouldDisplayAll = !(displayFilter === "all");
    const shouldDisplayWeek = !(displayFilter === "week" || displayFilter === "all");
    const shouldDisplayMonth = !(displayFilter === "month" || displayFilter === "all");
    return (
      <LoadingWrapper isLoading={this.state.loading}>
        <GridContainer justifyContent="space-between">
          <GridItem xs={12}>
            <Paper className={classes.header}>
              <GridContainer>
                <GridItem xs={8} sm={10}>
                  <Typography component="h1" variant="h5">
                    Garage List
                  </Typography>
                </GridItem>
                <Restricted privileges={[PRIVILEGES.CreateGarage]}>
                  <GridItem xs={4} sm={2}>
                    <Button onClick={this.createGarage} color="secondary">
                      Add Garage
                    </Button>
                  </GridItem>
                </Restricted>
              </GridContainer>
            </Paper>
          </GridItem>
          <GridItem>
            {hasManyOrganizations && (
              <ToggleButtonGroup
                color="primary"
                value={orgFilter}
                exclusive
                onChange={this.handleOrganisationFilter}
              >
                <ToggleButton value="allOrgs">All Orgs</ToggleButton>
                <ToggleButton value="enabled">Enabled Orgs</ToggleButton>
              </ToggleButtonGroup>
            )}
            <ToggleButtonGroup
              color="primary"
              value={displayFilter}
              exclusive
              onChange={this.handlePeriodFilterChange}
            >
              <ToggleButton value="all">All</ToggleButton>
              <ToggleButton value="week">Last Week</ToggleButton>
              <ToggleButton value="month">Last Month</ToggleButton>
            </ToggleButtonGroup>
          </GridItem>
          <GridItem>
            <Paper style={{ width: "100%", overflowX: "initial" }}>
              <Table aria-label="garages" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Postcode</TableCell>
                    {hasManyOrganizations && (
                      <TableCell>Organization</TableCell>
                    )}
                    <TableCell hidden={shouldDisplayAll}>Total Jobs</TableCell>
                    <TableCell hidden={shouldDisplayMonth}>Total Jobs (Month)</TableCell>
                    <TableCell hidden={shouldDisplayWeek}>Total Jobs (Week)</TableCell>
                    <TableCell hidden={shouldDisplayAll}>Finished Jobs</TableCell>
                    <TableCell hidden={shouldDisplayMonth}>Finished Jobs (Month)</TableCell>
                    <TableCell hidden={shouldDisplayWeek}>Finished Jobs (Week)</TableCell>
                    <TableCell hidden={shouldDisplayAll}>Abandoned Jobs</TableCell>
                    <TableCell hidden={shouldDisplayMonth}>Abandoned Jobs (Month)</TableCell>
                    <TableCell hidden={shouldDisplayWeek}>Abandoned Jobs (Week) </TableCell>
                    <TableCell>Open Jobs</TableCell>
                    <TableCell hidden={shouldDisplayAll}>Completed Revenue</TableCell>
                    <TableCell hidden={shouldDisplayMonth}>Completed Revenue (Month)</TableCell>
                    <TableCell>Abandoned Revenue</TableCell>
                    <TableCell>Upcoming Revenue</TableCell>
                    <TableCell>Revenue At Risk</TableCell>
                    <TableCell hidden={shouldDisplayAll}>Dates offered hrs</TableCell>
                    <TableCell hidden={shouldDisplayMonth}>Dates offered hrs (Month)</TableCell>
                    <TableCell hidden={shouldDisplayWeek}>Dates offered hrs (Week)</TableCell>
                    <TableCell hidden={shouldDisplayMonth}>Avg Lead Time days (Month)</TableCell>
                    <TableCell hidden={shouldDisplayWeek}>Avg Lead Time days (Week)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {garages && garages.map(garage => (
                    <TableRow
                      key={garage._id}
                      className={classes.row}
                      onClick={() =>
                        this.props.history.push(`/garages/${garage._id}`)
                      }
                    >
                      <TableCell>{garage.name}</TableCell>
                      <TableCell>{garage.postcode}</TableCell>
                      {hasManyOrganizations && (
                        <TableCell>
                          {get(garage, "organisation.name")}
                        </TableCell>
                      )}
                      <TableCell hidden={shouldDisplayAll}>{garage.totalJobs}</TableCell>
                      <TableCell hidden={shouldDisplayMonth}>{garage.totalJobsMonth}</TableCell>
                      <TableCell hidden={shouldDisplayWeek}>{garage.totalJobsWeek}</TableCell>
                      <TableCell hidden={shouldDisplayAll}>{garage.finishedJobs}</TableCell>
                      <TableCell hidden={shouldDisplayMonth}>{garage.finishedJobsMonth}</TableCell>
                      <TableCell hidden={shouldDisplayWeek}>{garage.finishedJobsWeek}</TableCell>
                      <TableCell hidden={shouldDisplayAll}>{garage.archivedJobs}</TableCell>
                      <TableCell hidden={shouldDisplayMonth}>{garage.archivedJobsMonth}</TableCell>
                      <TableCell hidden={shouldDisplayWeek}>{garage.archivedJobsWeek}</TableCell>
                      <TableCell>{garage.openJobs}</TableCell>
                      <TableCell hidden={shouldDisplayAll}>{parseInt(garage.finishedRevenue)}</TableCell>
                      <TableCell hidden={shouldDisplayMonth}>{parseInt(garage.finishedRevenueMonth)}</TableCell>
                      <TableCell>{parseInt(garage.lostRevenue)}</TableCell>
                      <TableCell>{parseInt(garage.upcomingRevenue)}</TableCell>
                      <TableCell>{parseInt(garage.revenueAtRisk)}</TableCell>
                      <TableCell hidden={shouldDisplayAll}>{parseInt(garage.averageDatesOffered)}</TableCell>
                      <TableCell hidden={shouldDisplayMonth}>{parseInt(garage.averageDatesOfferedMonth)}</TableCell>
                      <TableCell hidden={shouldDisplayWeek}>{parseInt(garage.averageDatesOfferedWeek)}</TableCell>
                      <TableCell hidden={shouldDisplayMonth}>{parseInt(garage.leadTimeMonth)}</TableCell>
                      <TableCell hidden={shouldDisplayWeek}>{parseInt(garage.leadTimeWeek)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </GridItem>
        </GridContainer>
      </LoadingWrapper>
    );
  }
}

GarageList.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  })
};

export default withStyles(styles)(observer(GarageList));
