import React from "react";
import PropTypes from "prop-types";
import { Checkbox, Dialog, FormControlLabel, Typography } from "@material-ui/core";
import Button from "../theme/CustomButtons/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import claimStore from "../../stores/claimStore";
import withStyles from "@material-ui/core/es/styles/withStyles";
import get from "lodash.get";
import { DayPicker } from "react-day-picker";
import { isSameDay } from "date-fns";
import "react-day-picker/dist/style.css";

const firstDayOfWeek = {
  "en-gb": 1
};

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  },
  distance: {
    padding: "5px",
    margin: "10px"
  }
});

function ArrangePickup(props) {

  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [contact, setContact] = React.useState(true);
  const [selectedDays, setSelectedDays] = React.useState([]);
  const [pending, setPending] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDayClick = (day, { selected }) => {
    const tzIndependentDay = new Date(day.getTime() - day.getTimezoneOffset() * 60000);
    const newSelected = [...selectedDays]
    if (selected) {
      const selectedIndex = selectedDays.findIndex(selectedDay =>
        isSameDay(selectedDay, tzIndependentDay)
      );
      newSelected.splice(selectedIndex, 1);
    } else {
      newSelected.push(tzIndependentDay);
    }
    setSelectedDays(newSelected);
  };

  const handleContact = event => {
    setContact(event.target.checked);
  };

  const requestPickup = async () => {
    const { history } = props;
    if (selectedDays.length < 1) {
      setError("At least 1 pickup day needs to be selected");
    } else {
      try {
        setPending(true);
        await claimStore.requestPickup(claimStore.currentClaim._id, selectedDays, contact);
        await claimStore.getClaim(claimStore.currentClaim._id);
        history.push("/marketplace/claims");
      } catch (err) {
        const error = get(err, "response.data.message", "Failed to arrange repair");
        setError(error);
        setPending(false);
      }
    }
  };
  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Arrange Repair
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Arrange Repair</DialogTitle>
        <DialogContent>
          <DialogContentText className={props.classes.distance}>
            Please provide available repair dates
          </DialogContentText>
          <DayPicker
            weekStartsOn={firstDayOfWeek["en-gb"]}
            selected={selectedDays}
            disabled={{ before: new Date() }}
            onDayClick={handleDayClick}
            showOutsideDays
          />
          <FormControlLabel
            value="end"
            control={<Checkbox defaultChecked color="primary" onChange={handleContact} />}
            label="Contact Driver"
            labelPlacement="end"
          />
          {error && (
            <Typography
              color="error"
              align="left"
              data-cy="pickup-error"
            >
              {error}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={requestPickup} color="primary" name="arrange-pickup-modal" disabled={pending}>
            Arrange Repair
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ArrangePickup.propTypes = {
  history: PropTypes.object,
  pickUp: PropTypes.string
};

export default withStyles(styles)(ArrangePickup);
