import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Typography
} from "@material-ui/core";
import Input from "../form/Input";
import Checkbox from "../form/Checkbox";
import { isRequired } from "../../validation/validation";
import { Form } from "react-final-form";

function LabelForm(props) {
  const {
    onSubmit,
    initialValues,
    submitError,
    title,
    submitLabel
  } = props;

  return (
    <Grid container justifyContent="center">
      <Grid item md={12} lg={6} xl={4}>
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              <Card>
                <CardHeader title={title} />
                <CardContent>
                  <Input
                    name="name"
                    label="Name"
                    required
                    validate={isRequired}
                  />
                  <Input
                    name="description"
                    label="Description"
                  />
                  <Checkbox
                    id="automated"
                    name="automated"
                    label="Is automated?"
                  />
                  <Checkbox
                    id="deleteAllowed"
                    name="deleteAllowed"
                    label="Is delete allowed?"
                  />
                </CardContent>
                <CardActions>
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <Button
                        color="secondary"
                        type="submit"
                        disabled={submitting}
                      >
                        {submitLabel}
                      </Button>
                    </Grid>
                  </Grid>
                </CardActions>
              </Card>
              {submitError && (
                <Typography color="error" align="center">
                  {submitError}
                </Typography>
              )}
            </form>
          )}
        />
      </Grid>
    </Grid>
  );
}

LabelForm.propTypes = {
  initialValues: PropTypes.object,
  submitError: PropTypes.string,
  title: PropTypes.string,
  submitLabel: PropTypes.string,
  onSubmit: PropTypes.func
};

export default LabelForm;
