import React from "react";
import PropTypes from "prop-types";
import CustomInput from "components/theme/CustomInput/CustomInput.jsx";
import { Field } from "react-final-form";

function Input(props) {
  const {
    name,
    label,
    required,
    validate,
    type,
    multiline,
    rows,
    disabled,
    inputProps: additionalInputProps,
    ...others
  } = props;

  return (
    <Field
      name={name}
      validate={validate}
      {...others}
      render={({ input, meta }) => {
        const success = meta.touched && meta.valid;

        const validationError = meta.touched && meta.error;
        const submitError = !meta.dirtySinceLastSubmit && meta.submitError;
        const errorMessage = validationError || submitError;

        const inputProps = {
          ...input,
          ...additionalInputProps,
          disabled,
          type
        };

        if (multiline) {
          inputProps.multiline = true;
          inputProps.rows = rows;
        }

        return (
          <CustomInput
            labelText={
              <span>
                  {label}
                {required && <small> (required)</small>}
                </span>
            }
            success={success}
            error={!!errorMessage}
            helpText={errorMessage}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={inputProps}
          />
        );
      }}
    />
  );
}

Input.defaultProps = {
  rows: 1
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  multiline: PropTypes.bool,
  disabled: PropTypes.bool,
  validate: PropTypes.func
};

export default Input;
