import React from "react";
import PropTypes from "prop-types";
import Images from "components/form/Images";
import { Grid, Typography } from "@material-ui/core";
import carrear1 from "../../assets/img/fnolform/carrear1.png";
import carrear2 from "../../assets/img/fnolform/carrear2.png";
import carfront1 from "../../assets/img/fnolform/carfront1.png";
import carfront2 from "../../assets/img/fnolform/carfront2.png";
import cardamage1 from "../../assets/img/fnolform/cardamage1.png";
import cardamage2 from "../../assets/img/fnolform/cardamage2.png";

function PictureGuideForm() {
  return (
    <Grid container spacing={10}>
      <Grid item xs={12} md={6}>
        <img alt="" src={carrear1} />
        <Typography variant="body1">
          Stand 3m from left rear corner of the car
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <img alt="" src={carrear2} />
        <Typography variant="body1">
          Stand 3m from right rear corner of the car
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <img alt="" src={carfront1} />
        <Typography variant="body1">
          Stand 3m from left front corner of the car
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <img alt="" src={carfront2} />
        <Typography variant="body1">
          Stand 3m from right front corner of the car
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <img alt="" src={cardamage1} />
      </Grid>
      <Grid item xs={12} md={6}>
        <img alt="" src={cardamage2} />
        <Typography variant="body1">
          Take 3 to 4 photos of the damaged area, from close up to 3m away
        </Typography>
      </Grid>
      <Images name="images" title="Damage Images" progress={{}} />
    </Grid>
  );
}

PictureGuideForm.propTypes = {
  form: PropTypes.object
};

export default PictureGuideForm;
