import React from 'react';
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import GridContainer from 'components/theme/Grid/GridContainer.jsx';
import GridItem from 'components/theme/Grid/GridItem.jsx';
import Table from 'components/theme/Table/Table.jsx';
import Card from 'components/theme/Card/Card.jsx';
import CardHeader from 'components/theme/Card/CardHeader.jsx';
import CardIcon from 'components/theme/Card/CardIcon.jsx';
import CardBody from 'components/theme/Card/CardBody.jsx';

import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';

// @material-ui/icons

class DetailsCard extends React.Component {
  render() {
    const { classes, title, tableData, color, icon } = this.props;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color={color} icon>
                <CardIcon color={color}>{icon}</CardIcon>
                <h4 className={classes.cardIconTitle}>{title}</h4>
              </CardHeader>
              <CardBody>
                <GridContainer justifyContent='space-between'>
                  <GridItem xs={12} sm={12} md={12}>
                    <Table tableData={tableData} />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

DetailsCard.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired
};

export default withStyles(dashboardStyle)(DetailsCard);
