import React, { useState, useEffect } from "react";
import PickUpForm from "./PickUpForm";
import { observer } from "mobx-react";
import claimStore from "../../stores/claimStore";
import { withRouter } from "react-router";
import Spinner from "../common/Spinner";
import get from "lodash.get";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import paymentStore from "../../stores/paymentStore";
import { Typography } from "@material-ui/core";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);
const PickUp = observer((props) => {
  const [state, setState] = useState({
    dates: null,
    location: null,
    chargeRequired: null,
    phoneNumber: null
  });

  const [clientSecret, setClientSecret] = useState("");
  const [paymentIntentId, setPaymentIntentId] = useState("");
  const [error, setError] = useState(null);
  const [confirmedDate, setConfirmedDate] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const { data, error } = await claimStore.getPickupDates(props.match.params.claimId);
      if (!error) {
        const dates = data.pickUpDates.map(function(d) {
          return { value: d, label: new Date(d).toDateString() };
        });
        setConfirmedDate(data.confirmedDate);
        setState({
          dates: dates,
          location: data.location,
          chargeRequired: data.chargeRequired,
          phoneNumber: data.phoneNumber ? data.phoneNumber.toString() : ""
        });
        if (data.chargeRequired) {
          createPaymentIntent();
        }
      } else {
        setError(error);
      }
    };
    const createPaymentIntent = async () => {
      const result = await paymentStore.createPaymentIntent(props.match.params.claimId);
      setClientSecret(result.clientSecret);
      setPaymentIntentId(result.id);
    };
    fetchData();
  }, [props.match.params.claimId]);

  const onSubmit = async (values) => {
    const { error } = await claimStore.confirmPickUp(props.match.params.claimId, {
      ...values
    });
    if (!error) {
      if (values.contactRequired) {
        props.history.push("/repairDateRejected");
      } else {
        props.history.push("/repairDateSelected");
      }
    }
    if (error) {
      const updatedError = get(error, "response.data.errors[0].pickup", "We are sorry but we were unable to save your preferences. Please try again later.");
      setError(updatedError);
    }
  };

  const appearance = {
    theme: "stripe"
  };

  const options = {
    clientSecret,
    appearance
  };

  const { dates, location, phoneNumber, chargeRequired } = state;

  if (confirmedDate) return (
    <Typography>`The repair date is already selected for {new Date(confirmedDate).toDateString()} - if that is not
      correct, please contact estimates@axioma.co.uk`</Typography>);
  if (!chargeRequired) return (<PickUpForm
    error={error}
    dates={dates}
    location={location}
    phoneNumber={phoneNumber}
    onSubmit={onSubmit}
    chargeRequired={chargeRequired}
    claimId={props.match.params.claimId}
  />);
  if (clientSecret) return (<Elements options={options} stripe={stripePromise}><PickUpForm
    error={error}
    dates={dates}
    location={location}
    phoneNumber={phoneNumber}
    onSubmit={onSubmit}
    chargeRequired={chargeRequired}
    clientSecret={clientSecret}
    paymentIntentId={paymentIntentId}
    claimId={props.match.params.claimId}
  /></Elements>);
  return (<Spinner message="Loading..." fullScreen />);
});

export default withRouter(PickUp);