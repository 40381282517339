import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import Wizard from './Wizard.js';
import GridContainer from 'components/theme/Grid/GridContainer.jsx';
import GridItem from 'components/theme/Grid/GridItem.jsx';

class FormWizard extends Component {
  state = {
    currentStep: 0
  };

  onStepClick = (step, form) => {
    const { currentStep } = this.state;
    const { valid } = form.getState();

    if (step < currentStep || valid) {
      this.setState({ currentStep: step });
    }
  };

  next = () => {
    this.setState(state => ({
      currentStep: state.currentStep + 1
    }));
  };

  previous = () => {
    this.setState(state => ({
      currentStep: Math.max(state.currentStep - 1, 0)
    }));
  };

  validate = values => {
    const validate = this.props.steps[this.state.currentStep].validate;

    return validate ? validate(values) : {};
  };

  onSubmit = values => {
    const isLastStep = this.props.steps.length - 1 === this.state.currentStep;

    if (isLastStep) {
      return this.props.onSubmit(values);
    } else {
      this.next();
    }
  };

  render() {
    const {
      title,
      subtitle,
      steps,
      initialValues,
      formProps,
      pending,
      error,
      success
    } = this.props;

    return (
      <Form
        initialValues={initialValues}
        validate={this.validate}
        onSubmit={this.onSubmit}
        {...formProps}
        render={({ handleSubmit, submitting, form }) => (
          <GridContainer justifyContent='center'>
            <GridItem xs={12} sm={8}>
              <form
                onSubmit={async event => {
                  if (await handleSubmit(event)) {
                    form.reset();
                  }
                }}
              >
                <Wizard
                  currentStep={this.state.currentStep}
                  steps={steps}
                  form={form}
                  previous={this.previous}
                  onStepClick={this.onStepClick}
                  title={title}
                  subtitle={subtitle}
                  pending={pending || submitting}
                  error={error}
                  success={success}
                />
              </form>
            </GridItem>
          </GridContainer>
        )}
      />
    );
  }
}

FormWizard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      stepName: PropTypes.string,
      stepComponent: PropTypes.func,
      stepId: PropTypes.string,
      validate: PropTypes.func
    })
  ),
  pending: PropTypes.bool,
  error: PropTypes.string,
  success: PropTypes.bool
};

export default FormWizard;
