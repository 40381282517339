import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Button } from '@material-ui/core';
import { observer } from 'mobx-react';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import AccountIcon from '@material-ui/icons/PersonOutline';
import AdminIcon from '@material-ui/icons/SupervisorAccountOutlined';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import HelpIcon from '@material-ui/icons/HelpOutline';

import userStore from '../../stores/userStore';
import Support from '../support/Support';
import Restricted from '../restricted/Restricted';
import { PRIVILEGES } from '../../constants/privileges';
import Notifications from '../notifications/Notifications';
import NavbarItem from './NavbarItem';

class Navbar extends Component {
  onLogoutClick = () => {
    userStore.logoutUser('Logged out');
  };

  renderAuthLinks = () => {
    return (
      <div>
        <Restricted privileges={[PRIVILEGES.ReadClaimsStats]}>
          <NavbarItem
            component={Link}
            to='/admin'
            label='Admin'
            icon={<AdminIcon fontSize='small' />}
          />
        </Restricted>
        <Support>
          {({ openSupport }) => (
            <NavbarItem
              onClick={openSupport}
              label='Help & Feedback'
              icon={<HelpIcon fontSize='small' />}
            />
          )}
        </Support>
        <NavbarItem
          component={Link}
          to='/account'
          id='account-link'
          label='Account'
          icon={<AccountIcon />}
        />
        <Notifications>
          {({ openNotifications }) => (
            <NavbarItem
              onClick={openNotifications}
              label='Notifications'
              icon={<NotificationsIcon fontSize='small' />}
            />
          )}
        </Notifications>
        <NavbarItem
          onClick={this.onLogoutClick}
          label='Logout'
          icon={<LogoutIcon fontSize='small' />}
        />
      </div>
    );
  };

  renderGuestLinks = () => (
    <div>
      <Button size='small' color='secondary' component={Link} to='/login'>
        Login
      </Button>
    </div>
  );

  render() {
    const { isAuthenticated } = userStore;

    return (
      <div style={{ marginRight: '24px' }}>
        {isAuthenticated ? this.renderAuthLinks() : this.renderGuestLinks()}
      </div>
    );
  }
}

Navbar = observer(Navbar);
export default withRouter(Navbar);
