import React, { Component, Fragment } from "react";
import { observer } from "mobx-react";

import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Grid,
  Paper,
  CircularProgress, Hidden, Typography
} from '@material-ui/core';
import get from 'lodash.get';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment-business-days';
import Button from '../../theme/CustomButtons/Button';
import claimStore from '../../../stores/claimStore';
import Restricted from '../../restricted/Restricted';
import { PRIVILEGES } from '../../../constants/privileges';
import {
  CLAIM_STATUS,
  CLAIM_STATUS_KEYS
} from "../../../constants/claimStatus";
import ExcelDownload from "./ExcelDownload";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = {
  header: {
    marginBottom: '8px',
    padding: '16px'
  },
};

class Actions extends Component {

  async handlePayment(id) {
    await claimStore.confirmClaimPayment(id);
    await claimStore.getClaims(60);
  }

  dateFromActions(actions, status) {
    return actions
      .filter(a => a.name === status)
      .map(a => a.createdAt)
      .shift();
  }

  render() {
    const { classes } = this.props;
    if (!this.props.actions) {
      return <CircularProgress/>;
    }

    const rawDataSet = this.props.actions.map(row => {
      const registeredDate = this.dateFromActions(row.actions, CLAIM_STATUS_KEYS.Registered) ? this.dateFromActions(row.actions, CLAIM_STATUS_KEYS.Registered) : this.dateFromActions(row.actions, CLAIM_STATUS_KEYS.Fnol);
      const awaitingDate = this.dateFromActions(row.actions, CLAIM_STATUS_KEYS.AwaitingQuoteAuthorisation);
      const inMarketplace = this.dateFromActions(row.actions, CLAIM_STATUS_KEYS.InMarketplace);
      const biddingDate = this.dateFromActions(row.actions, CLAIM_STATUS_KEYS.Bidding);
      const pickupDate = this.dateFromActions(row.actions, CLAIM_STATUS_KEYS.AwaitingPickup);
      const inRepairDate = this.dateFromActions(row.actions, CLAIM_STATUS_KEYS.InRepair);
      const finishedDate = this.dateFromActions(row.actions, CLAIM_STATUS_KEYS.Finished)
      const winningBid = row.bid.find(elem => elem.winning === true);
      const garage = winningBid ? get(winningBid, "garage.name") : "";
      const quote = get(row, "quote.cost", 0);
      const repairConfirmedDate = get(row.pickup, "date");
      const bookedIn = biddingDate ? moment.duration(moment(biddingDate).diff(registeredDate)).hours() : "";
      return {
        id: row._id,
        claim: `${row.vehicle.make} ${row.vehicle.model} (${row.vehicle.year})`,
        status: CLAIM_STATUS[row.status],
        license: row.vehicle.registration,
        policynumber: row.policynumber ? row.policynumber : "",
        createdAt: row.date,
        garage: garage,
        quote: quote,
        organisation: row.organisation ? row.organisation.name : "",
        registeredDate: registeredDate,
        finishedDate: finishedDate,
        inRepairDate: inRepairDate,
        biddingDate: biddingDate,
        awaitingDate: awaitingDate,
        approvedDate: inMarketplace,
        pickupDate: pickupDate,
        repairConfirmedDate: repairConfirmedDate,
        bookedIn: bookedIn,
        estimateDuration: awaitingDate ? moment(awaitingDate).businessDiff(moment(registeredDate)) : "",
        findRepairerDuration: biddingDate ? moment(biddingDate).businessDiff(moment(inMarketplace)) : "",
        offRoadDuration: finishedDate ? moment(finishedDate).businessDiff(moment(inRepairDate)) : "",
        turnaroundDuration: finishedDate ? moment(finishedDate).businessDiff(moment(registeredDate)) : ""
      };
    });

    const tableRows = rawDataSet.map(row => {
      return (
        <TableRow key={row.id}>
          <TableCell>
            {row.claim}
          </TableCell>
          <TableCell>{row.status}</TableCell>
          <TableCell>{row.license}</TableCell>
          <TableCell>
            {row.createdAt && <Moment format="DD/MM/YYYY HH:mm">{row.createdAt}</Moment>}
          </TableCell>
          <Hidden mdDown>
            <TableCell>
              {row.biddingDate ? (
                <Moment format="DD/MM/YYYY HH:mm">{row.biddingDate}</Moment>
              ) : ("N/A")}
            </TableCell>
            <TableCell>
              {row.awaitingDate ? (
                <Moment format="DD/MM/YYYY HH:mm">{row.awaitingDate}</Moment>
              ) : ("N/A")}
            </TableCell>
            <TableCell>
              {row.approvedDate ? (
                <Moment format="DD/MM/YYYY HH:mm">{row.approvedDate}</Moment>
              ) : ("N/A")}
            </TableCell>
            <TableCell>
              {row.pickupDate ? (
                <Moment format="DD/MM/YYYY HH:mm">{row.pickupDate}</Moment>
              ) : ("N/A")}
            </TableCell>
            <TableCell>
              {row.inRepairDate ? (
                <Moment format="DD/MM/YYYY HH:mm">{row.inRepairDate}</Moment>
              ) : ("N/A")}
            </TableCell>
            <TableCell>
              {row.repairConfirmedDate ? (
                <Moment format="DD/MM/YYYY HH:mm">{row.repairConfirmedDate}</Moment>
              ) : ("N/A")}
            </TableCell>
          </Hidden>
          <TableCell>
            {row.finishedDate ? (
              <Moment format="DD/MM/YYYY HH:mm">{row.finishedDate}</Moment>
            ) : ("N/A")}
          </TableCell>
          <TableCell>{row.garage}</TableCell>
          <TableCell>{row.quote}</TableCell>
          <Hidden mdDown>
            <TableCell>{row.organisation}</TableCell>
          </Hidden>
          <TableCell>
            <Link to={"/explorer/" + row.id}>show</Link>
            <br />
            <Link to={"/dashboardClaim/" + row.id}>details</Link>
          </TableCell>
          <Restricted privileges={[PRIVILEGES.ConfirmClaimPayment]}>
            <TableCell>
              {row.status === "Finished" ? (
                <Button
                  color="primary"
                  simple
                  justIcon
                  onClick={() => this.handlePayment(row.id)}
                >
                  Paid
                </Button>
              ) : null}
            </TableCell>
          </Restricted>
          <Hidden mdDown>
            <TableCell>
              {row.bookedIn}
            </TableCell>
            <TableCell>
              {row.estimateDuration}
            </TableCell>
            <TableCell>
              {row.findRepairerDuration}
            </TableCell>
            <TableCell>
              {row.offRoadDuration}
            </TableCell>
            <TableCell>
              {row.turnaroundDuration}
            </TableCell>
          </Hidden>
        </TableRow>
      );
    });
    return (
      <Fragment>
        <Grid container>
          <Grid item sm={12} lg={12}>
            <Paper className={classes.header}>
              <Typography component="h1" variant="h5">
                Claim Explorer (Last 60 days)
              </Typography>
            </Paper>
          </Grid>
          <Grid item sm={12} lg="auto">
            <ExcelDownload dataSet={rawDataSet} />
          </Grid>
          <Grid item sm={12} lg="auto">
            <Paper style={{ padding: 5, margin: 3 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Claim</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>License plate</TableCell>
                    <TableCell>Created at</TableCell>
                    <Hidden mdDown>
                      <TableCell>Repair Allocated</TableCell>
                      <TableCell>Estimate Issued</TableCell>
                      <TableCell>Estimate Approved</TableCell>
                      <TableCell>Pickup Request Issued</TableCell>
                      <TableCell>In-Repair Date</TableCell>
                      <TableCell>Confirmed Repair Date</TableCell>
                    </Hidden>
                    <TableCell>Finished Date</TableCell>
                    <TableCell>Garage</TableCell>
                    <TableCell>Quote</TableCell>
                    <Hidden mdDown>
                      <TableCell>Organisation</TableCell>
                    </Hidden>
                    <TableCell>Details</TableCell>
                    <Restricted privileges={[PRIVILEGES.ConfirmClaimPayment]}>
                      <TableCell>Payment</TableCell>
                    </Restricted>
                    <Hidden mdDown>
                      <TableCell>Booking (Hrs)</TableCell>
                      <TableCell>Estimate Issued(Workdays)</TableCell>
                      <TableCell>Time to find repairer(WD)</TableCell>
                      <TableCell>Vehicle Off Road(WD)</TableCell>
                      <TableCell>Total Turnaround(WD)</TableCell>
                    </Hidden>
                  </TableRow>
                </TableHead>
                <TableBody>{tableRows}</TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default withStyles(styles)(observer(Actions));
