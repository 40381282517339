import React from 'react';
import {
  isRequired,
  isPositiveNumber,
  pipe
} from '../../../validation/validation';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Select from 'components/form/Select';
import Input from 'components/form/Input';
import Images from 'components/form/Images';
import Restricted from 'components/restricted/Restricted';
import { Grid } from '@material-ui/core';
import Button from 'components/theme/CustomButtons/Button.jsx';
import { dataCarMake } from '../../../data/DataCarMake';
import { groupedCarModels } from '../../../data/DataCarModel';
import axios from 'axios';
import OrganizationSelect from '../../organizationSelect/OrganizationSelect';
import { PRIVILEGES } from '../../../constants/privileges';
import get from 'lodash.get';
import movieIcon from 'assets/img/movie.png';
import pdfIcon from 'assets/img/pdf-icon.png';
import UploadImages from '../../form/UploadImages';

const style = theme => ({
  infoText: {
    fontWeight: '300',
    margin: '10px 0 30px',
    textAlign: 'center'
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main
  },
  error: {
    color: 'red'
  },
  imageGrid: {
    padding: '20px'
  }
});

class VehicleForm extends React.Component {
  onMakeChange = () => {
    const { form } = this.props;
    form.change('vehicle.model', '');
  };

  lookupPlate = async () => {
    const { form } = this.props;
    const registration = form.getFieldState('vehicle.registration').value;
    const result = await axios('/api/licensePlate/' + registration);
    form.change('vehicle.model', result.data.model);
    form.change('vehicle.make', result.data.make);
    form.change('vehicle.vin', result.data.vin);
    form.change('vehicle.year', result.data.year);
  };

  render() {
    const { classes, form } = this.props;
    const make = get(form.getState().values, 'vehicle.make');
    const models = (groupedCarModels[make] || []).map(({ Model }) => ({
      value: Model,
      label: Model
    }));

    return (
      <div>
        <h4 className={classes.infoText}>Vehicle Details</h4>
        <Grid container spacing={16}>
          <Grid item xs={12} lg={6}>
            <Restricted privileges={[PRIVILEGES.ReadOrganisations]}>
              <OrganizationSelect />
            </Restricted>
            <Input
              name='vehicle.registration'
              label='Registration Plates'
              required
              validate={isRequired}
            />
            <Button
              color='rose'
              type='button'
              onClick={() => this.lookupPlate()}
            >
              Lookup
            </Button>
            <Select
              name='vehicle.make'
              label='Make'
              options={dataCarMake}
              onChange={this.onMakeChange}
              required
              validate={isRequired}
            />
            <Select
              name='vehicle.model'
              label='Model'
              options={models}
              disabled={models.length === 0}
              validate={isRequired}
              required
            />
            <Input name='vehicle.vin' label='Vin' />
            <Input
              name='vehicle.year'
              label='Year'
              validate={pipe(
                isPositiveNumber
              )}
            />
            <Input
              name='location'
              label='Vehicle Location'
              required
              validate={isRequired}
            />
          </Grid>
          <Grid item xs={12} lg={6} container direction='column'>
            <Grid item style={{marginBottom: '20px'}} >
              <Images name='images' progress={{ }}/>
            </Grid>
            <Grid item style={{marginBottom: '20px'}}>
              <UploadImages name='pdfs' image={pdfIcon}/>
            </Grid>
            <Grid item>
              <UploadImages name='videos' filetype='video/mp4' extension='mp4' description='Choose Videos' image={movieIcon} title='Damage Videos' />
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

VehicleForm.propTypes = {
  form: PropTypes.object
};

export default withStyles(style)(VehicleForm);
