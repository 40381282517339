import React, { useEffect } from "react";
import { Paper, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid/Grid";
import claimStore from "../../stores/claimStore";

function BodyshopAgreement(props) {

  const claimId = props.match.params.claimId

  useEffect(() => {
    const sendBodyshopLead = async () => {
      await claimStore.sendBodyshopLead(claimId);
    };
    sendBodyshopLead().catch(console.error);
  }, [claimId]);

  return (
    <Grid container>
      <Grid item sm={10}>
        <Paper style={{ padding: 20, margin: 30 }}>
          <Typography align="center" paragraph variant="h6">
            Looks like you are in need of a bodyshop.
          </Typography>
          <Typography align="center" variant="body1" >
            We will send your details to our partnered repairers, who will review your job request and come back if they are interested in taking it on!<br/>
            Best, The Axioma Team
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default BodyshopAgreement;
