import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../../App.css';
import userStore from '../../stores/userStore';

class Landing extends Component {
  componentDidMount() {
    if (userStore.isAuthenticated) {
      this.props.history.push('/dashboard');
    }
  }

  render() {
    return (
      <div className='landing'>
        <div className='dark-overlay landing-inner'>
          <div className='container'>
            <div className='row'>
              <div className='col-sm-7 text-left'>
                <Link className='btn btn-lg btn-light' to='/login'>
                  Login
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Landing;
