import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import claimStore from '../../stores/claimStore';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = {
  container: {
    width: '100%',
    position: 'relative'
  },
  small: {
    height: '80px',
    boxSizing: 'border-box',
    '& img': {
      height: '100%',
      width: 'auto'
    },
    padding: '8px',
    cursor: 'pointer'
  }
};

class QuoteFile extends Component {
  fetchFile = async fileId => {
    try {
      const data = await claimStore.getQuoteUrl(fileId);
      if (data.data) {
        this.downloadFile(data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  downloadFile = url => {
    const a = document.createElement('a');
    a.href = url;
    a.download = 'download.' + this.props.extension;
    a.click();
  };

  render() {
    const { classes, fileId } = this.props;

    return (
      <Grid container direction="column" className={classes.container}>
        <Grid item>
          <Grid container wrap="wrap">
            <Grid item key={fileId}>
              <div
                className={classes.small}
                onClick={() => this.fetchFile(fileId)}
              >
                <img src={this.props.image} alt={fileId} title={fileId}/>
              </div>
              <div/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

QuoteFile.propTypes = {
  fileId: PropTypes.string,
  filetype: PropTypes.string,
  extension: PropTypes.string
};

QuoteFile.defaultProps = {
  filetype: 'application/pdf',
  extension: 'pdf'
};

export default withStyles(styles)(QuoteFile);
