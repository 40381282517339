import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button, Dialog, Grid, Typography } from '@material-ui/core';
import { Send } from '@mui/icons-material';
import organizationsStore from '../../stores/organizationsStore';
import ErrorWrapper from '../common/ErrorWrapper';
import LoadingWrapper from '../common/LoadingWrapper';
import cx from 'classnames';

import {
  ORGANIZATION_TYPES,
  ORGANIZATION_TYPES_KEYS
} from '../../constants/organizationTypes';
import { USER_ROLES } from '../../constants/userRoles';
import { observer } from 'mobx-react';
import OrganizationInviteForm from './OrganizationInviteForm';
import OrganizationFnolCodeForm from './OrganizationFnolCodeForm';
import OrganizationDisableForm from './OrganizationDisableForm';
import OrganizationPaymentForm from './OrganizationPaymentForm';
import userStore from '../../stores/userStore';
import { PRIVILEGES } from '../../constants/privileges';

const styles = {
  card: {
    padding: '16px'
  },
  headerCard: {
    marginBottom: '16px'
  }
};

const dialogs = {
  invite: OrganizationInviteForm,
  fnol: OrganizationFnolCodeForm,
  payment: OrganizationPaymentForm,
  disableOrganization: OrganizationDisableForm
};

class Organizations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isError: false,
      dialog: null
    };
  }

  async componentDidMount() {
    await this.getOrganization();
  }

  getOrganization = async () => {
    const { id } = this.props.match.params;

    try {
      await organizationsStore.getOrganizationDetails(id);
    } catch (e) {
      this.setState({ isError: true });
    }

    this.setState({ loading: false });
  };

  refetch = async () => {
    this.setState({ isLoading: true, isError: false });
    await this.getOrganization();
  };

  openDialog = dialog => () => this.setState({ dialog });

  closeDialog = () => this.setState({ dialog: null });

  renderContent = () => {
    const organization = organizationsStore.currentOrganization;

    if (!organization) {
      return null;
    }

    const { classes } = this.props;
    const { dialog } = this.state;
    const DialogComponent = dialog ? dialogs[dialog] : null;
    const isFleet = organization.type === ORGANIZATION_TYPES_KEYS.FLEET;
    const isDisabled = organization.disabled;
    const paymentAccount = organization.payment?.stripeAccountId;
    const directPaymentEnabled = organization.payment?.directPaymentEnabled;
    const isRepairer = organization.type === ORGANIZATION_TYPES_KEYS.REPAIR_SHOP || organization.type === ORGANIZATION_TYPES_KEYS.REPAIR_NETWORK || organization.type === ORGANIZATION_TYPES_KEYS.SMART_REPAIRER;
    const canUpdateOrg = userStore.hasPrivilege(PRIVILEGES.SuperAdminUpdateOrgData);
    const canInviteUser = userStore.hasPrivilege(PRIVILEGES.ExecuteUserInvite);

    return (
      <div>
        <Paper className={cx(classes.card, classes.headerCard)}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="subtitle1">{`Organisation: ${
                organization.name
              }`}</Typography>
              <Typography>{`Type: ${
                ORGANIZATION_TYPES[organization.type]
              }`}</Typography>
              {isFleet && (
                <Typography>{`FNOL CODE: ${organization.fnol ||
                'none'}`}</Typography>
              )}
              {isDisabled && (
                <Typography color="error">Organisation Disabled {isDisabled}</Typography>
              )}
              {paymentAccount && (
                <Typography>Stripe Account: {paymentAccount}</Typography>
              )}
              {isRepairer && directPaymentEnabled && (
                <Typography>Direct Payment Enabled</Typography>

              )}
              {isRepairer && !directPaymentEnabled && (
                <Typography>Direct Payment Disabled</Typography>
              )}
            </Grid>
            <Grid item>
              <Grid container direction="column" alignItems="center">
                {isFleet && (
                  <Grid item>
                    <Button
                      color="secondary"
                      onClick={this.openDialog('fnol')}
                      data-cy="update-fnol"
                    >
                      Update Fnol Code
                    </Button>
                  </Grid>
                )}
                {canUpdateOrg && isRepairer && (
                  <Grid item>
                    <Button
                      color="secondary"
                      onClick={this.openDialog('payment')}
                      data-cy="update-payment"
                    >
                      Update Payment Details
                    </Button>
                  </Grid>
                )}
                {canUpdateOrg && (
                  <Grid item>
                    <Button
                      color="secondary"
                      onClick={this.openDialog('disableOrganization')}
                      data-cy="update-disabled"
                    >
                      Enable/Disable Organisation
                    </Button>
                  </Grid>
                )}
                {canInviteUser && (<Grid item>
                  <Button
                    color="secondary"
                    onClick={this.openDialog('invite')}
                    data-cy="invite-user"
                  >
                    <Send/>
                    Invite User
                  </Button>
                </Grid>)}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Paper className={classes.card}>
          <Typography variant="subtitle1">Users</Typography>
          <Table aria-label="organizations">
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>Role</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {organization.users.map(user => (
                <TableRow key={user._id}>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{USER_ROLES[user.role]}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
        <Dialog
          open={!!this.state.dialog}
          onClose={this.closeDialog}
          maxWidth="sm"
          fullWidth
        >
          {DialogComponent ? (
            <DialogComponent
              id={this.props.match.params.id}
              closeDialog={this.closeDialog}
            />
          ) : (
            <div/>
          )}
        </Dialog>
      </div>
    );
  };

  render() {
    return (
      <LoadingWrapper isLoading={this.state.loading}>
        <ErrorWrapper isError={this.state.isError} onRetry={this.refetch}>
          {this.renderContent()}
        </ErrorWrapper>
      </LoadingWrapper>
    );
  }
}

Organizations.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  })
};

export default withStyles(styles)(observer(Organizations));
