import React, { Component } from "react";
import Footer from "./components/layout/Footer";
import "./App.css";
import Main from "./components/layout/Main";
import Spinner from "./components/common/Spinner";
import claimStore from "./stores/claimStore";
import userStore from "./stores/userStore";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import Routes from "./routes";
import "mapbox-gl/dist/mapbox-gl.css";
import { SnackbarProvider } from "notistack";
import { CompatRouter } from "react-router-dom-v5-compat";

const theme = createTheme({
  palette: {
    primary: {
      extraLight: "#cecccc",
      // light: will be calculated from palette.primary.main,
      main: "#5E5F5F"
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: "#52ADCD",
      main: "#53AECE",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "white"
    },
    // error: will use the default color
    error: {
      main: "#FF0000"
    }
  }
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  async componentDidMount() {
    try {
      await userStore.restoreSession();
    } catch (e) {
      userStore.logoutUser("Error during restoring session");
    }

    this.setState({ loading: false });
  }

  render() {
    if (this.state.loading) {
      return <Spinner fullScreen />;
    }

    return (
      <CompatRouter>
        <MuiThemeProvider theme={theme}>
          <SnackbarProvider maxSnack={5} />
          <Main claimStore={claimStore} userStore={userStore}>
            <Routes />
          </Main>
          <Footer />
        </MuiThemeProvider>
      </CompatRouter>
    );
  }
}

export default App;
