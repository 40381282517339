import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Grid
} from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import Navbar from '../../components/layout/Navbar';
import Hidden from '@material-ui/core/Hidden';

class ApplicationBar extends Component {
  render() {
    return (
      <AppBar
        position='absolute'
        className={classNames(
          this.props.classes.appBar,
          this.props.open && this.props.classes.appBarShift
        )}
      >
        <Toolbar disableGutters={!this.props.open}>
          {this.props.isAuthenticated ? (
            <Hidden xsDown>
              <IconButton
                color='inherit'
                aria-label='Open drawer'
                onClick={this.props.onClick}
                className={classNames(
                  this.props.classes.menuButton,
                  this.props.open && this.props.classes.hide
                )}
              >
                <Menu />
              </IconButton>
            </Hidden>
          ) : (
            <Grid item sm={1} />
          )}
          <Grid container justifyContent='space-between'>
            <Grid item>
              <Hidden mdDown>
                <Typography variant='h5' color='secondary' noWrap>
                  Axioma
                </Typography>
              </Hidden>
            </Grid>
            <Grid item>
              <Navbar />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  }
}

ApplicationBar.propTypes = {
  classes: PropTypes.any,
  open: PropTypes.bool,
  onClick: PropTypes.func,
  isAuthenticated: PropTypes.bool
};

export default ApplicationBar;
