import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'react-final-form';
import Datetime from 'react-datetime';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

function DatePicker(props) {
    const { name, label, required, validate, type, ...others } = props;
    const placeholder = required ? `${label} (required)` : label;

    return (
      <Field
        name={name}
        validate={validate}
        {...others}
        render={({ input, meta }) => {
          const success = meta.touched && meta.valid;
          const error = meta.touched && !!meta.error;
          const onChange = event => {
            input.onChange({
              target: {
                value: event._d
              }
            });
          };

          return (
            <FormControl error={error} fullWidth>
              <Datetime
                {...input}
                onChange={onChange}
                inputProps={{ placeholder }}
                success={success}
                error={error}
              />
              {error && <FormHelperText>{meta.error}</FormHelperText>}
            </FormControl>
          );
        }}
      />
    );
}

DatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  validate: PropTypes.func
};

export default DatePicker;
