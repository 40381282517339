import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeftOutlined';
import ArrowRightIcon from '@material-ui/icons/ArrowRightOutlined';
import { IconButton } from '@material-ui/core';
import LoadingWrapper from '../common/LoadingWrapper';

const styles = {
  dialog: {
    position: 'relative',
    display: 'flex',
  },
  img: {
    display: 'flex',
    flex: 'auto'
  },
  arrow: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    '&:focus': {
      outline: 'none'
    }
  },
  left: {
    left: '8px'
  },
  right: {
    right: '8px'
  }
};

class ImagePreview extends Component {
  changePicture = direction => {
    const { previewId, imagesIds, setPreviewId } = this.props;
    const index = imagesIds.findIndex(id => id === previewId);
    const imagesCount = imagesIds.length;
    const newIndex = (index + imagesCount + direction) % imagesCount;

    setPreviewId(imagesIds[newIndex]);
  };

  next = () => {
    this.changePicture(1);
  };

  previous = () => {
    this.changePicture(-1);
  };

  render() {
    const { classes, previewId, fullImages, imagesIds } = this.props;
    const imageSrc = fullImages[previewId];

    return (
      <div className={classes.dialog} >
        <LoadingWrapper isLoading={!imageSrc}>
          <img className={classes.img} src={imageSrc} alt='' />
        </LoadingWrapper>

        {imagesIds.length > 1 && (
          <>
            <IconButton
              onClick={this.previous}
              className={`${classes.arrow} ${classes.left}`}
            >
              <ArrowLeftIcon fontSize='large' />
            </IconButton>
            <IconButton
              onClick={this.next}
              className={`${classes.arrow} ${classes.right}`}
            >
              <ArrowRightIcon fontSize='large' />
            </IconButton>
          </>
        )}
      </div>
    );
  }
}

ImagePreview.propTypes = {
  setPreviewId: PropTypes.func.isRequired,
  previewId: PropTypes.string.isRequired,
  fullImages: PropTypes.shape({}).isRequired,
  imagesIds: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default withStyles(styles)(ImagePreview);
