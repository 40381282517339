import React from "react";
import UserWelcome from "./UserWelcome";
import { Grid } from "@material-ui/core";
import Button from "components/theme/CustomButtons/Button.jsx";
import { Link } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = {
  margin: {
    marginTop: "50px"
  }
};

function HomePage(props) {
  const { classes } = props;

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      className={classes.margin}
    >
      <Grid item>
        <UserWelcome />
      </Grid>
      <Grid item className={classes.margin}>
        <Button
          to="/dashboard"
          component={Link}
          color="primary"
          round
          size="lg"
        >
          Dashboard
        </Button>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(HomePage);
