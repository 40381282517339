import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import userStore from '../../stores/userStore';

const Restricted = ({ privileges, children, bannedContent }) => {
  if (userStore.isAllowed(privileges)) {
    return children;
  }

  return bannedContent;
};

Restricted.propTypes = {
  privileges: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node.isRequired,
  bannedContent: PropTypes.node
};

Restricted.defaultProps = {
  privileges: [],
  bannedContent: null
};

export default observer(Restricted);
