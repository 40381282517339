import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Input from '../form/Input';
import { isRequired } from '../../validation/validation';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { Form } from 'react-final-form';
import organizationsStore from '../../stores/organizationsStore';
import get from 'lodash.get';
import { Grid, Typography } from '@material-ui/core';
import Radio from '../form/Radio';

const OrganizationPaymentForm = ({ closeDialog, id }) => {
  const [error, setError] = useState(null);

  const updatePayment = async values => {
    try {
      await organizationsStore.updatePayment(id, {
        directPaymentEnabled: values.directPaymentEnabled === 'true',
        stripeAccountId: values.stripeAccountId
      });

      closeDialog();
    } catch (err) {
      const error = get(err, 'response.data.message', err.message);
      setError(error);
    }
  };

  const isEnabled = () => {
    return organizationsStore.currentOrganization?.payment?.directPaymentEnabled;
  };

  return (
    <Form
      onSubmit={updatePayment}
      initialValues={{
        directPaymentEnabled: `${!isEnabled()}`,
        stripeAccountId: organizationsStore.currentOrganization?.payment?.stripeAccountId
      }}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <DialogTitle>Update Stripe Payment Account</DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item>
                <Input
                  name="stripeAccountId"
                  label="Stripe Account ID"
                  type="input"
                  required
                  validate={isRequired}
                />
              </Grid>
              <Grid container>
                <Grid item>
                  <Radio
                    name="directPaymentEnabled"
                    value="true"
                    id="disableTrue"
                    label="Enable"
                  />
                </Grid>
                <Grid item>
                  <Radio
                    name="directPaymentEnabled"
                    value="false"
                    id="disableFalse"
                    label="Disable"
                  />
                </Grid>
              </Grid>
            </Grid>
            {error && (
              <Typography color="error" align="center">
                {error}
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="secondary">
              Send
            </Button>
          </DialogActions>
        </form>
      )}
    />
  );
};

OrganizationPaymentForm.propTypes = {
  id: PropTypes.string,
  closeDialog: PropTypes.func
};

export default OrganizationPaymentForm;
