import React from 'react';
import { Redirect } from 'react-router-dom';
import { observer } from 'mobx-react';
import userStore from '../../stores/userStore';
import { CompatRoute } from "react-router-dom-v5-compat";

let PrivateRoute = ({
  component: Component = null,
  render: Render = null,
  privileges = [],
  ...rest
}) => {
  return (
    <CompatRoute
      {...rest}
      render={props => {
        if (!userStore.isAuthenticated) {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location }
              }}
            />
          );
        }

        if (userStore.isAllowed(privileges)) {
          return Render ? Render(props) : <Component {...props} />;
        }

        return 'Not permitted';
      }}
    />
  );
};

PrivateRoute.propTypes = {};

PrivateRoute = observer(PrivateRoute);
export default PrivateRoute;
