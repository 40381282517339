import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { AppBar, Tab, Tabs } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import claimStore from "../../../stores/claimStore";
import Claims from "../claims/Claims";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import { CLAIM_STATUS_KEYS } from "../../../constants/claimStatus";

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper
  }
});

function ClaimTabs (props) {
  const [status, setStatus] = React.useState(CLAIM_STATUS_KEYS.Bidding);
  const [claims, setClaims] = React.useState([]);

  React.useEffect(() => {
    async function getFilteredClaims() {
      const claims = await claimStore.getFilteredClaims(status);
      setClaims(claims);
    }

    getFilteredClaims().catch(console.error);
  }, [status]);

  const handleChange = (event, status) => {
    setStatus(status);
  };

  const { classes } = props;
  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={status}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Accepted" data-tip data-tooltip-id="accepted-hint" data-place="bottom"
               value={CLAIM_STATUS_KEYS.Bidding} />
          <Tab label="Awaiting Repair" data-tip data-tooltip-id="awaitingrepair-hint" data-place="bottom"
               value={CLAIM_STATUS_KEYS.AwaitingPickup} />
          <Tab label="In Repair" data-tip data-tooltip-id="inrepair-hint" data-place="bottom"
               value={CLAIM_STATUS_KEYS.InRepair} />
          <Tab label="Repaired" data-tip data-tooltip-id="repaired-hint" data-place="bottom"
               value={CLAIM_STATUS_KEYS.Finished} />
          <Tab label="Paid" data-tip data-tooltip-id="paid-hint" data-place="bottom" value={CLAIM_STATUS_KEYS.Paid} />
        </Tabs>
        <Tooltip id="accepted-hint" type="info" place="right">These are the jobs you’ve accepted.<br />Your client has
          been notified
          and will be in touch to arrange repair.</Tooltip>
        <Tooltip id="awaitingrepair-hint" type="info" place="right">These are the cars that are awaiting repair
          arrangements.</Tooltip>
        <Tooltip id="inrepair-hint" type="info" place="right">These are the cars that you have picked up and are
          currently working
          on.</Tooltip>
        <Tooltip id="repaired-hint" type="info" place="right">These are your finished jobs which are awaiting
          payment.</Tooltip>
        <Tooltip id="paid-hint" type="info" place="right">These are your finished jobs, payment of which has been
          settled.</Tooltip>
      </AppBar>
      <TabContainer><Claims claims={claims} /></TabContainer>
    </div>
  );
}

ClaimTabs.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ClaimTabs);
