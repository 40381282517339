import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import GridContainer from 'components/theme/Grid/GridContainer.jsx';
import GridItem from 'components/theme/Grid/GridItem.jsx';
import Button from 'components/theme/CustomButtons/Button.jsx';
import { Card, CardContent, Typography } from '@material-ui/core';
import CardFooter from 'components/theme/Card/CardFooter.jsx';
import Input from 'components/form/Input';
import { email, isRequired, pipe } from '../../validation/validation';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import { Email, Lock } from '@mui/icons-material';
import FormHeader from './FormHeader';

function LoginForm(props) {
  const { onSubmit, error, logoutMessage } = props;
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        email: '',
        password: ''
      }}
      render={({ handleSubmit }) => (
        <GridContainer justifyContent='center'>
          <GridItem xs={12} sm={6} md={4} lg={3}>
            <form onSubmit={handleSubmit}>
              <Card>
                <FormHeader title='Login' />
                <CardContent>
                  <Input
                    id='email'
                    label='Email'
                    name='email'
                    required
                    validate={pipe(
                      isRequired,
                      email
                    )}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <Email />
                        </InputAdornment>
                      )
                    }}
                  />

                  <Input
                    id='standard-password-input'
                    label='Password'
                    name='password'
                    type='password'
                    required
                    validate={isRequired}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <Lock />
                        </InputAdornment>
                      )
                    }}
                  />

                  {logoutMessage && <Paper>{logoutMessage}</Paper>}
                  {error && (
                    <Typography
                      color='error'
                      align='center'
                      data-cy='login-error'
                    >
                      {error}
                    </Typography>
                  )}
                </CardContent>
                <CardFooter>
                  <Button
                    id={'login-button'}
                    color='rose'
                    size='lg'
                    block
                    type='submit'
                  >
                    Let's Go
                  </Button>
                </CardFooter>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
      )}
    />
  );
}

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  logoutMessage: PropTypes.string
};

export default LoginForm;
