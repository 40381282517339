export const CLAIM_STATUS_KEYS = {
  Fnol: "fnol",
  Registered: "registered",
  AwaitingQuoteAuthorisation: "awaiting-quote-authorisation",
  InMarketplace: "in-marketplace",
  Bidding: "bidding",
  AwaitingBidAuthorisation: "awaiting-bid-authorisation",
  InRepair: "in-repair",
  AwaitingPickup: "awaiting-pickup",
  Finished: "finished",
  Archived: "archived",
  Paid: "paid",
  Completed: "completed"
};

export const CLAIM_STATUS = {
  [CLAIM_STATUS_KEYS.Fnol]: "Fnol",
  [CLAIM_STATUS_KEYS.Registered]: "Registered",
  [CLAIM_STATUS_KEYS.AwaitingQuoteAuthorisation]: "Awaiting Authorisation",
  [CLAIM_STATUS_KEYS.InMarketplace]: "In Marketplace",
  [CLAIM_STATUS_KEYS.Bidding]: "Bidding",
  [CLAIM_STATUS_KEYS.AwaitingBidAuthorisation]: "Awaiting Bid Authorisation",
  [CLAIM_STATUS_KEYS.AwaitingPickup]: "Awaiting Repair",
  [CLAIM_STATUS_KEYS.InRepair]: "In Repair",
  [CLAIM_STATUS_KEYS.Finished]: "Finished",
  [CLAIM_STATUS_KEYS.Archived]: "Archived",
  [CLAIM_STATUS_KEYS.Paid]: "Paid",
  [CLAIM_STATUS_KEYS.Completed]: "Completed"
};

export const SERVICE_REQUIRED = {
  "repair": "Repair",
  "assistance": "Roadside Assistance",
  "assessment": "Desktop Damage Assessment",
  "bodyshop": "Bodyshop Repair",
  "other": "Other"
};

export const CLAIM_STATUS_ORDER = [
  CLAIM_STATUS_KEYS.Registered,
  CLAIM_STATUS_KEYS.AwaitingQuoteAuthorisation,
  CLAIM_STATUS_KEYS.InMarketplace,
  CLAIM_STATUS_KEYS.Bidding,
  CLAIM_STATUS_KEYS.AwaitingPickup,
  CLAIM_STATUS_KEYS.InRepair,
  CLAIM_STATUS_KEYS.Finished,
  CLAIM_STATUS_KEYS.Paid
];

export const FNOL_CLAIM_STATUS_ORDER = [
  CLAIM_STATUS_KEYS.Fnol,
  CLAIM_STATUS_KEYS.AwaitingQuoteAuthorisation,
  CLAIM_STATUS_KEYS.InMarketplace,
  CLAIM_STATUS_KEYS.Bidding,
  CLAIM_STATUS_KEYS.AwaitingPickup,
  CLAIM_STATUS_KEYS.InRepair,
  CLAIM_STATUS_KEYS.Finished,
  CLAIM_STATUS_KEYS.Paid
];
export const BODYSHOP_CLAIM_STATUS_ORDER = [
  CLAIM_STATUS_KEYS.InMarketplace,
  CLAIM_STATUS_KEYS.Bidding,
  CLAIM_STATUS_KEYS.AwaitingBidAuthorisation,
  CLAIM_STATUS_KEYS.AwaitingPickup,
  CLAIM_STATUS_KEYS.InRepair,
  CLAIM_STATUS_KEYS.Finished,
  CLAIM_STATUS_KEYS.Paid
];