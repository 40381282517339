import React, { Component } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Paper from "@material-ui/core/Paper";
import garagesStore from "../../stores/garagesStore";
import claimStore from "../../stores/claimStore";
import ErrorWrapper from "../common/ErrorWrapper";
import LoadingWrapper from "../common/LoadingWrapper";
import { observer } from "mobx-react";
import GridContainer from "../theme/Grid/GridContainer";
import GridItem from "../theme/Grid/GridItem";
import { Button, Grid, Typography } from "@material-ui/core";
import ReactMapGL, { Marker, Popup } from "react-map-gl";
import mapboxgl from "mapbox-gl";
import mapboxsdk from "@mapbox/mapbox-sdk/services/geocoding";
import { Build, DriveEta, EventAvailable, Schedule } from "@mui/icons-material";
import { CLAIM_STATUS_KEYS } from "../../constants/claimStatus";
import { Link } from "react-router-dom";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import userStore from "../../stores/userStore";
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
const accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const mapboxClient = mapboxsdk({ accessToken });
const styles = {
  header: {
    marginBottom: "8px",
    padding: "16px"
  },
  row: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.1)"
    }
  }
};


const CustomPopup = ({ index, marker, closePopup, metadata }) => {
  return (
    <Popup
      latitude={marker.props.latitude}
      longitude={marker.props.longitude}
      onClose={closePopup}
      closeButton={true}
      closeOnClick={false}
      offsetTop={-10}
    >
      {metadata.type === "claim" ? (
        <Button
          size="small"
          color="primary"
          component={Link}
          to={`/marketplace/claims/${metadata.id}`}
        ><p>{metadata.label}</p>
        </Button>) : (
        <Button
          size="small"
          color="primary"
          component={Link}
          to={`/garages/${metadata.id}`}
        ><p>{metadata.label}</p></Button>
      )}
    </Popup>
  );
};

const claimFilterMap = {
  "fnol": CLAIM_STATUS_KEYS.Fnol,
  "quoted": CLAIM_STATUS_KEYS.AwaitingQuoteAuthorisation,
  "approved": CLAIM_STATUS_KEYS.InMarketplace,
  "allocated": CLAIM_STATUS_KEYS.Bidding,
  "awaiting-repair": CLAIM_STATUS_KEYS.AwaitingPickup
};
const claimTypeMap = { "retail": true, "b2b": false };

class RepairMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isError: false,
      latitude: 51.6330,
      longitude: -0.1500,
      zoom: 8,
      markers: [],
      showPopup: false,
      togglePopup: false,
      selectedIndex: null,
      popupMetadata: {},
      claimFilter: "none",
      claimTypeFilter: "all",
      garages: []
    };
  }


  async markersFromFilters(baseIndex) {
    const claims = this.state.claimFilter === "none" ? [] : await claimStore.getFilteredClaims(claimFilterMap[this.state.claimFilter]);
    const typeFilteredClaims = this.state.claimTypeFilter === "all" ? claims : claims.filter(claim => claim.retail === claimTypeMap[this.state.claimTypeFilter]);
    return await this.getClaimMarkers(typeFilteredClaims, "error", baseIndex);
  }

  async componentDidMount() {
    this.garages = await garagesStore.getGarages();
    await this.getGarages();
    const markers = await this.markersFromFilters(this.state.markers.length);
    this.setState({ markers: this.state.markers.concat(markers) });
  }

  async componentDidUpdate(prevProps, prevState) {
    if ((prevState.claimFilter !== this.state.claimFilter) || (prevState.claimTypeFilter !== this.state.claimTypeFilter)) {
      this.closePopup();
      await this.getGarages();
      const markers = await this.markersFromFilters(this.state.markers.length);
      this.setState({ markers: this.state.markers.concat(markers) });
    }
  }

  getCoordinates = async (postcode) => {
    try {
      const response = await mapboxClient.forwardGeocode({
        query: postcode,
        types: ["postcode"],
        limit: 1
      })
        .send();
      return response.body.features[0].center;
    } catch (e) {
      console.log(e);
      return [0, 0];
    }
  };

  setSelectedMarker = (selectedIndex, popupMetadata) => {
    this.setState({ selectedIndex, popupMetadata });
  };

  closePopup = () => {
    this.setSelectedMarker(null);
  };

  openPopup = (index, popupMetadata) => {
    this.setSelectedMarker(index, popupMetadata);
  };

  getClaimMarkers = async (claims, color, baseIndex) => {
    return await Promise.all(claims.map(async (cl, index) => {
      const coords = await this.getCoordinates(cl.location);
      if (cl.status === CLAIM_STATUS_KEYS.AwaitingPickup && cl.pickup.date) {
        return (
          <Marker
            key={cl._id}
            index={baseIndex + index}
            latitude={parseFloat(coords[1])}
            longitude={parseFloat(coords[0])}
          >
            <EventAvailable color='secondary' onMouseOver={() => this.openPopup(baseIndex + index, {
              type: "claim",
              label: cl.vehicle.registration,
              id: cl._id
            })} />
          </Marker>
        );
      } else if (cl.status === CLAIM_STATUS_KEYS.AwaitingPickup && cl.pickup.availableDates && cl.pickup.availableDates.length > 0) {
        return (
          <Marker
            key={cl._id}
            index={baseIndex + index}
            latitude={parseFloat(coords[1])}
            longitude={parseFloat(coords[0])}
          >
            <Schedule color={color} onMouseOver={() => this.openPopup(baseIndex + index, {
              type: "claim",
              label: cl.vehicle.registration,
              id: cl._id
            })} />
          </Marker>
        );
      } else {
        return (
          <Marker
            key={cl._id}
            index={baseIndex + index}
            latitude={parseFloat(coords[1])}
            longitude={parseFloat(coords[0])}
          >
            <DriveEta color={color} onMouseOver={() => this.openPopup(baseIndex + index, {
              type: "claim",
              label: cl.vehicle.registration,
              id: cl._id
            })} />
          </Marker>
        );
      }

    }));
  };

  getGarages = async () => {
    try {
      const markers = await Promise.all(this.garages.filter(g => g.postcode != null).map(async (ga, index) => {
        const coords = await this.getCoordinates(ga.postcode);
        return (
          <Marker
            key={ga.name}
            index={index}
            latitude={parseFloat(coords[1])}
            longitude={parseFloat(coords[0])}
          >
            <Build onMouseOver={() => this.openPopup(index, { type: "garage", label: ga.name, id: ga._id })} />
          </Marker>
        );
      }));
      this.setState({ markers: markers });
    } catch (e) {
      this.setState({ isError: true });
    }

    this.setState({ loading: false });
  };

  refetch = async () => {
    this.setState({ isLoading: true, isError: false });
    await this.getGarages();
  };

  handleClaimFilter = async (event, claimFilter) => {
    if (claimFilter != null) {
      this.setState({ claimFilter });
    }
  };

  handleClaimTypeFilter = async (event, claimTypeFilter) => {
    if (claimTypeFilter != null) {
      this.setState({ claimTypeFilter });
    }
  };

  render() {
    const { classes } = this.props;
    const { claimTypeFilter, claimFilter } = this.state;
    const hasManyOrganizations = userStore.hasManyOrganizations;
    const viewport = { latitude: this.state.latitude, longitude: this.state.longitude, zoom: this.state.zoom };
    return (
      <LoadingWrapper isLoading={this.state.loading}>
        <ErrorWrapper isError={this.state.isError} onRetry={this.refetch}>
          <div>
            <Paper className={classes.header}>
              <GridContainer justifyContent="space-between" align="center">
                <GridItem>
                  <Typography component="h1" variant="h5">
                    Repair Map
                  </Typography>
                </GridItem>
              </GridContainer>
            </Paper>

            <Paper>
              <Grid container justifyContent="center">

                <grid container>
                  <grid item xs={12} sm={6}>
                    State:
                    <ToggleButtonGroup
                      color="primary"
                      value={claimFilter}
                      exclusive
                      onChange={this.handleClaimFilter}
                    >
                      {hasManyOrganizations && (<ToggleButton value="fnol">Fnol</ToggleButton>)}
                      {hasManyOrganizations && (<ToggleButton value="quoted">Quoted</ToggleButton>)}
                      <ToggleButton value="approved">Approved</ToggleButton>
                      <ToggleButton value="allocated">Allocated</ToggleButton>
                      <ToggleButton value="awaiting-repair">Awaiting Repair</ToggleButton>
                      <ToggleButton value="none">None</ToggleButton>
                    </ToggleButtonGroup>
                  </grid>
                  {hasManyOrganizations && (
                    <grid item xs={12} sm={6}>
                      Type:
                      <ToggleButtonGroup
                        color="primary"
                        value={claimTypeFilter}
                        exclusive
                        onChange={this.handleClaimTypeFilter}
                      >
                        <ToggleButton value="all">All</ToggleButton>
                        <ToggleButton value="retail">Retail</ToggleButton>
                        <ToggleButton value="b2b">B2B</ToggleButton>
                      </ToggleButtonGroup>
                    </grid>
                  )}
                </grid>

                <Grid item sm={12}> <ReactMapGL
                  initialViewState={viewport}
                  mapStyle="mapbox://styles/mapbox/streets-v11"
                  style={{ width: "90vw", height: "80vh" }}
                >
                  {this.state.markers}
                  {
                    this.state.selectedIndex !== null &&
                    <CustomPopup
                      index={this.state.selectedIndex}
                      marker={this.state.markers[this.state.selectedIndex]}
                      closePopup={this.closePopup}
                      metadata={this.state.popupMetadata}
                    />
                  }
                </ReactMapGL></Grid></Grid>
            </Paper>
          </div>
        </ErrorWrapper>
      </LoadingWrapper>
    );
  }
}

RepairMap.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  })
};

export default withStyles(styles)(observer(RepairMap));
