import React from 'react';
import PropTypes from 'prop-types';

import {
  Paper
} from '@material-ui/core';

import withStyles from '@material-ui/core/styles/withStyles';
import DamageList from '../../form/DamageList';

const styles = theme => ({
  infoText: {
    fontWeight: '300',
    margin: '10px 0 30px',
    textAlign: 'center'
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`
  }
});

class WizardDamageForm extends React.Component {

  render() {
    const { classes } = this.props;
    return (
      <div>
        <h4 className={classes.infoText}>Damage</h4>
        <Paper className={classes.paper}>
          <DamageList name = "damage" />
        </Paper>
      </div>
    );
  };
}

WizardDamageForm.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(WizardDamageForm);
