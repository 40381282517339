import React from "react";
import BodyshopClaimTabs from "./BodyshopClaimTabs";
import { observer } from "mobx-react";

function BodyshopClaimsContainer() {
  return (
    <BodyshopClaimTabs />
  );
}

export default observer(BodyshopClaimsContainer);
