import React, { useCallback } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import userStore from "../../../stores/userStore";
import { isRequired } from "../../../validation/validation";
import Select from "../../form/Select";
import GridContainer from "../../theme/Grid/GridContainer";
import GridItem from "../../theme/Grid/GridItem";
import { Dialog, DialogActions, DialogContent } from "@material-ui/core";
import Button from "../../theme/CustomButtons/Button";
import Input from "../../form/Input";
import { Form } from "react-final-form";
import { PRIVILEGES } from "../../../constants/privileges";
import DialogTitle from "@material-ui/core/DialogTitle";
import get from "lodash.get";

function GarageSelect(props) {
  const [garages, setGarages] = React.useState([]);
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const organizationId = getOrganizationId();

  const getGarages = useCallback(async () => {
    if (organizationId) {
      try {
        const { data } = await axios.get("/api/garages", {
          params: {
            organisation: organizationId
          }
        });

        setGarages(data);
      } catch (e) {
        console.log("error", e);
      }
    }
  }, [organizationId]);

  React.useEffect(() => {
    getGarages().catch(console.error);
  }, [getGarages]);

  function getOrganizationId() {
    if (userStore.currentUser.organizationsIds.length === 1) {
      return userStore.currentUser.organizationsIds[0];
    }

    return props.organization;
  }

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const addGarage = async ({ garage }) => {
    try {
      const response = await axios.post("/api/garages", {
        name: garage,
        organisation: organizationId
      });
      await getGarages();

      props.setGarage(response.data._id);
      toggleModal();
    } catch (e) {
      const errorMessage = get(e, "response.data.message", e.message);

      return {
        garage: errorMessage
      };
    }
  };

  const multipleGaragesAllowed = getMultipleGaragesAllowed();

  function getMultipleGaragesAllowed() {
    const organizationId = getOrganizationId();
    const organization = userStore.currentUser.permissions.find(
      p => p.organisationId === organizationId
    );

    if (organization) {
      return organization.privileges.includes(PRIVILEGES.CreateGarage);
    }

    return false;
  }

  const garagesList = garages.map(g => ({
    value: g._id,
    label: g.name
  }));

  if (!multipleGaragesAllowed) {
    return null;
  }

  return (
    <>
      <GridContainer alignItems="center">
        <GridItem xs={12} md={6}>
          <Select
            id="garage"
            name="garage"
            label="Garage"
            options={garagesList}
            required
            disabled={garagesList.length === 0}
            validate={isRequired}
          />
        </GridItem>
        <GridItem xs={12} md={6}>
          <Button color="rose" onClick={toggleModal}>
            Add garage
          </Button>
        </GridItem>
      </GridContainer>
      <Dialog
        open={isModalOpen}
        onClose={toggleModal}
        maxWidth="sm"
        fullWidth
      >
        {isModalOpen && (
          <Form
            onSubmit={addGarage}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <DialogTitle>Add Garage</DialogTitle>
                <DialogContent>
                  <Input
                    label="Garage name"
                    name="garage"
                    required
                    validate={isRequired}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={toggleModal}>Cancel</Button>
                  <Button type="submit" color="rose">
                    Add
                  </Button>
                </DialogActions>
              </form>
            )}
          />
        )}
      </Dialog>
    </>
  );
}

GarageSelect.propTypes = {
  organization: PropTypes.string,
  setGarage: PropTypes.func
};

export default GarageSelect;
