export const NOTIFICATION_TYPES_KEYS = {
  ClaimRegistered: 'CLAIM_REGISTERED',
  ClaimUpdated: 'CLAIM_UPDATED',
  PickupUpdated: 'PICKUP_UPDATED',
  PickupOverride: 'PICKUP_OVERRIDE',
  PickupRejected: 'PICKUP_REJECTED',
  ClaimStatusUpdated: 'CLAIM_STATUS_UPDATED',
  ClaimImagesUpdated: 'CLAIM_IMAGES_UPDATED',
  ClaimFileAdded: 'CLAIM_FILE_ADDED',
  ClaimFileRemoved: 'CLAIM_FILE_REMOVED',
  ClaimCommentAdded: 'CLAIM_COMMENT_ADDED',
  ClaimRepairerChanged: 'CLAIM_REPAIRER_CHANGED',
  ClaimBookingPaid: 'CLAIM_BOOKING_PAID',
  ClaimLocationChanged: 'CLAIM_LOCATION_CHANGED'
};

export const NOTIFICATION_MESSAGES = {
  [NOTIFICATION_TYPES_KEYS.ClaimRegistered]: 'New claim added',
  [NOTIFICATION_TYPES_KEYS.ClaimUpdated]: 'Claim updated',
  [NOTIFICATION_TYPES_KEYS.PickupUpdated]: 'Repair date selected by a driver',
  [NOTIFICATION_TYPES_KEYS.PickupOverride]: 'Repair date amended',
  [NOTIFICATION_TYPES_KEYS.PickupRejected]: 'Repair dates rejected by a driver',
  [NOTIFICATION_TYPES_KEYS.ClaimStatusUpdated]: 'Claim status changed',
  [NOTIFICATION_TYPES_KEYS.ClaimImagesUpdated]: 'Claim image deleted',
  [NOTIFICATION_TYPES_KEYS.ClaimFileAdded]: 'New files added to claim',
  [NOTIFICATION_TYPES_KEYS.ClaimFileRemoved]: 'Claim file was removed',
  [NOTIFICATION_TYPES_KEYS.ClaimCommentAdded]: 'New comment added to claim',
  [NOTIFICATION_TYPES_KEYS.ClaimRepairerChanged]: 'Repairer changed',
  [NOTIFICATION_TYPES_KEYS.ClaimBookingPaid]: 'Booked repair date',
  [NOTIFICATION_TYPES_KEYS.ClaimLocationChanged]: 'Repair location changed'
};
