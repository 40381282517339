import React from "react";
import PropTypes from "prop-types";
import { Radio as MURadio, FormControlLabel } from "@material-ui/core";
import { Field } from "react-final-form";

function Radio(props) {
  const { name, label, value, disabled } = props;

  return (
    <Field
      name={name}
      type="radio"
      value={value}
      render={({ input }) => {
        return (
          <FormControlLabel
            control={<MURadio {...input} disabled={disabled} />}
            label={label}
          />
        );
      }}
    />
  );
}

Radio.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

export default Radio;
