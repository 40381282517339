import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormWizard from 'components/form/Wizard/FormWizard';
import arrayMutators from 'final-form-arrays';
import PictureGuideForm from './PictureGuideForm';
import FNOLStep from './FNOLStep';
import claimStore from '../../stores/claimStore';

class FnolWizardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      error: null,
      claimSaved: false
    };
    const params = new URLSearchParams(props.location.search);
    this.token = params.get('fnolToken');
  }

  steps = [
    { stepName: 'Report', stepComponent: FNOLStep, stepId: 'fnol' },
    {
      stepName: 'Picture Guide',
      stepComponent: PictureGuideForm,
      stepId: 'picture'
    }
  ];

  onSubmit = async values => {
    try {
      const { images, ...claimData } = values;
      if (!this.state.claimSaved) {
        const createdClaim = await claimStore.addFnolClaim(claimData);
        this.setState({ claimSaved: true, claimId: createdClaim._id });
      }
      const claimId = this.state.claimId;
      if (images && images.length) {
        await claimStore.addFnolFiles(claimId, images, claimData.secret);
      }
      this.setState({ success: true, error: null });
      this.props.history.push('/fnol/complete');
    } catch (error) {
      console.log(error);
      this.setState({ success: false, error: error.message });
      return false;
    }
  };

  render() {
    const { error, success } = this.state;
    return (
      <FormWizard
        formProps={{
          mutators: {
            ...arrayMutators
          }
        }}
        title='Car Damage Report'
        subtitle=''
        initialValues={this.state.initialValues}
        steps={this.steps}
        onSubmit={this.onSubmit}
        error={error}
        success={success}
      />
    );
  }
}

FnolWizardContainer.propTypes = {
  history: PropTypes.object,
  claimId: PropTypes.string
};

export default FnolWizardContainer;
