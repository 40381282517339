import { Component } from "react";
import claimStore from "../../stores/claimStore";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import React from "react";
import GridContainer from "../theme/Grid/GridContainer";
import GridItem from "../theme/Grid/GridItem";
import CardBody from "../theme/Card/CardBody";
import Card from "../theme/Card/Card";
import Button from "../theme/CustomButtons/Button";
import { getStatusConfigDetails } from "./claimStatusConfig";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import withStyles from "@material-ui/core/styles/withStyles";

import {
  successColor,
  dangerColor,
  warningColor,
  tooltip,
  cardTitle
} from "assets/jss/material-dashboard-pro-react.jsx";

import hoverCardStyle from "assets/jss/material-dashboard-pro-react/hoverCardStyle.jsx";
import { CLAIM_STATUS_KEYS } from "../../constants/claimStatus";
import Typography from "@material-ui/core/Typography";
import userStore from "../../stores/userStore";
import moment from "moment";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import ClaimCard from "./ClaimCard";
import labelsStore from "../../stores/labelsStore";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { PRIVILEGES } from "../../constants/privileges";

const dashboardStyle = {
  ...hoverCardStyle,
  tooltip,
  cardTitle: {
    ...cardTitle,
    marginTop: "0px",
    marginBottom: "3px"
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  cardProductTitle: {
    ...cardTitle,
    marginTop: "0px",
    marginBottom: "3px",
    textAlign: "center"
  },
  cardCategory: {
    color: "#999999",
    fontSize: "14px",
    paddingTop: "10px",
    marginBottom: "0",
    marginTop: "0",
    margin: "0"
  },
  cardProductDescription: {
    textAlign: "center",
    color: "#999999"
  },
  cardImage: {
    height: "292px",
    maxWidth: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    background: "#FFF",
    border: "1px solid #eee",
    boxShadow:
      "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
    borderRadius: "6px",

    "& img": {
      height: "auto",
      maxHeight: "100%",
      flex: "0 1",
      maxWidth: "100%",
      boxShadow: "none",
      transform: "translateY(1px)"
    }
  },
  stats: {
    color: "#999999",
    fontSize: "12px",
    lineHeight: "22px",
    display: "inline-flex",
    "& svg": {
      position: "relative",
      top: "4px",
      width: "16px",
      height: "16px",
      marginRight: "3px"
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      position: "relative",
      top: "4px",
      fontSize: "16px",
      marginRight: "3px"
    }
  },
  productStats: {
    paddingTop: "7px",
    paddingBottom: "7px",
    margin: "0"
  },
  successText: {
    color: successColor
  },
  dangerText: {
    color: dangerColor
  },
  warningText: {
    color: warningColor
  },
  statusText: {
    color: "#408336"
  },
  upArrowCardCategory: {
    width: 14,
    height: 14
  },
  underChartIcons: {
    width: "17px",
    height: "17px"
  },
  price: {
    color: "inherit",
    "& h4": {
      marginBottom: "0px",
      marginTop: "0px"
    }
  },
  defaultIcon: {
    padding: "0 4px"
  }
};

class FilteredClaims extends Component {

  constructor(props) {
    super(props);
    this.filter = this.props.match.params.status;
    this.state = {
      filteredClaims: [],
      retailFilter: "all",
      overdueFilter: "all",
      collectionFilter: "all",
      photosFilter: "all",
      labelsFilter: [],
      labels: []
    };
  }

  async componentDidMount() {
    const filteredClaims = await claimStore.getFilteredClaims(this.props.match.params.status);
    if (userStore.isAllowed([PRIVILEGES.SuperAdminLabels])) {
      const labels = await labelsStore.getAllLabels()
      await this.setState({ labels });
    }
    await this.setState({ filteredClaims });
  }

  handleRetailChange = (event, retailFilter) => {
    if (retailFilter !== null) {
      this.setState({ retailFilter });
    }
  };

  handleOverdueChange = (event, overdueFilter) => {
    if (overdueFilter !== null) {
      this.setState({ overdueFilter });
    }
  };

  handleCollectionChange = (event, collectionFilter) => {
    if (collectionFilter !== null) {
      this.setState({ collectionFilter });
    }
  };

  handlePhotosChange = (event, photosFilter) => {
    if (photosFilter !== null) {
      this.setState({ photosFilter });
    }
  };

  async handleArchiving(id) {
    confirmAlert({
      title: "Archive claim",
      message: "Are you sure?",
      buttons: [
        {
          label: "Yes",
          async onClick() {
            await claimStore.archiveClaim(id);
            await claimStore.getClaim(id);
          }
        },
        {
          label: "No",
          onClick: () => ""
        }
      ]
    });
  }

  async handleUnarchiving(id) {
    await claimStore.unarchiveClaim(id);
    await claimStore.getClaim(id);
  }

  onLabelChange = (event, value) => {
    this.setState({ labelsFilter: value });
  }

  render() {
    const { classes } = this.props;
    const { retailFilter, overdueFilter, collectionFilter, photosFilter, labelsFilter, labels } = this.state;
    let { filteredClaims } = this.state;
    const filterConfig = getStatusConfigDetails(this.filter);
    const isRegistered = this.filter === CLAIM_STATUS_KEYS.Registered;
    const isArchived = this.filter === CLAIM_STATUS_KEYS.Archived;
    const isFNOL = this.filter === CLAIM_STATUS_KEYS.Fnol;
    const hasManyOrganizations = userStore.hasManyOrganizations;

    if (!filteredClaims > 0) {
      return null;
    }

    function applyRetailFilter(c) {
      if (retailFilter === "all") {
        return true;
      }
      if (retailFilter === "retail") {
        return c.retail;
      } else {
        return !c.retail;
      }
    }

    function applyOverdueFilter(c) {
      if (overdueFilter === "all") {
        return true;
      }
      const pickupDay = moment(c.pickup?.date).startOf("day");
      const today = moment().startOf("day");
      const isOverdue = today.isAfter(pickupDay);
      if (overdueFilter === "true") {
        return isOverdue;
      } else {
        return !isOverdue;
      }
    }

    function applyCollectionFilter(c) {
      if (collectionFilter === "all") {
        return true;
      }
      if (collectionFilter === "true") {
        return c.pickup?.date !== undefined && c.pickup?.date !== null;
      } else {
        return c.pickup?.date === undefined || c.pickup?.date === null;
      }
    }

    function applyPhotosFilter(c) {
      if (photosFilter === "all") {
        return true;
      }
      if (photosFilter === "true") {
        return c.images.length > 0;
      } else {
        return c.images.length === 0;
      }
    }

    function applyLabelFilter(c) {
      return labelsFilter.length === 0 || c.metadata?.labels?.some(label => labelsFilter.some(l => label === l._id))
    }

    filteredClaims = filteredClaims.filter(applyRetailFilter).filter(applyOverdueFilter).filter(applyCollectionFilter).filter(applyPhotosFilter).filter(applyLabelFilter);
    const sortedClaims = filteredClaims.sort((a, b) => {
      const earliestDateA = a.pickup?.availableDates?.slice().sort()[0]
      const earliestDateB = b.pickup?.availableDates?.slice().sort()[0]
      const dateToCompareA = a.pickup?.date ? a.pickup?.date: earliestDateA
      const dateToCompareB = b.pickup?.date ? b.pickup?.date: earliestDateB
      if (dateToCompareA === undefined || dateToCompareA === null) {
        return 1;
      }
      if (dateToCompareB === undefined || dateToCompareB === null) {
        return -1;
      }
      return new Date(dateToCompareA) - new Date(dateToCompareB);
    } );

    const claimCards = sortedClaims.map(claim => {
      return (<ClaimCard
        claim={claim}
        key={claim._id}
        handleArchiving={this.handleArchiving}
        handleUnarchiving={this.handleUnarchiving}
        isArchived={isArchived}
        isRegistered={isRegistered}
        isFNOL={isFNOL}
        classes={classes}
      />);
    });

    return (
      <div>
        <GridContainer justifyContent="center">
          <GridItem xs={12} sm={4} md={3} lg={3}>
            <Card pricing>
              <CardBody pricing>
                <h6 className={classes.cardCategory}>{filterConfig.name}</h6>
                <div className={classes.icon}>
                  <h3 className={`${classes.cardTitle} ${classes.marginTop30}`}>
                    {filteredClaims.length}
                  </h3>
                </div>
                <Button
                  to={"/dashboard"}
                  component={Link}
                  round
                  color="primary"
                >
                  Back
                </Button>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {hasManyOrganizations && (
          <GridContainer>
            <GridItem xs={12} md={4} lg={2}>
              <Typography>Origin:</Typography>
              <ToggleButtonGroup
                color="primary"
                value={retailFilter}
                exclusive
                onChange={this.handleRetailChange}
              >
                <ToggleButton value="all">All</ToggleButton>
                <ToggleButton value="retail">Retail</ToggleButton>
                <ToggleButton value="b2b">B2B</ToggleButton>
              </ToggleButtonGroup>
            </GridItem>
            <GridItem xs={12} md={4} lg={2}>
              <Typography>Pickup:</Typography>
              <ToggleButtonGroup
                color="primary"
                value={overdueFilter}
                exclusive
                onChange={this.handleOverdueChange}
              >
                <ToggleButton value="all">All</ToggleButton>
                <ToggleButton value="false">On Time</ToggleButton>
                <ToggleButton value="true">Overdue</ToggleButton>
              </ToggleButtonGroup>
            </GridItem>
            <GridItem xs={12} md={4} lg={2}>
              <Typography>Collection:</Typography>
              <ToggleButtonGroup
                color="primary"
                value={collectionFilter}
                exclusive
                onChange={this.handleCollectionChange}
              >
                <ToggleButton value="all">All</ToggleButton>
                <ToggleButton value="true">Confirmed</ToggleButton>
                <ToggleButton value="false">Not Confirmed</ToggleButton>
              </ToggleButtonGroup>
            </GridItem>
            <GridItem xs={12} md={4} lg={2}>
              <Typography>Photos:</Typography>
              <ToggleButtonGroup
                color="primary"
                value={photosFilter}
                exclusive
                onChange={this.handlePhotosChange}
              >
                <ToggleButton value="all">All</ToggleButton>
                <ToggleButton value="true">Has Photos</ToggleButton>
                <ToggleButton value="false">No Photos</ToggleButton>
              </ToggleButtonGroup>
            </GridItem>
            <GridItem xs={12} md={4} lg={2}>
              <Typography>Labels:</Typography>
              <Autocomplete
                multiple
                id="label-filters"
                options={labels}
                getOptionLabel={(option) => option.name}
                onChange={this.onLabelChange}
                isOptionEqualToValue={(option, value) => option.name === value.name}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    placeholder="Add label"
                  />
                )}
              />
            </GridItem>
          </GridContainer>
        )}
        <GridContainer>{claimCards}</GridContainer>
      </div>
    );
  }
}

FilteredClaims = observer(FilteredClaims);
export default withStyles(dashboardStyle)(FilteredClaims);
