import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Checkbox from "components/form/Checkbox";
import DatePicker from "components/form/DatePicker";
import GridContainer from "components/theme/Grid/GridContainer.jsx";
import GridItem from "components/theme/Grid/GridItem.jsx";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  }
};

class AccidentForm extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer justifyContent="center">
          <GridItem xs={12} sm={12}>
            <h4 className={classes.infoText}>
              Accident Details (optional)
            </h4>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={10}>
            <DatePicker name="time" label="Time of Accident" />
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={10}>
            <Checkbox name="fault" label="Claimant at fault?" />
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={10}>
            <Checkbox name="witnesses" label="Witnesses" />
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={10}>
            <Checkbox name="policereport" label="Police Report" />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(style)(AccidentForm);
