import React from 'react';
import PropTypes from 'prop-types';
import Spinner from './Spinner';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = {
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%'
  }
};

const LoadingWrapper = ({
  isLoading,
  children,
  classes
}) => {
  if (isLoading) {
    return (
      <div className={classes.wrapper}>
        <Spinner />
      </div>
    );
  }
  return children;
};

LoadingWrapper.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.node
};

export default withStyles(styles)(LoadingWrapper);
