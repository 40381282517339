import React from 'react';
import PropTypes from 'prop-types';

import { Form } from 'react-final-form';
import Input from 'components/form/Input';
import {
  isRequired,
  pipe,
  minLength,
  maxLength
} from '../../validation/validation';
import GridContainer from 'components/theme/Grid/GridContainer.jsx';
import GridItem from 'components/theme/Grid/GridItem.jsx';
import Button from 'components/theme/CustomButtons/Button.jsx';
import { Card, CardContent, Typography } from '@material-ui/core';
import CardFooter from 'components/theme/Card/CardFooter.jsx';
import FormHeader from './FormHeader';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Person as User, Lock } from '@material-ui/icons';

function RegisterForm(props) {
  const { error } = props;

  const validatePassword = (value, values) => {
    if (value !== values.password) {
      return 'Passwords must be the same';
    }
  };

  return (
    <Form
      onSubmit={props.onSubmit}
      initialValues={{
        name: '',
        password: '',
        password2: ''
      }}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div>
            <GridContainer justifyContent='center'>
              <GridItem xs={12} sm={6} md={4} lg={3}>
                <Card>
                  <FormHeader title='Register' />
                  <CardContent>
                    <Input
                      id='name'
                      label='Name'
                      name='name'
                      required
                      validate={pipe(
                        isRequired,
                        minLength(2),
                        maxLength(30)
                      )}
                      inputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <User />
                          </InputAdornment>
                        )
                      }}
                    />
                    <Input
                      id='password'
                      label='Password'
                      name='password'
                      type='password'
                      required
                      validate={pipe(
                        isRequired,
                        minLength(6),
                        maxLength(30)
                      )}
                      inputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <Lock />
                          </InputAdornment>
                        )
                      }}
                    />
                    <Input
                      id='password2'
                      label='Confirm Password'
                      name='password2'
                      type='password'
                      required
                      validate={pipe(
                        isRequired,
                        validatePassword
                      )}
                      inputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <Lock />
                          </InputAdornment>
                        )
                      }}
                    />{' '}
                    {error && (
                      <Typography color='error' align='center'>
                        {error}
                      </Typography>
                    )}
                  </CardContent>
                  <CardFooter>
                    <Button color='rose' size='lg' block type='submit'>
                      Let's Go
                    </Button>
                  </CardFooter>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </form>
      )}
    />
  );
}

RegisterForm.propTypes = {
  error: PropTypes.string
};

export default RegisterForm;
