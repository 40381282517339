import React, { Component } from 'react';
import claimStore from '../../../stores/claimStore';
import LoadingWrapper from '../../common/LoadingWrapper';
import withStyles from '@material-ui/core/styles/withStyles';
import { Paper, Button, Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import Images from 'components/form/Images';
import { getNewFiles, getFilesToDelete } from '../../../utils/files';
import { CLAIM_STATUS_KEYS } from '../../../constants/claimStatus';
import { enqueueSnackbar } from 'notistack';

const style = theme => ({
  paper: {
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px`
  }
});

class MarketplaceClaimImages extends Component {
  state = {
    isLoading: true,
    error: '',
    failedImageList: [],
    progress: {},
    errors: [],
    open: []
  };

  async componentDidMount() {
    await claimStore.getClaim(this.props.match.params.claimId);
    this.setState({ isLoading: false, images: claimStore.currentClaim.thumbnails });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (!!this.state.error) {enqueueSnackbar(this.state.error.toString(), { variant: 'error' });}
    if (!!this.state.errors) {this.state.errors.forEach(e => enqueueSnackbar(e.toString(), { variant: 'error' }));}
  }

  progressUpdateFn(imageName, progressNr) {
    const { progress } = this.state;
    if (imageName) {
      progress[imageName] = progressNr;
      this.setState({ progress });
    }
  }

  handleSubmit = async ({ images }) => {
    try {
      const claimId = claimStore.currentClaim._id;
      this.setState({ images,  error: '', errors: [] })
      const newImages = getNewFiles(images);
      const imagesToProcess = this.state.failedImageList.length > 0 ? this.state.failedImageList : newImages;
      const imagesToDelete = getFilesToDelete(
        images,
        claimStore.currentClaim.thumbnails
      );
      let failedImageList = [];
      let errors = [];
      const promises = imagesToProcess.map(image =>  async () => {
        try {
          await claimStore.addClaimFile(claimId, image, this);
        } catch (error) {
          console.log(error)
          failedImageList = failedImageList.concat(image);
          const progress = this.state.progress[image.name]? this.state.progress[image.name] : 0;
          errors = errors.concat(`${image.name} upload failed with error: ${error} at ${progress.toFixed(2)}%`);
        }
      })

      for(const promise of promises) {
         await promise();
      }
      if (failedImageList.length > 0) {
        this.setState({ failedImageList, errors });
      } else {
        await claimStore.deleteFiles(claimId, imagesToDelete);
        this.redirect(claimId);
      }
    } catch (e) {
      this.setState({ error: e.message });
    }
  };

  redirect = (claimId) => {
    this.props.history.push(`/marketplace/claims/${claimId}`);
  };

  render() {
    if (this.state.isLoading || !claimStore.currentClaim) {
      return <LoadingWrapper isLoading/>;
    }

    if (claimStore.currentClaim.status in [CLAIM_STATUS_KEYS.Registered, CLAIM_STATUS_KEYS.Fnol]) {
      return 'Not permitted';
    }

    const { classes } = this.props;
    const { failedImageList, progress, images } = this.state;
    return (
      <Form
        onSubmit={this.handleSubmit}
        initialValues={{
          images: failedImageList.length > 0 ? failedImageList : images
        }}
        mutators={{
          ...arrayMutators
        }}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Paper className={classes.paper}>
                <Images name={'images'} progress={progress}/>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Button
                      type="submit"
                      variant="contained"
                      color="secondary"
                      disabled={submitting}
                    >
                      {submitting ? 'Processing...' : (failedImageList.length > 0 ? 'Retry' : 'Update Claim')}
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </form>
          );
        }}
      />
    );
  }
}

export default withStyles(style)(observer(MarketplaceClaimImages));
