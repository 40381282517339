import React, { Component } from 'react';
import claimStore from '../../../stores/claimStore';
import { observer } from 'mobx-react';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  Button,
  Card,
  Grid, Divider
} from '@material-ui/core';

import { Send } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import GridContainer from '../../theme/Grid/GridContainer';
import GridItem from '../../theme/Grid/GridItem';
import CardFooter from '../../theme/Card/CardFooter';
import CardBody from '../../theme/Card/CardBody';
import ClaimImageGallery from '../../claimImageGallery/ClaimImageGallery';
import ClaimComments from '../../claimComments/ClaimComments';
import Typography from '@material-ui/core/Typography';
import { CLAIM_STATUS_KEYS, SERVICE_REQUIRED } from '../../../constants/claimStatus';
import ErrorWrapper from '../../common/ErrorWrapper';
import LoadingWrapper from '../../common/LoadingWrapper';
import Input from 'components/form/Input';
import Radio from 'components/form/Radio';
import { PRIVILEGES } from '../../../constants/privileges';
import OrganizationSelect from '../../organizationSelect/OrganizationSelect';
import Restricted from '../../restricted/Restricted';
import { ORGANIZATION_TYPES_KEYS } from '../../../constants/organizationTypes';
import ClaimFiles from '../../claimFiles/ClaimFiles';
import GarageSelect from './GarageSelect';
import Moment from 'react-moment';
import pdfIcon from '../../../assets/img/pdf-icon.png';
import movieIcon from '../../../assets/img/movie.png';
import quoteStore from '../../../stores/quoteStore';
import ArrangePickup from '../../stateActionButtons/ArrangePickup';
import StartRepair from '../../stateActionButtons/StartRepair';
import FinishRepair from '../../stateActionButtons/FinishRepair';
import BidBodyshop from '../../stateActionButtons/BidBodyshop';
import ModifyBodyshopQuote from "../bodyshop/modifyQuote/ModifyBodyshopQuote";
import AuthoriseBid from "../../authorise/AuthoriseBid";
import RejectBid from "../../authorise/RejectBid";
class ClaimDetailsContainer extends Component {
  state = {
    isLoading: true,
    isError: false,
    isEditable: false,
    pending: false
  };

  async componentDidMount() {
    await this.getClaim();
  }

  getClaim = async () => {
    try {
      await claimStore.getClaim(this.props.match.params.claimId);
      if (claimStore.currentClaim.quoteRef) {
        const quote = await quoteStore.getRepairerQuote(claimStore.currentClaim.quoteRef)
        this.setState({ quote });
      }
      this.setState({
        isLoading: false,
        isEditable: claimStore.currentClaim.status === CLAIM_STATUS_KEYS.InMarketplace
      });
    } catch (e) {
      this.setState({ isError: true, isLoading: false });
    }
  };

  refetch = async () => {
    this.setState({ isLoading: true, isError: false });
    await this.getClaim();
  };

  addBid = async values => {
    this.setState({ pending: true });
    const payload = {
      damageDetails: values.damages,
      organisation: values.organisation,
      garage: values.garage
    };

    await claimStore.addBid(payload);
    this.props.history.push('/marketplace/bid/created');
  };

  getDamageFormData = (damages = []) => damages.map(d => ({ ...d }));

  getAvailableDates = (pickup) => {
    if (pickup.availableDates) {
      const pickupDates = pickup.availableDates.slice().sort();
      return pickupDates.map(d => <GridItem><Moment format="DD/MM/YYYY">{d}</Moment></GridItem>);
    }
  };

  renderForm = () => {
    const { isEditable, isLoading, pending } = this.state;
    const currentClaim = claimStore.currentClaim;

    if (isLoading || !currentClaim) {
      return null;
    }

    let initialValues;
    let damages;
    const repairerCost = this.state.quote ? this.state.quote.costRepairer: 0
    if (isEditable) {
      damages = this.getDamageFormData(currentClaim.damage);
      initialValues = {
        cost: repairerCost,
      };
    } else {
      const bid = currentClaim.bid.find(b => !!b.winning) || {};
      damages = this.getDamageFormData(bid.damageDetails);
      initialValues = {
        cost: repairerCost,
      };
    }

    initialValues.location = currentClaim.location;
    initialValues.damages = damages;

    const {
      vehicle: { make, model, year, registration, vin },
      images,
      pdfs,
      thumbnails,
      location,
      serviceRequired,
      serviceDescription,
      driver,
      videos,
      pickup
    } = currentClaim;

    return (
      <Form
        onSubmit={this.addBid}
        initialValues={initialValues}
        mutators={{
          ...arrayMutators
        }}
        render={({ handleSubmit, form, values }) => (
            <GridContainer>
              <GridItem sm={4}>
                <form noValidate>
                <Card
                  style={{
                    padding: 20,
                    margin: 10,
                    textAlign: 'center'
                  }}
                >
                  <ClaimImageGallery
                    imagesIds={images}
                    thumbnails={thumbnails}
                  />
                  <ClaimFiles data={pdfs} image={pdfIcon}/>
                  <ClaimFiles data={videos} image={movieIcon} filetype="video/mp4" extension="mp4"/>
                  {currentClaim.status !== CLAIM_STATUS_KEYS.InMarketplace && (
                    <>
                      <Button
                        component={Link}
                        to={`/marketplace/claimImages/${
                          this.props.match.params.claimId
                        }`}
                        color="secondary"
                      >
                        Edit Images
                      </Button>
                      <Button
                        component={Link}
                        to={`/marketplace/claimVideos/${
                          this.props.match.params.claimId
                        }`}
                        color="secondary"
                      >
                        Edit Videos
                      </Button>
                      <Button
                        component={Link}
                        to={`/marketplace/claimDocuments/${
                          this.props.match.params.claimId
                        }`}
                        color="secondary"
                      >
                        Upload Document
                      </Button>
                    </>
                  )}
                </Card>
                <Paper style={{ padding: 20, margin: 10 }}>
                  <Typography component="h1" variant="h5">
                    {make} {model} {year}
                  </Typography>
                  <Divider/>
                  <Typography>Plate number: {registration}</Typography>
                  <Typography>VIN: {vin}</Typography>
                </Paper>
                <Paper style={{ padding: 20, margin: 10 }} id="pickup-details">
                  <Typography variant="h5">Repair details</Typography>
                  <Divider/>
                  {pickup && pickup.date && !pickup.contactRequired && (<Typography variant={'h6'} color={'secondary'}>
                    Confirmed Repair Date:<br/>
                    <Moment format="DD/MM/YY">
                      {pickup.date}
                    </Moment>
                  </Typography>)}
                  {pickup && !pickup.date && !pickup.contactRequired && pickup.availableDates.length > 0 && (
                    <Typography color={'primary'}>
                      Proposed Repair Dates:
                      {this.getAvailableDates(pickup)}
                    </Typography>)}
                  {pickup && pickup.contactRequired && (<Typography variant={'h6'} color="error">
                    Repair dates rejected by a driver, please contact directly.
                  </Typography>)}
                  {currentClaim.status !== CLAIM_STATUS_KEYS.InMarketplace &&
                  <div>
                    {driver && driver.name && (<Typography>
                      Driver: {driver.name}
                    </Typography>)}
                    {driver && driver.phoneNumber && (<Typography>
                      Driver Phone Number: {driver.phoneNumber}
                    </Typography>)}
                    {driver && driver.email && (<Typography>
                      Driver Email: {driver.email}
                    </Typography>)}
                  </div>}
                  {!!location && <Typography> Location: {location}</Typography>}
                  {driver && driver.fullAddress && <Typography> Full Address: {driver.fullAddress}</Typography>}
                </Paper>
                <Paper style={{ padding: 20, margin: 10 }} id="claim-details">
                  <Typography component="h1" variant="h5">
                    Claim Details
                  </Typography>
                  <Divider/>
                  {serviceRequired &&
                  <Typography component="p">
                    Service
                    Required: {serviceRequired !== 'other' ? SERVICE_REQUIRED[serviceRequired] : serviceDescription}
                  </Typography>
                  }
                </Paper>
                </form>
              </GridItem>
              <GridItem sm={8}>
                {damages.length >0 &&(
                <Paper style={{ padding: 20, margin: 10 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Part</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <FieldArray name="damages">
                        {({ fields }) =>
                          fields.map((name, index) => {
                            const damage = damages[index];
                            const fieldName = `${name}.action`;

                            return (
                              <TableRow key={damage._id}>
                                <TableCell>{damage.part}</TableCell>
                                <TableCell padding="checkbox">
                                  <Grid container direction="row">
                                    <Grid item>
                                      <Radio
                                        name={fieldName}
                                        value="repair"
                                        label="Repair"
                                        disabled={!isEditable}
                                      />
                                    </Grid>
                                    <Grid item>
                                      <Radio
                                        name={fieldName}
                                        value="replace"
                                        label="Replace"
                                        disabled={!isEditable}
                                      />
                                    </Grid>
                                  </Grid>
                                </TableCell>
                              </TableRow>
                            );
                          })
                        }
                      </FieldArray>
                    </TableBody>
                  </Table>
                </Paper>
                )}
                <Card style={{ padding: 20, margin: 10 }}>
                  <CardBody>
                    <GridContainer>
                      <GridItem sm={2}>
                        <Input
                          name="cost"
                          label="Quote £"
                          id="standard-number"
                          disabled={true}
                        />
                      </GridItem>
                      <GridItem sm={3}>
                        <Input
                          name="location"
                          label="Repair location"
                          id="standard-number"
                          disabled
                        />
                      </GridItem>
                    </GridContainer>
                    {isEditable && currentClaim.serviceRequired !== "bodyshop" && (
                      <GridContainer direction="column">
                        <GridItem sm={6}>
                          <Restricted
                            privileges={[PRIVILEGES.ReadOrganisations]}
                          >
                            <OrganizationSelect
                              types={[
                                ORGANIZATION_TYPES_KEYS.REPAIR_NETWORK,
                                ORGANIZATION_TYPES_KEYS.REPAIR_SHOP,
                                ORGANIZATION_TYPES_KEYS.SMART_REPAIRER
                              ]}
                            />
                          </Restricted>
                        </GridItem>
                      </GridContainer>
                    )}
                  </CardBody>
                  <CardFooter>
                    {currentClaim.status === CLAIM_STATUS_KEYS.InMarketplace && currentClaim.serviceRequired !== "bodyshop" && (
                      <div id={'in-marketplace'}>
                          <GarageSelect
                            organization={values.organisation}
                            setGarage={value => form.change('garage', value)}
                          />
                        <Button color="secondary" onClick={handleSubmit} disabled={pending}>
                          <Send/>
                          Accept
                        </Button>
                      </div>
                    )}
                    {currentClaim.status === CLAIM_STATUS_KEYS.InMarketplace && currentClaim.serviceRequired === "bodyshop" && (
                      <div id={"bidding"}>
                        <GridItem sm={12}>
                          <BidBodyshop history={this.props.history} pickUp={values.pickUp} damage={values.damage} organization={values.organisation} successHandler={this.refetch}/>
                        </GridItem>
                      </div>
                    )}
                    {currentClaim.status === CLAIM_STATUS_KEYS.Bidding && currentClaim.serviceRequired !== "bodyshop"&& (
                      <div id={"bidding"}>
                        <GridItem sm={12}>
                          <ArrangePickup history={this.props.history} pickUp={values.pickUp}/>
                        </GridItem>
                      </div>
                    )}
                    {currentClaim.status === CLAIM_STATUS_KEYS.Bidding && currentClaim.serviceRequired === "bodyshop" && (
                      <div id={"bidding"}>
                        <GridItem sm={12}>
                          <ModifyBodyshopQuote history={this.props.history} successHandler={this.refetch}>
                          </ModifyBodyshopQuote>
                        </GridItem>
                      </div>
                    )}
                    {currentClaim.status === CLAIM_STATUS_KEYS.AwaitingBidAuthorisation && currentClaim.serviceRequired === "bodyshop" && (
                      <div id={"awaiting-bid-authorisation"}>
                        <GridItem sm={12}>
                          <ModifyBodyshopQuote history={this.props.history} successHandler={this.refetch}>
                          </ModifyBodyshopQuote>
                          <AuthoriseBid successHandler={this.refetch}/>
                          <RejectBid successHandler={this.refetch}/>
                        </GridItem>
                      </div>
                    )}
                    {currentClaim.status === CLAIM_STATUS_KEYS.AwaitingPickup && (
                      <div id={'awaiting-pickup'}>
                        <GridItem sm={12}>
                          <StartRepair history={this.props.history}/>
                        </GridItem>
                      </div>
                    )}
                    {currentClaim.status === CLAIM_STATUS_KEYS.InRepair && (
                      <div id={'in-repair'}>
                        <GridItem sm={12}>
                          <FinishRepair history={this.props.history}/>
                        </GridItem>
                      </div>
                    )}
                    {this.state.error && (
                      <Typography
                        color='error'
                        align='left'
                        data-cy='login-error'
                      >
                        {this.state.error}
                      </Typography>
                    )}
                  </CardFooter>
                </Card>
                <Paper style={{ padding: 20, margin: 10 }}>
                  <ClaimComments
                    comments={currentClaim.comments}
                    claimId={currentClaim._id}
                  />
                </Paper>
              </GridItem>
            </GridContainer>
        )}
      />
    );
  };

  render() {
    return (
      <LoadingWrapper isLoading={this.state.isLoading}>
        <ErrorWrapper isError={this.state.isError} onRetry={this.refetch}>
          {this.renderForm()}
        </ErrorWrapper>
      </LoadingWrapper>
    );
  }
}

ClaimDetailsContainer = observer(ClaimDetailsContainer);
export default withRouter(ClaimDetailsContainer);
