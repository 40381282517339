import React, { Component } from 'react';
import claimStore from '../../../stores/claimStore';
import LoadingWrapper from '../../common/LoadingWrapper';
import withStyles from '@material-ui/core/styles/withStyles';
import { Paper, Button, Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { getFilesToDelete, getNewFiles } from 'utils/files';
import { CLAIM_STATUS_KEYS } from '../../../constants/claimStatus';
import pdfIcon from '../../../assets/img/pdf-icon.png';
import UploadImages from '../../form/UploadImages';

const style = theme => ({
  paper: {
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px`
  }
});

class MarketplaceClaimPdfs extends Component {
  state = {
    pdfs: [],
    isLoading: true,
    error: ''
  };

  async componentDidMount() {
    await claimStore.getClaim(this.props.match.params.claimId);
    const pdfs = await claimStore.getFilesData(claimStore.currentClaim.pdfs);
    this.setState({ isLoading: false, pdfs });
  }

  handleSubmit = async ({ pdfs }) => {
    try {
      const claimId = claimStore.currentClaim._id;
      const newFiles = getNewFiles(pdfs);
      const filesToDelete = getFilesToDelete(pdfs, this.state.pdfs);
      await claimStore.addClaimFiles(claimId, newFiles);
      await claimStore.deleteFiles(claimId, filesToDelete);
      this.redirect();
    } catch (e) {
      this.setState({ error: e.message });
    }
  };

  redirect = () => {
    this.props.history.push('/marketplace/claims');
  };

  render() {
    if (this.state.isLoading || !claimStore.currentClaim) {
      return <LoadingWrapper isLoading />;
    }

    if (claimStore.currentClaim.status === CLAIM_STATUS_KEYS.Registered) {
      return 'Not permitted';
    }

    const { classes } = this.props;
    const { error, pdfs } = this.state;

    return (
      <Form
        onSubmit={this.handleSubmit}
        initialValues={{
          pdfs: [...pdfs]
        }}
        mutators={{
          ...arrayMutators
        }}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Paper className={classes.paper}>
                <UploadImages name='pdfs' image={pdfIcon}/>
                <Grid container justifyContent='flex-end'>
                  <Grid item>
                    <Button
                      type='submit'
                      variant='contained'
                      color='secondary'
                      disabled={submitting}
                    >
                      {submitting ? 'Processing...' : 'Update Claim'}
                    </Button>
                  </Grid>
                </Grid>
                {!!error && (
                  <Typography align='center' color='error'>
                    {error}
                  </Typography>
                )}
              </Paper>
            </form>
          );
        }}
      />
    );
  }
}

export default withStyles(style)(observer(MarketplaceClaimPdfs));
