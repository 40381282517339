import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import carDefaultIcon from "assets/img/defaultCar.png";
import claimStore from "../../stores/claimStore";
import { THUMBNAILS_SIZE } from "../../constants/images";

class ClaimImage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      src: carDefaultIcon,
      fetched: false
    };
    this.onScroll = this.onScroll.bind(this);
  }

  componentDidMount = async()=> {
    if (this.props.imageId !== "") {
      window.addEventListener("scroll", this.onScroll, true);
      await this.onScroll()
    }
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll, true);
  }
  componentDidUpdate(prevProps) {
    const { imageId } = this.props;
    if (imageId && imageId !== prevProps.imageId) {
      this.fetchImage(imageId);
    }
    if (this.state.fetched) {
      window.removeEventListener("scroll", this.onScroll, true);
    }
  }

  isComponentVisible = () => {
    const { top, bottom } = this.viewElement.getBoundingClientRect();
    const { innerHeight } = window;
    return top < innerHeight && bottom >= 0;
  };

  onScroll = async() => {
    const { imageId } = this.props;
    if (this.isComponentVisible() && imageId && imageId !== "" &&!this.state.fetched) {
      await this.fetchImage(this.props.imageId);
    }
  };

  fetchImage = async imageId => {
    const images = await claimStore.getFilesData([imageId], this.props.width);
    if (images.length) {
      this.setState({ src: images[0].data, fetched: true });
    }
  };

  render() {
    const { className, style } = this.props;
    let setRef = (el) => {
      this.viewElement = el;
    };
    return (
        <img ref={setRef} src={this.state.src} className={className} loading="lazy" style={style} alt="" />
    );
  }
}

ClaimImage.propTypes = {
  imageId: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.number,
  style: PropTypes.object
};

ClaimImage.defaultProps = {
  width: THUMBNAILS_SIZE.medium
};

export default ClaimImage;
