import React from 'react';
import { withStyles, Button, Dialog, Typography } from '@material-ui/core';
import cx from 'classnames';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

const styles = theme => ({
  submitButton: {
    display: 'block',
    fontSize: '16px',
    width: '50%',
    maxWidth: '600px',
    height: '40px',
    margin: '0 auto',
    backgroundColor: '#4caf50',
    boxShadow: '0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #ffb9f6',
    borderRadius: '4px',
    color: '#fff',
    fontWeight: '600',
    cursor: 'pointer',
    transition: 'all 100ms ease-in-out',
    willChange: 'transform, background-color, box-shadow',
    '&:active': {
      boxShadow: '0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #e298d8'
    },
    '&:hover': {
      color: '#4caf50'
    },
    '&:disabled': {
      opacity: '0.5',
      cursor: 'default',
      backgroundColor: theme.palette.action.disabledBackground,
      boxShadow: 'none'
    }
  }
});

const SubmitButton = ({
                        processing,
                        error,
                        children,
                        disabled,
                        classes,
                        className,
                        onClick,
                        handleOpen,
                        handleClose,
                        open
                      }) => (
  <>
    <Button
      variant="contained"
      className={cx(classes.submitButton, className)}
      type="submit"
      disabled={processing || disabled}
      onClick={handleOpen}
    >
      {processing ? 'Processing...' : children}
    </Button>
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Approve Quote</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.distance}>
          Thanks for accepting our quote!<br/><br/>
          Please confirm that you wish to proceed with the repair.<br/>
          We will now work to arrange the best supplier to carry out your repair and you will soon receive an email with the repairer’s availability.<br/>
          Once you choose a date you will be asked to pay a non-refundable £38 booking fee to secure your booking. The fee will be taken off your final bill.<br/><br/>
          We are able to offer competitive pricing without compromising on quality by providing our repudiate with flexible workloads. Our lead times are currently roughly 14 days.
        </DialogContentText>
        {error && (
          <Typography
            color="error"
            align="left"
            data-cy="login-error"
          >
            {error}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onClick} color="primary" name="approve-quote-modal" disabled={processing || disabled}>
          Approve Quote
        </Button>
      </DialogActions>
    </Dialog>
  </>
);

export default withStyles(styles)(SubmitButton);
