import React from "react";
import { Switch } from "react-router-dom";

import Landing from "./components/layout/Landing";
import Register from "./components/auth/Register";
import Login from "./components/auth/Login";
import PrivateRoute from "./components/common/PrivateRoute";
import ClaimsContainer from "./components/marketplace/claims/ClaimsContainer";
import ClaimDetailsContainer from "./components/marketplace/claims/ClaimDetailsContainer";
import ClaimActionsContainer from "./components/marketplace/explorer/ClaimActionsContainer";
import ActionsContainer from "./components/marketplace/explorer/ActionsContainer";
import BidCreated from "./components/marketplace/bids/BidCreated";
import Admin from "./components/admin/Admin";
import UserProfile from "./components/user/UserProfile";
import NewClaimFormContainer from "./components/claims/NewClaimFormContainer";
import HomePage from "./components/homePage/HomePage";
import MarketplaceClaimImages from "./components/marketplace/claims/MarketplaceClaimImages";
import UserUpdate from "./components/user/UserUpdate";
import ModifyClaim from "./components/dashboard/modifyClaim/ModifyClaim";
import RepairerHomePage from "./components/repairerHomePage/RepairerHomePage";
import InMarketplaceClaimsContainer from "./components/marketplace/claims/InMarketplaceClaimsContainer";
import FnolWizardContainer from "./components/fnol/FnolWizardContainer";
import NewOrganization from "./components/newOrganization/NewOrganization";
import Organizations from "./components/organizations/Organizations";
import OrganizationDetails from "./components/organizationDetails/OrganizationDetails";
import Labels from "./components/labels/Labels";
import InviteAdmin from "./components/inviteAdmin/InviteAdmin";
import { PRIVILEGES } from "./constants/privileges";
import MarketplaceClaimPdfs from "./components/marketplace/claims/MarketplaceClaimPdfs";
import GarageList from "components/garages/GarageList";
import RepairMap from "components/garages/RepairMap";
import GarageDetails from "components/garages/GarageDetails";
import CreateGarage from "components/garages/CreateGarage";
import Claim from "components/dashboard/Claim";
import PickUp from "./components/pickupDate/PickUp";
import RepairDateSelected from "./components/pickupDate/RepairDateSelected";
import FnolComplete from "./components/fnol/FnolComplete";
import MarketplaceClaimVideos from "./components/marketplace/claims/MarketplaceClaimVideos";
import QuotePage from "./components/quotePage/QuotePage";
import BodyshopQuotePage from "./components/quotePage/BodyshopQuotePage";
import RepairDateRejected from "./components/pickupDate/RepairDateRejected";
import { CompatRoute } from "react-router-dom-v5-compat";
import DashboardCards from "./components/dashboard/DashboardCards";
import FilteredClaims from "./components/dashboard/FilteredClaims";
import BodyshopAgreement from "./components/bodyshopAgreement/BodyshopAgreement";
import CreateLabel from "./components/labels/CreateLabel";
import BodyshopClaimsContainer from "./components/marketplace/bodyshop/BodyshopClaimsContainer";
import BodyshopHomePage from "./components/marketplace/bodyshop/BodyshopHomePage";

function Routes() {
  return (
    <Switch>
      <CompatRoute exact path="/" component={Landing} />
      <CompatRoute exact path="/register" component={Register} />
      <CompatRoute exact path="/login" component={Login} />
      <CompatRoute exact path="/fnol" component={FnolWizardContainer} />
      <CompatRoute exact path="/pickup/:claimId" component={PickUp} />
      <CompatRoute exact path="/repairDateSelected" component={RepairDateSelected} />
      <CompatRoute exact path="/repairDateRejected" component={RepairDateRejected} />
      <CompatRoute exact path="/fnol/complete" component={FnolComplete} />
      <CompatRoute exact path="/quote-accept/:quoteId" component={QuotePage} />
      <CompatRoute exact path="/bodyshop-quote-accept/:quoteId" component={BodyshopQuotePage} />
      <CompatRoute exact path="/bodyshop/:claimId" component={BodyshopAgreement} />
      <PrivateRoute
        exact
        path="/repairerHome"
        privileges={[PRIVILEGES.ReadRepairClaims, PRIVILEGES.ReadBodyshopClaims]}
        component={RepairerHomePage}
      />

      <PrivateRoute
        exact
        path="/bodyshopHome"
        privileges={[PRIVILEGES.ReadRepairClaims, PRIVILEGES.ReadBodyshopClaims]}
        component={BodyshopHomePage}
      />

      <PrivateRoute
        exact
        path="/marketplace/claims"
        privileges={[PRIVILEGES.ReadRepairClaims, PRIVILEGES.ReadBodyshopClaims]}
        component={ClaimsContainer}
      />

      <PrivateRoute
        exact
        path="/marketplace/in-marketplace"
        privileges={[PRIVILEGES.ReadRepairClaims, PRIVILEGES.ReadBodyshopClaims]}
        component={InMarketplaceClaimsContainer}
      />

      <PrivateRoute
        exact
        path="/marketplace/bodyshop-marketplace"
        privileges={[PRIVILEGES.ReadBodyshopClaims]}
        component={BodyshopClaimsContainer}
      />

      <PrivateRoute
        exact
        path="/marketplace/claimImages/:claimId"
        privileges={[PRIVILEGES.ReadRepairClaims, PRIVILEGES.ReadBodyshopClaims]}
        component={MarketplaceClaimImages}
      />

      <PrivateRoute
        exact
        path="/marketplace/claimDocuments/:claimId"
        privileges={[PRIVILEGES.ReadRepairClaims, PRIVILEGES.ReadBodyshopClaims]}
        component={MarketplaceClaimPdfs}
      />

      <PrivateRoute
        exact
        path="/marketplace/claimVideos/:claimId"
        privileges={[PRIVILEGES.ReadRepairClaims, PRIVILEGES.ReadBodyshopClaims]}
        component={MarketplaceClaimVideos}
      />

      <PrivateRoute
        exact
        path="/marketplace/claims/:claimId"
        privileges={[PRIVILEGES.ReadRepairClaims, PRIVILEGES.ReadBodyshopClaims]}
        component={ClaimDetailsContainer}
      />

      <PrivateRoute
        exact
        path="/marketplace/bid/created"
        privileges={[PRIVILEGES.ReadRepairClaims, PRIVILEGES.ReadBodyshopClaims]}
        component={BidCreated}
      />

      <PrivateRoute exact path="/user" component={UserProfile} />

      <PrivateRoute exact path="/account" component={UserUpdate} />

      <PrivateRoute
        exact
        path="/explorer/:number"
        privileges={[PRIVILEGES.ReadClaims]}
        component={ClaimActionsContainer}
      />

      <PrivateRoute
        exact
        path="/explorer"
        privileges={[PRIVILEGES.ReadClaims]}
        component={ActionsContainer}
      />

      <PrivateRoute
        exact
        path="/home"
        privileges={[PRIVILEGES.ReadClaims]}
        component={HomePage}
      />

      <PrivateRoute
        path="/dashboard/:status"
        privileges={[PRIVILEGES.ReadClaims]}
        component={FilteredClaims}
      />

      <PrivateRoute
        path="/dashboard"
        privileges={[PRIVILEGES.ReadClaims]}
        component={DashboardCards}
      />


      <PrivateRoute
        exact
        path="/dashboardClaim/:claimId"
        privileges={[PRIVILEGES.ReadClaims]}
        component={Claim}
      />

      <PrivateRoute
        exact
        path="/modifyclaim/:claimId"
        privileges={[PRIVILEGES.ReadClaims]}
        component={ModifyClaim}
      />

      <PrivateRoute
        exact
        path="/claim"
        privileges={[PRIVILEGES.ReadClaims]}
        component={NewClaimFormContainer}
      />

      <PrivateRoute
        exact
        path="/admin"
        privileges={[PRIVILEGES.ReadClaimsStats]}
        component={Admin}
      />

      <PrivateRoute
        exact
        path="/garages"
        privileges={[PRIVILEGES.ReadGarages]}
        component={GarageList}
      />

      <PrivateRoute
        exact
        path="/repairMap"
        privileges={[PRIVILEGES.ReadGarages]}
        component={RepairMap}
      />

      <PrivateRoute
        exact
        path="/garages/:id"
        privileges={[PRIVILEGES.ReadGarages]}
        component={GarageDetails}
      />

      <PrivateRoute
        exact
        path="/createGarage"
        privileges={[PRIVILEGES.CreateGarage]}
        component={CreateGarage}
      />

      <PrivateRoute
        exact
        path="/organizations"
        privileges={[
          PRIVILEGES.ReadOrganisations,
          PRIVILEGES.ExecuteUserInvite
        ]}
        component={Organizations}
      />

      <PrivateRoute
        exact
        path="/organizations/:id"
        privileges={[
          PRIVILEGES.ReadOrganisations,
          PRIVILEGES.ExecuteUserInvite
        ]}
        component={OrganizationDetails}
      />

      <PrivateRoute
        exact
        path="/newOrganization"
        privileges={[PRIVILEGES.CreateOrganisation]}
        component={NewOrganization}
      />

      <PrivateRoute
        exact
        path="/inviteAdmin"
        privileges={[PRIVILEGES.ExecuteSuperAdminInvite]}
        component={InviteAdmin}
      />

      <PrivateRoute
        exact
        path="/labels"
        privileges={[PRIVILEGES.SuperAdminLabels]}
        component={Labels}
      />

      <PrivateRoute
        exact
        path="/createLabel/"
        privileges={[PRIVILEGES.SuperAdminLabels]}
        component={CreateLabel}
      />

      <PrivateRoute
        exact
        path="/updateLabel/:labelId"
        privileges={[PRIVILEGES.SuperAdminLabels]}
        component={CreateLabel}
      />
    </Switch>
  );
}

export default Routes;
