import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import { Grid } from "@material-ui/core";
import Button from "components/theme/CustomButtons/Button.jsx";
import CardBody from "components/theme/Card/CardBody.jsx";
import CardAvatar from "components/theme/Card/CardAvatar.jsx";
import CardFooter from "components/theme/Card/CardFooter.jsx";
import userStore from "stores/userStore";
import { Link } from "react-router-dom";
import AsyncSelect from "react-select/async";
import axios from "axios";
import { history } from "../../history";
import Hidden from "@material-ui/core/Hidden";
import { CLAIM_STATUS } from "../../constants/claimStatus";

const lockScreenPageStyle = {
  cardAvatar: {
    maxWidth: "90px",
    maxHeight: "90px",
    marginTop: "-45px"
  },
  customCardFooterClass: {
    border: "none",
    paddingTop: "0"
  },
  justifyContentCenter: {
    justifyContent: "center !important"
  },
  mainButton: {
    padding: "5rem 10rem",
    fontWeight: "bold",
    fontSize: "300%",
    borderRadius: "30px"
  },
  smallButton: {
    padding: "1rem 3rem",
    fontWeight: "bold",
    fontSize: "150%",
    borderRadius: "30px"
  },
  selectList: {
    width: "250px",
    fontSize: "120%"
  }
};

function UserWelcome(props) {
  const handleChange = selectedOption => {
    if (selectedOption) {
      history.push("/dashboardClaim/" + selectedOption.value._id);
      return selectedOption;
    }
  };

  const loadRegistrationOptions = async (inputValue) => {
    if (inputValue.length > 3) {
      const response = await axios("/api/claims", {
        params: {
          plateNumber: inputValue
        }
      });
      return response.data.map(c => {
        return {
          value: c,
          label: c.vehicle.registration + ", " + CLAIM_STATUS[c.status]
        };
      });
    } else return [];
  };


  const loadEmailOptions = async (email) => {
    if (email.length > 4) {
      const response = await axios("/api/claims", {
        params: {
          email
        }
      });
      return response.data.map(c => {
        return {
          value: c,
          label: c.vehicle.registration + ", " + CLAIM_STATUS[c.status]
        };
      });
    } else return [];
  };

  const loadPhoneNumberOptions = async (phoneNumber) => {
    if (phoneNumber.length > 4) {
      const response = await axios("/api/claims", {
        params: {
          phoneNumber
        }
      });
      return response.data.map(c => {
        return {
          value: c,
          label: c.vehicle.registration + ", " + CLAIM_STATUS[c.status]
        };
      });
    } else return [];
  };

  const { classes } = props;
  const { currentUser } = userStore;
  return (
    <div>
      <Grid container direction="column" alignItems="center">
        <CardAvatar profile className={classes.cardAvatar}>
          <img src={currentUser.avatar} alt="..." />
        </CardAvatar>
        <CardBody profile>
          <h4 className={classes.cardTitle}>
            Welcome {userStore.currentUser.name}
          </h4>
        </CardBody>
        <CardBody>
          <Hidden mdUp>
            <Button
              to="/claim"
              component={Link}
              color="primary"
              round
              className={classes.smallButton}
            >
              Get a quote
            </Button>
          </Hidden>
          <Hidden smDown>
            <Button
              to="/claim"
              component={Link}
              color="primary"
              round
              className={classes.mainButton}
            >
              Get a quote
            </Button>
          </Hidden>
        </CardBody>
        <CardFooter className={classes.justifyContentCenter}>
          <grid container direction="row" alignItems="center">
            <grid item name="registration-select">
              Find quotes by license plate:
              <AsyncSelect
                className={classes.selectList}
                cacheOptions
                loadOptions={loadRegistrationOptions}
                defaultOptions
                onChange={selected => handleChange(selected)}
                placeholder="Type plate nr"
              />
            </grid>
            <grid item name="email-select">
              Find quotes by email:
              <AsyncSelect
                className={classes.selectList}
                cacheOptions
                loadOptions={loadEmailOptions}
                defaultOptions
                onChange={selected => handleChange(selected)}
                placeholder="Type email"
              />
            </grid>
            <grid item name="phone-select">
              Find quotes by customer phone number:
              <AsyncSelect
                className={classes.selectList}
                cacheOptions
                loadOptions={loadPhoneNumberOptions}
                defaultOptions
                onChange={selected => handleChange(selected)}
                placeholder="Type phone number"
              />
            </grid>
          </grid>
        </CardFooter>
      </Grid>
    </div>
  );
}

UserWelcome.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(lockScreenPageStyle)(UserWelcome);
