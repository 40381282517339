export const groupClaimFiles = files => {
  return files.reduce(
    (groupedFiles, currentFile) => {
      if (currentFile.contentType.includes('pdf')) {
        return {
          ...groupedFiles,
          pdfs: [...groupedFiles.pdfs, currentFile._id]
        };
      }
      if (currentFile.contentType.includes('mp4')) {
        return {
          ...groupedFiles,
          videos: [...groupedFiles.videos, currentFile._id]
        };
      }
      if (currentFile.contentType.includes('image')) {
        return {
          ...groupedFiles,
          images: [...groupedFiles.images, currentFile._id]
        };
      }

      return groupedFiles;
    },
    {
      images: [],
      pdfs: [],
      videos: []
    }
  );
};

export const enrichClaim = claim => {
  return {
    ...claim,
    files: claim.images,
    ...groupClaimFiles(claim.images)
  };
};

export const enrichClaims = claims => {
  return claims.map(enrichClaim);
};
