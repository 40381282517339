import React, { useState } from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
import garagesStore from "../../stores/garagesStore";
import { observer } from "mobx-react";
import GarageForm from "./GarageForm";

function CreateGarage(props) {

  const [submitError, setSubmitError] = useState(null);

  const saveGarage = async values => {
    try {
      const {
        name,
        address1,
        address2,
        city,
        postcode,
        phone,
        email,
        organisation
      } = values;
      const data = {
        name,
        address1,
        address2,
        city,
        postcode,
        phone,
        email,
        organisation
      };

      await garagesStore.createGarage(data);
      props.history.push("/garages");
    } catch (error) {
      const submitError = get(error, "response.data.message", error.message);
      setSubmitError(submitError);
    }
  };

  return (
    <GarageForm
      initialValues={{}}
      onSubmit={saveGarage}
      submitError={submitError}
      title="Add Garage"
      submitLabel="Add Garage"
      showOrganization
    />
  );
}

CreateGarage.propTypes = {
  history: PropTypes.object
};

export default observer(CreateGarage);
