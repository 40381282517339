import React, { Component } from 'react';
import { withRouter } from 'react-router';
import RegisterForm from './RegisterForm';
import { observer } from 'mobx-react';
import userStore from '../../stores/userStore';
import get from 'lodash.get';

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: ''
    };

    const params = new URLSearchParams(props.location.search);
    this.token = params.get('token');
  }

  componentDidMount() {
    if (userStore.isAuthenticated) {
      this.props.history.replace('/dashboard');
    }

    if (!this.token) {
      this.props.history.replace('/');
    }
  }

  onSubmit = async values => {
    try {
      await userStore.registerUser(values, this.token, this.props.history);
    } catch (err) {
      const error = get(
        err,
        'response.data.message',
        'Error occurred. Try again'
      );

      this.setState({ error });
    }
  };

  render() {
    const { error } = this.state;

    return <RegisterForm error={error} onSubmit={this.onSubmit} />;
  }
}

Register = observer(Register);

export default withRouter(Register);
