import get from "lodash.get";
import userStore from "../../stores/userStore";
import moment from "moment/moment";
import GridItem from "../theme/Grid/GridItem";
import Card from "../theme/Card/Card";
import { Link } from "react-router-dom";
import CardHeader from "../theme/Card/CardHeader";
import ClaimImage from "../claimImage/ClaimImage";
import { THUMBNAILS_SIZE } from "../../constants/images";
import CardBody from "../theme/Card/CardBody";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import Button from "../theme/CustomButtons/Button";
import { ArtTrack, HighlightOff, Refresh } from "@mui/icons-material";
import { PRIVILEGES } from "../../constants/privileges";
import Typography from "@material-ui/core/Typography";
import Moment from "react-moment";
import { SERVICE_REQUIRED } from "../../constants/claimStatus";
import CardFooter from "../theme/Card/CardFooter";
import GridContainer from "../theme/Grid/GridContainer";
import React from "react";
import PropTypes from "prop-types";

function ClaimCard(props) {

  const { claim, classes, isArchived, isRegistered, isFNOL, handleArchiving, handleUnarchiving } = props;
  const {
    vehicle: { make, model, registration },
    bid,
    location,
    _id
  } = claim;
  const winningBid = bid.find(elem => elem.winning);
  const garage = winningBid && get(winningBid, "garage.name");
  const superUser = userStore.hasManyOrganizations;
  const pickupDay = moment(claim.pickup?.date).startOf("day");
  const today = moment().startOf("day");
  const isOverdue = today.isAfter(pickupDay);
  const cardClass = isOverdue ? classes.cardHoverOverdue : (claim.retail ? classes.cardHoverRetail : classes.cardHover);
  const currentTime = moment();
  const action = claim.actions?.find(a => a.name === claim.status);
  const allMinutesIn = action? currentTime.diff(action.createdAt, "minutes"): currentTime.diff(claim.date, "minutes");
  const daysIn = Math.floor(allMinutesIn / 1440);
  const hoursIn = Math.floor((allMinutesIn % 1440) / 60);
  const minutesIn = Math.ceil(allMinutesIn % 60);
  const displayDays = daysIn > 0 ? `${daysIn} Days ` : "";
  const displayHours = hoursIn > 0 ? `${hoursIn} Hrs ` : "";
  const displayMinutes = minutesIn > 0 ? `${minutesIn} Mins` : "";
  const timeIn = `${displayDays}${displayHours}${displayMinutes}`
  return (
    <GridItem xs={12} sm={12} md={3} key={_id}>
      <Card product className={cardClass}>
        <Link to={`/dashboardClaim/${_id}`}>
          <CardHeader
            image
            className={`${classes.cardHeaderHover} ${classes.cardImage}`}
          >
            <ClaimImage
              imageId={claim.images.length > 0 ? claim.images[0] : ""}
              className={classes.defaultIcon}
              width={THUMBNAILS_SIZE.medium}
            />
          </CardHeader>
        </Link>
        <CardBody>
          <div className={classes.cardHoverUnder}>
            <Tooltip
              id="tooltip-top"
              title="View"
              placement="bottom"
              classes={{ tooltip: classes.tooltip }}
            >
              <Button
                color="transparent"
                simple
                justIcon
                to={`/dashboardClaim/${_id}`}
                component={Link}
              >
                <ArtTrack className={classes.underChartIcons} />
              </Button>
            </Tooltip>
            <Tooltip
              id="tooltip-top"
              title="Modify"
              placement="bottom"
              classes={{ tooltip: classes.tooltip }}
            >
              <Button
                color="success"
                simple
                justIcon
                to={`/modifyclaim/${_id}`}
                component={Link}
              >
                <Refresh className={classes.underChartIcons} />
              </Button>
            </Tooltip>
            {(!isArchived && (isRegistered || isFNOL || userStore.isAllowed(PRIVILEGES.ArchiveAll))) && (
              <Tooltip
                id="tooltip-top"
                title="Archive"
                placement="bottom"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  color="danger"
                  simple
                  justIcon
                  onClick={() => handleArchiving(_id)}
                >
                  <HighlightOff className={classes.underChartIcons} />
                </Button>
              </Tooltip>
            )}
            {(isArchived && userStore.isAllowed(PRIVILEGES.ArchiveAll)) && (
              <Tooltip
                id="tooltip-top"
                title="Unarchive"
                placement="bottom"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  color="success"
                  simple
                  justIcon
                  onClick={() => handleUnarchiving(_id)}
                >
                  <HighlightOff className={classes.underChartIcons} />
                </Button>
              </Tooltip>
            )}
          </div>
          <h4 className={classes.cardProductTitle}>
            <Link to={`/dashboardClaim/${_id}`}>{registration}</Link>
          </h4>
          <div className={classes.cardProductDescription} id={`claimDescription-${_id}`}>
            <div>Make: {make}</div>
            <div>Model: {model}</div>
            {garage && <div>{`Garage: ${garage}`}</div>}
            <div>{`Location: ${location}`}</div>
            {superUser && <div>{`Organization: ${claim.organisation.name}`}</div>}
            {superUser && <div>{`Time in: ${timeIn}`}</div>}
            {claim.pickup?.date && (
              <Typography className={classes.cardProductDescription}>
                Confirmed Pickup:{" "}
                <Moment fromNow>{claim.pickup.date}</Moment>
              </Typography>
            )}
            {!claim.pickup?.date && claim.pickup?.availableDates?.length > 0 && (
              <Typography className={classes.cardProductDescription}>
                Earliest Expected Pickup:{" "}
                <Moment fromNow>{claim.pickup.availableDates.slice().sort()[0]}</Moment>
              </Typography>
            )}
            {claim.pickup?.contactRequired && (
              <Typography color="error">
                Pickup date rejected
              </Typography>
            )}
            {claim.driver?.name && (
              <Typography className={classes.cardProductDescription}>
                Driver: {claim.driver.name}
              </Typography>
            )}
            {claim.serviceRequired &&
              <Typography component="p" className={classes.cardProductDescription}>
                Service
                Required: {claim.serviceRequired !== "other" ? SERVICE_REQUIRED[claim.serviceRequired] : claim.serviceDescription}
              </Typography>
            }
            {claim.retail &&
              <Typography component="p" className={classes.cardProductDescription}>
                {claim.payment.bookingPaid ? "Booking paid." : ""} {claim.payment.finalInvoicePaid ? "Final invoice paid." : ""}
              </Typography>
            }
          </div>
        </CardBody>
        <CardFooter stats>
          <GridContainer>
            <GridItem md={7}>
              <div className={classes.cardProductDescription}>
                {"Created "}
                <Moment fromNow>{claim.date}</Moment>
              </div>
            </GridItem>

            <GridItem md={5}>
              {claim.retail &&
                <>
                  Retail claim
                </>
              }
            </GridItem>
          </GridContainer>
        </CardFooter>
      </Card>
    </GridItem>
  );
}

ClaimCard.propTypes = {
  claim: PropTypes.object,
  classes: PropTypes.object,
  isArchived: PropTypes.bool,
  isRegistered: PropTypes.bool,
  isFNOL: PropTypes.bool,
  handleArchiving: PropTypes.func,
  handleUnarchiving: PropTypes.func
};

export default ClaimCard;